import Lottie from 'react-lottie';

import * as animationData from './animation.json';
import { StyledSplash } from './styled';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Splash = () => (
  <StyledSplash>
    <Lottie options={defaultOptions} height={400} width={400} />
  </StyledSplash>
);

export default Splash;
