export enum StepsEnum {
  question = 'question',
  reason = 'reason',
  rating = 'rating',
  success = 'success'
}

export enum ReasonValuesEnum {
  technical,
  unsatisfactory,
  translation
}

export type AnswersType = {
  [StepsEnum.reason]: {
    values: ReasonValuesEnum[];
    text?: string;
  };
  [StepsEnum.rating]: {
    value?: number;
    text?: string;
  };
};
