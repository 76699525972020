import { FC, useCallback, useEffect } from 'react';
import { useCaptions, useWebRTC } from 'hooks';
import { useParams } from 'react-router-dom';
import ACTIONS from 'socket/actions';
import { useTranslation } from 'react-i18next';
import socket from 'webrtc/socket';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRemoteStreams } from 'store/webrtc/selectors';
import { toggleModal } from 'store/ui/slice';
import { ModalKeys } from 'store/ui/types';
import {
  resetShareApprovalStatuses,
  setIsApprovingInProgress,
  setIsApprovingRequested,
  setShareApprovalStatus
} from 'store/webrtc/slice';
import { shallowEqual } from 'react-redux';

import {
  ErrorModal,
  LocalRTCVideo,
  RoomButtons,
  RTCVideo,
  SettingsModal,
  ShareLink,
  Captions
} from 'components/shared';
import { StyledVideoContainer } from './styled';

const Room: FC = () => {
  useCaptions();
  const { t } = useTranslation();

  const { id: roomID } = useParams();

  const dispatch = useAppDispatch();
  const remoteStreams = useAppSelector(selectRemoteStreams, shallowEqual);

  const {
    pinned,
    isError,
    localStream,
    endCall,
    setPinned,
    onShareScreen,
    onSwitchAudio,
    onSwitchVideo,
    onSwitchCamera,
    onSwitchMicrophone
  } = useWebRTC(roomID as string);

  const onClickPin = useCallback(
    (id: string) => {
      setPinned(prev => {
        if (prev === id) return '';

        return id;
      });
    },
    [setPinned]
  );

  useEffect(() => {
    const listener = ({
      peerID,
      value
    }: {
      peerID: string;
      value: boolean;
    }) => {
      dispatch(
        setShareApprovalStatus({
          id: peerID,
          value
        })
      );
    };

    socket.io.on(ACTIONS.SHARE_LINK_ANSWER, listener);

    return () => {
      socket.io.off(ACTIONS.SHARE_LINK_ANSWER, listener);
    };
  }, [dispatch, endCall, roomID, t]);

  useEffect(() => {
    const listener = () => {
      dispatch(setIsApprovingInProgress(true));
      dispatch(
        toggleModal({
          name: ModalKeys.confirm,
          value: true,
          params: {
            title: t('agreement_request'),
            description: t('agreement_request_text'),
            showCancel: true,
            cancelText: t('no'),
            okText: t('yes'),
            onOk() {
              socket.sendToPeer(ACTIONS.SHARE_LINK_ANSWER, {
                value: true
              });
            },
            onCancel() {
              socket.sendToPeer(ACTIONS.SHARE_LINK_ANSWER, {
                value: false
              });
            }
          }
        })
      );
    };

    socket.io.on(ACTIONS.SHARE_LINK, listener);

    return () => {
      socket.io.off(ACTIONS.SHARE_LINK, listener);
    };
  }, [dispatch, endCall, roomID, t]);

  useEffect(() => {
    const listener = () => {
      dispatch(setIsApprovingInProgress(false));
      dispatch(setIsApprovingRequested(false));
      dispatch(resetShareApprovalStatuses());
      dispatch(
        toggleModal({
          name: ModalKeys.confirm,
          value: false
        })
      );
      dispatch(
        toggleModal({
          name: ModalKeys.shareLink,
          value: false
        })
      );
    };

    socket.io.on(ACTIONS.STOP_SHARE_LINK, listener);

    return () => {
      socket.io.off(ACTIONS.STOP_SHARE_LINK, listener);
    };
  }, [dispatch, endCall, roomID, t]);

  return (
    <div>
      <StyledVideoContainer>
        {localStream && <LocalRTCVideo stream={localStream} pinned={pinned} />}
        {remoteStreams.map((id, _, arr) => {
          const filtered = arr.filter(item => item !== pinned);
          const index = filtered.findIndex(item => item === id);

          return (
            <RTCVideo
              key={`video-item-${id}`}
              id={id}
              index={index}
              onClickPin={onClickPin}
              pinned={pinned}
            />
          );
        })}
      </StyledVideoContainer>
      <RoomButtons
        endCall={endCall}
        toggleShareScreen={onShareScreen}
        onSwitchAudio={onSwitchAudio}
        onSwitchVideo={onSwitchVideo}
      />
      <ErrorModal
        visible={isError}
        title={t('error')}
        text={t('something_wrong_check_device')}
        onOk={() => {
          window.location.href = `${window.location.origin}/home/${roomID}`;
        }}
      />
      <SettingsModal
        onSwitchCamera={onSwitchCamera}
        onSwitchMicrophone={onSwitchMicrophone}
      />
      <ShareLink />
      <Captions />
    </div>
  );
};

export default Room;
