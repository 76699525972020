import { FC, useEffect, useRef } from 'react';
import ACTIONS from 'socket/actions';
import { useLocation } from 'react-router';
import socket from 'webrtc/socket';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUserData, setLoading } from 'store/user/slice';
import { fetchAppointmentData } from 'store/appointments/slice';
import {
  selectAppointmentsError,
  selectAppointmentsLoading
} from 'store/appointments/selectors';
import {
  selectIsLoggedIn,
  selectRoomId,
  selectUserLoading
} from 'store/user/selectors';
import WorkerBuilder from 'worker/worker-builder';
import ConnectionWorker from 'worker/connection';
import { checkConnection, setNetworkStatus } from 'store/network/slice';
import { NetworkStatuses } from 'store/network/types';
import { APP_ERRORS, APP_EVENTS } from 'logger/constants';
import { logger } from 'logger';
import { SOCKET_PAGES } from 'constants/global';

import {
  AppointmentInfo,
  Splash,
  NotFound,
  Chat,
  NetworkStatus
} from 'components/shared';
import { IAppContainerProps } from './types';

const connection = new WorkerBuilder(ConnectionWorker);
const navigatorConnection =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  navigator.connection || navigator.mozConnection || navigator.webkitConnection;

const AppContainer: FC<IAppContainerProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isUserLoading = useAppSelector(selectUserLoading());
  const isLoggedIn = useAppSelector(selectIsLoggedIn());
  const isAppointmentLoading = useAppSelector(selectAppointmentsLoading);
  const isAppointmentError = useAppSelector(selectAppointmentsError);
  const prevSocketId = useRef('');

  const appointmentId = useAppSelector(selectRoomId());

  const isRoomPage = location.pathname.split('/')?.[1] === 'room';

  useEffect(() => {
    if (isLoggedIn && isAppointmentError) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  }, [dispatch, isAppointmentError, isLoggedIn]);

  useEffect(() => {
    const isSocketPage = SOCKET_PAGES.includes(
      window.location.pathname.split('/')?.[1]
    );

    const token = localStorage.getItem('token');

    if (isSocketPage && appointmentId) {
      dispatch(setLoading(true));
      socket.connect(appointmentId, token);

      socket.io.on(ACTIONS.CONNECT, async () => {
        if (prevSocketId.current) {
          socket.sendToPeer(ACTIONS.REPLACE_SOCKET_ID);
        } else {
          logger.event(APP_EVENTS.socket_connected);

          if (token) {
            const res = await dispatch(fetchUserData());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!res.error) {
              await dispatch(fetchAppointmentData(appointmentId));
            }
          }
        }

        prevSocketId.current = socket.id;
        dispatch(setLoading(false));
      });

      socket.io.on(ACTIONS.CONNECT_ERROR, err => {
        logger.error(APP_ERRORS.connection_error, {
          err
        });
      });

      socket.io.on(ACTIONS.THROW, () => {
        window.location.href = `${window.location.origin}/duplicate?roomID=${appointmentId}`;
      });

      socket.io.on('disconnect', (e: unknown) => {
        console.log('DISCONNECT', e); // undefined
      });
    } else {
      dispatch(setLoading(false));
    }

    return () => {
      if (appointmentId) {
        socket.io.off();
      }
    };
  }, [appointmentId, dispatch]);

  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      const { to, online } = JSON.parse(event.data);

      if (online) {
        dispatch(checkConnection(to));
      }
    };

    connection.addEventListener('message', messageListener);

    return () => {
      connection.removeEventListener('message', messageListener);
    };
  }, [dispatch]);

  useEffect(() => {
    const onLoad = () => {
      if (navigatorConnection?.effectiveType) {
        if (navigator.onLine) {
          dispatch(checkConnection(navigatorConnection?.effectiveType));
        } else {
          dispatch(setNetworkStatus(NetworkStatuses.offline));
        }
      }
    };

    window.addEventListener('load', onLoad);

    return () => {
      window.addEventListener('load', onLoad);
    };
  }, [dispatch]);

  useEffect(() => {
    const onOnline = () => {
      dispatch(setNetworkStatus(NetworkStatuses.online));
    };

    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('online', onOnline);
    };
  }, [dispatch]);

  useEffect(() => {
    const onOffline = () => {
      dispatch(setNetworkStatus(NetworkStatuses.offline));
    };

    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('offline', onOffline);
    };
  }, [dispatch]);

  if (isUserLoading || isAppointmentLoading) {
    return <Splash />;
  }

  if (isAppointmentError) {
    return <NotFound />;
  }

  return (
    <>
      {children}
      {isRoomPage && (
        <>
          <Chat />
          <AppointmentInfo />
        </>
      )}
      <NetworkStatus />
    </>
  );
};

export default AppContainer;
