import styled from 'styled-components';

export const StyledContent = styled.div`
  > p {
    color: var(--blue-6);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    padding-bottom: 16px;
  }
`;
