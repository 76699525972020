import { Mic, MicDisabled } from 'icons';
import {
  ForwardRefRenderFunction,
  forwardRef,
  memo,
  useImperativeHandle,
  useState
} from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectAudioEnabled,
  selectIsSpeaking,
  selectIsStreamScreen,
  selectUserName
} from 'store/webrtc/selectors';

import { MicButton, NameContainer } from './styled';
import { Talking } from 'components/shared';

interface IProps {
  id: string;
  self?: boolean;
  muted: boolean;
  setMuted(value: boolean): void;
}

export type RTCVideoUserInfoRef = {
  setHovered(value: boolean): void;
};

const RTCVideoUserInfo: ForwardRefRenderFunction<
  RTCVideoUserInfoRef,
  IProps
> = ({ id, muted, self, setMuted }, ref) => {
  // states
  const [hovered, setHovered] = useState(false);
  // redux
  const isSpeaking = useAppSelector(selectIsSpeaking(id, muted));
  const userName = useAppSelector(selectUserName(id));
  const isAudioEnabled = useAppSelector(selectAudioEnabled(id));
  const isScreenSharing = useAppSelector(selectIsStreamScreen(id));

  const renderMicIcon = () => {
    if (isSpeaking && !hovered) {
      return <Talking />;
    }

    if (muted || !isAudioEnabled) {
      return <MicDisabled width={24} height={24} />;
    }

    return <Mic width={24} height={24} />;
  };

  useImperativeHandle(ref, () => ({
    setHovered
  }));

  if (isScreenSharing) {
    return null;
  }

  return (
    <NameContainer>
      <MicButton disabled={self} onClick={() => setMuted(!muted)}>
        {renderMicIcon()}
      </MicButton>
      <p>{userName}</p>
    </NameContainer>
  );
};

export default memo(forwardRef(RTCVideoUserInfo));
