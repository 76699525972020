import styled, { css } from 'styled-components';

import { ILoadingProps } from '.';

const ORIGIN_INDEX = 0.5;
const TOP_INDEX = 0.035;
const LEFT_INDEX = 0.46;
const WIDTH_INDEX = 0.075;
const HEIGHT_INDEX = 0.225;

export const StyledLoading = styled.div<ILoadingProps>`
  display: inline-block;
  position: relative;
  ${({ color, size }) => css`
    color: var(--${color});
    width: ${size}px;
    height: ${size}px;
  `}
  div {
    ${({ size }) => css`
      transform-origin: ${size * ORIGIN_INDEX}px ${size * ORIGIN_INDEX}px;
    `}
    animation: lds-spinner 1.2s linear infinite;
  }
  div:after {
    content: ' ';
    display: block;
    position: absolute;
    ${({ size }) => css`
      top: ${size * TOP_INDEX}px;
      left: ${size * LEFT_INDEX}px;
      width: ${size * WIDTH_INDEX}px;
      height: ${size * HEIGHT_INDEX}px;
    `}
    border-radius: 20%;
    ${({ color }) => css`
      background-color: var(--${color});
    `}
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
