import styled from 'styled-components';

export const NameContainer = styled.div`
  position: absolute;
  z-index: 6;
  left: 0;
  bottom: 0;
  padding: 4px 8px;
  background-color: rgba(11, 26, 48, 0.8);
  display: flex;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
  p {
    margin-left: 8px;
    font-size: 16px;
    color: var(--white);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const MicButton = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: transparent;
  border: none;
  height: 24px;
`;
