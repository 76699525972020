import styled from 'styled-components';

export const StyledNotes = styled.div`
  margin-bottom: 16px;
  ul {
    padding-left: 0px;
    list-style: none;
    li {
      margin-bottom: 8px;
      background-color: var(--light-bg-2);
      padding: 12px;
      border-radius: 2px;
      p {
        font-size: 14px;
        color: var(--light-text);
      }
      span {
        font-size: 12px;
        color: var(--light-text);
      }
    }
  }
  .more-button {
    text-align: right;
    button {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      color: var(--blue-4);
      cursor: pointer;
    }
  }
`;

export const AddButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  padding-right: 0;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 16px;
  margin-left: auto;
  span {
    margin-right: 8px;
  }
`;
