import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef
} from 'react';
import { Close } from 'icons';

import { Content, Header, StyledClose, StyledSidebar } from './styled';
import { ISidebarProps, SidebarRef } from './types';

const Sidebar: ForwardRefRenderFunction<SidebarRef, ISidebarProps> = (
  { title, isOpen, footer, children, onClose },
  ref
) => {
  // refs
  const contentRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(() => {
    contentRef.current?.scrollTo({
      top: contentRef.current?.scrollHeight || 0
    });
  }, []);

  useImperativeHandle(ref, () => ({
    scrollToBottom
  }));

  if (!isOpen) return null;

  return (
    <StyledSidebar>
      <Header>
        <h4>{title}</h4>
        <StyledClose onClick={onClose}>
          <Close />
        </StyledClose>
      </Header>
      <Content ref={contentRef}>{children}</Content>
      {footer}
    </StyledSidebar>
  );
};

export default forwardRef(Sidebar);
