import styled from 'styled-components';

export const StyledContent = styled.div`
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledTabHeader = styled.div`
  display: flex;
  align-items: center;
  button {
    padding: 8px 16px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    font-size: 16px;
    color: var(--black);
    &.screen-sharing-active-tab {
      border-color: var(--primary);
    }
  }
`;

export const StyledTabContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
`;

export const StyledItem = styled.div`
  margin: 16px 8px 0;
  cursor: pointer;
  max-width: 150px;
  p {
    font-size: 12px;
    color: var(--black);
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      object-position: center;
      margin-right: 4px;
    }
  }
`;

export const StyledPreview = styled.div<{ active?: boolean }>`
  width: 150px;
  height: 120px;
  background-color: var(--gray-4);
  border: 2px solid
    ${({ active }) => (active ? 'var(--primary)' : 'transparent')};
  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;
