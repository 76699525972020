import { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { selectUIModalVisible } from 'store/ui/selectors';
import { ModalKeys } from 'store/ui/types';
import { toggleModal } from 'store/ui/slice';

import { Modal } from 'components/shared';
import { StreamRecord } from './components';

const CheckDevices: FC = () => {
  const { t } = useTranslation();
  const visible = useAppSelector(selectUIModalVisible(ModalKeys.checkDevices));
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(toggleModal({ name: ModalKeys.checkDevices }));
  }, [dispatch]);

  return (
    <Modal
      width={600}
      isOpen={visible}
      title={t('check_video_image')}
      onClose={onClose}
    >
      <StreamRecord />
    </Modal>
  );
};

export default memo(CheckDevices);
