import styled, { keyframes } from 'styled-components';

const sidebarLgKeyframes = keyframes`
 0% { transform: translateX(100%) }
 100% { transform: translateX(0) }
`;

const sidebarSmKeyframes = keyframes`
 0% { transform: translateY(100%) }
 100% { transform: translateX(0) }
`;

export const StyledSidebar = styled.div`
  position: fixed;
  right: 0;
  transform: translateX(0);
  top: 0;
  bottom: 0;
  z-index: 9997;
  width: 400px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  animation: ${sidebarLgKeyframes} 0.3s ease-out;
  @media only screen and (max-width: 768px) {
    top: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(0);
    animation: ${sidebarSmKeyframes} 0.2s ease-out;
  }
`;

export const Header = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e4eeff;
  h4 {
    font-size: 22px;
    font-weight: bold;
    color: var(--gray-4);
  }
`;

export const Content = styled.div`
  padding: 16px 16px 0;
  overflow-y: auto;
  flex: 1;
`;

export const StyledClose = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: 0;
  svg {
    width: 18px;
    height: 18px;
  }
`;
