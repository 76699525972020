import { Jwt } from '@medicusunion/front-utils';
import config from 'config';
import { StorageKeys } from 'constants/storage';

import { refreshTokenStorage, tokenStorage } from './storage';

const storages = {
  [StorageKeys.token]: tokenStorage,
  [StorageKeys.refreshToken]: refreshTokenStorage
};

type Key = StorageKeys.token | StorageKeys.refreshToken;

export const jwt = new Jwt({
  baseUrl: config.base_url as string,
  tokenKey: StorageKeys.token,
  refreshKey: StorageKeys.refreshToken,
  getFunction: async key => storages[key as Key].get(),
  setFunction: async (key, value) => storages[key as Key].set(value),
  deleteFunction: async key => storages[key as Key].remove()
});
