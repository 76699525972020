import { FC } from 'react';
import { Mic, MicDisabled, Video, VideoDisabled } from 'icons';
import { useAppDispatch } from 'store/hooks';
import { toggleModal } from 'store/ui/slice';
import { ModalKeys } from 'store/ui/types';

import { StyledButton } from './styled';

const ICONS = {
  video: {
    default: <VideoDisabled width={22} height={22} />,
    active: <Video width={22} height={22} />
  },
  mic: {
    default: <MicDisabled width={22} height={22} />,
    active: <Mic width={22} height={22} />
  }
} as const;

interface IProps {
  text: string;
  icon: keyof typeof ICONS;
  active?: boolean;
  activeText?: string;
  allowed?: boolean;
  onClick(): void;
  requestMediaStream(): void;
}

const ActionButton: FC<IProps> = ({
  text,
  icon,
  active,
  activeText,
  allowed,
  onClick,
  requestMediaStream
}) => {
  const dispatch = useAppDispatch();
  const iconsObj = ICONS[icon];

  const onClickButton = () => {
    if (!allowed) {
      requestMediaStream();
      dispatch(
        toggleModal({ name: ModalKeys.permission, params: { type: icon } })
      );
    }

    onClick();
  };

  return (
    <StyledButton onClick={onClickButton}>
      {active ? (
        <>
          {iconsObj.active}
          {activeText}
        </>
      ) : (
        <>
          {iconsObj.default}
          {text}
        </>
      )}
    </StyledButton>
  );
};

export default ActionButton;
