import styled from 'styled-components';

export const StyledUserItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  p {
    color: var(--blue-6);
    font-size: 14px;
    line-height: 1.57;
  }
  span {
    display: inline-block;
    width: 24px;
    display: flex;
    justify-content: center;
    &.danger {
      svg {
        path {
          stroke: var(--danger);
        }
      }
    }
  }
`;
