import { PeerID } from 'webrtc/types';

import { AppPlatforms, RemoteStream, StreamId } from 'hooks/useWebRTC/types';

export enum ShareApprovalsEnum {
  pending,
  rejected,
  approved
}

export type ToggleSpeaking = {
  value: boolean;
  peerID: string;
};

export type ToggleRemoteStreamMedia = {
  id: StreamId;
  value: boolean;
  from?: AppPlatforms;
};

export type SetRemoteStream = {
  id: StreamId;
  data: Partial<RemoteStream>;
};

export type SetShareApprovalStatus = {
  id: StreamId;
  value: boolean;
};

export type ReplacePeerIds = {
  prev: PeerID;
  peerID: PeerID;
};

export type ChangeDisconnectionStatus = {
  peerID: PeerID;
  value?: boolean;
};

export interface IWebRTCState {
  joined: boolean;
  videoEnabled: boolean;
  audioEnabled: boolean;
  isSettingsOpen: boolean;
  speaking: Record<string, boolean>;
  remoteStreams: StreamId[];
  remoteStreamsById: Record<StreamId, RemoteStream>;
  shareApprovals: Record<StreamId, ShareApprovalsEnum>;
  // this is true if someone has sent a request
  isApprovingInProgress: boolean;
  // this is true if I sent a request
  isApprovingRequested: boolean;
  // if my screen is sharing this is true
  isSharingScreen: boolean;
  // if someones screen is sharing this is true
  sharingScreenExists: boolean;
}
