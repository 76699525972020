import config from 'config';

import { USER_TYPES } from './users';

export const getWebsiteUrl = (userType: string) => {
  switch (userType) {
    case USER_TYPES.doctor:
      return config.doctor_url;
    case USER_TYPES.translator:
      return config.translator_url;
    default:
      return config.website_url;
  }
};
