import { FC, memo, useCallback } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import {
  selectDevicesMicrophones,
  selectSelectedMicrophone
} from 'store/devices/selectors';
import { chooseMicrophone } from 'store/devices/slice';
import { useAppSelector } from 'store/hooks';
import { Mic } from 'icons';

import { Select } from 'components/ui';

interface Props {
  disabled?: boolean;
  onSwitch(device: MediaDeviceInfo): void;
}

const AudioSelect: FC<Props> = ({ disabled, onSwitch }) => {
  const data = useAppSelector(selectDevicesMicrophones, shallowEqual);
  const selected = useAppSelector(selectSelectedMicrophone);
  const dispatch = useDispatch();

  const onSelect = useCallback(
    (id: string) => {
      const device = data.find(item => item.deviceId === id);

      if (device) {
        dispatch(chooseMicrophone(device));
        onSwitch(device);
      }
    },
    [data, dispatch, onSwitch]
  );

  if (!data.length) {
    return null;
  }

  return (
    <Select
      value={selected}
      options={data.map(item => ({
        value: item.deviceId,
        label: item.label || 'Default'
      }))}
      onChange={onSelect}
      icon={<Mic width={20} height={20} className="select-placeholder-icon" />}
      disabled={disabled}
    />
  );
};

export default memo(AudioSelect);
