import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from 'config';
import instance from 'services/api';
import { RootState } from 'store';
import { selectRoomId } from 'store/user/selectors';

import { IAppointmentsState, Note } from './types';

export const fetchAppointmentData = createAsyncThunk(
  'appointments/fetchAppointmentData',
  async (id: string) => {
    const response = await instance.get(`appointments/info/${id}`);

    return { appointment: response.data.data, roomID: id };
  }
);

export const refetchAppointmentData = createAsyncThunk(
  'appointments/refetchAppointmentData',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const roomID = selectRoomId()(state);

    const response = await instance.get(`appointments/info/${roomID}`);

    return response.data.data;
  }
);

const initialState: IAppointmentsState = {
  appointment: null,
  opened: false,
  loading: false,
  error: false
};

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    toggleAppointment: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action.payload === 'undefined') {
        state.opened = !state.opened;
      } else {
        state.opened = action.payload;
      }
    },
    addAppointmentNote: (state, action: PayloadAction<Note>) => {
      state.appointment?.notes.push(action.payload);
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAppointmentData.pending, state => {
      state.error = false;

      if (config.env !== 'development') {
        state.loading = true;
      }
    });
    builder.addCase(fetchAppointmentData.fulfilled, (state, action) => {
      state.appointment = action.payload.appointment;
      state.loading = false;
    });
    builder.addCase(fetchAppointmentData.rejected, state => {
      if (config.env !== 'development') {
        state.error = true;
      }

      state.loading = false;
    });
    builder.addCase(refetchAppointmentData.fulfilled, (state, action) => {
      state.appointment = action.payload;
    });
  }
});

export const { toggleAppointment, addAppointmentNote } =
  appointmentsSlice.actions;
export default appointmentsSlice.reducer;
