import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectUIModalVisible } from 'store/ui/selectors';
import { ModalKeys } from 'store/ui/types';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'store/ui/slice';

import {
  StyledContent,
  StyledItem,
  StyledTabContent,
  StyledTabHeader,
  StyledPreview
} from './styles';
import console from 'utils/console';
import { Loading, Modal } from 'components/shared';

interface IProps {
  toggleShareScreen(share: boolean, sourceId: string): void;
}

enum ScreenTypes {
  screen = 'screen',
  window = 'window'
}

const GET_SOURCES_TIMER = 5000;

const ShareScreenModal: FC<IProps> = ({ toggleShareScreen }) => {
  const { t } = useTranslation();
  const [sources, setSources] = useState<ShareScreenSources | null>(null);
  const isVisible = useAppSelector(
    selectUIModalVisible(ModalKeys.desktopShareScreen)
  );

  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<ScreenTypes>(
    ScreenTypes.window
  );

  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    let timer: NodeJS.Timer | null = null;

    if (isVisible) {
      const getSources = async () => {
        try {
          const data = await window.electron.getSources();

          setSources(data);
        } catch (err) {
          console.warn('ERROR | window.electron.getSources | ', err);
        }
      };

      getSources();
      timer = setInterval(getSources, GET_SOURCES_TIMER);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isVisible]);

  const isEmpty = useMemo(
    () => !!sources && !Object.values(sources).every(item => item.length),
    [sources]
  );

  useEffect(() => {
    if (isVisible && isEmpty) {
      alert(t('screen_sharing_not_available'));
    }
  }, [isEmpty, isVisible, t]);

  const onClose = () => {
    setSelectedItem('');
    setSelectedTab(ScreenTypes.window);
    dispatch(
      toggleModal({
        name: ModalKeys.desktopShareScreen,
        value: false
      })
    );
  };

  const onClickShare = () => {
    if (selectedItem) {
      toggleShareScreen(true, selectedItem);
      onClose();
    }
  };

  const tabs = sources ? Object.keys(sources) : [];

  return (
    <Modal
      isOpen={isVisible}
      title={t('choose_what_to_share')}
      onClose={onClose}
      footer={{
        cancelButton: {
          text: t('cancel'),
          onClick: onClose
        },
        okButton: {
          text: t('share'),
          disabled: !selectedItem,
          onClick: onClickShare
        }
      }}
    >
      <StyledContent>
        {!sources || isEmpty ? (
          <>
            <StyledTabHeader>
              {tabs.map(tab => (
                <button
                  key={`tab-item-${tab}`}
                  onClick={() => setSelectedTab(tab as ScreenTypes)}
                  className={
                    selectedTab === tab ? 'screen-sharing-active-tab' : ''
                  }
                >
                  {t(tab)}
                </button>
              ))}
            </StyledTabHeader>
            <StyledTabContent>
              {sources?.[selectedTab].map(item => (
                <StyledItem
                  key={item.id}
                  onClick={() => setSelectedItem(item.id)}
                >
                  <p>
                    {item.appIcon ? <img src={item.appIcon} /> : null}
                    {item.name}
                  </p>
                  <StyledPreview active={selectedItem === item.id}>
                    {item.thumbnail ? (
                      <img src={item.thumbnail} alt="" />
                    ) : null}
                  </StyledPreview>
                </StyledItem>
              ))}
            </StyledTabContent>
          </>
        ) : (
          <Loading />
        )}
      </StyledContent>
    </Modal>
  );
};

export default memo(ShareScreenModal);
