import { ChatMessageData } from 'store/chat/types';
import { PeerID } from 'webrtc/types';

export const MESSAGE_TYPES = {
  chat: 'chat',
  video: 'video',
  audio: 'audio',
  speaking: 'speaking',
  update_appointment: 'update_appointment',
  speech_text: 'speech_text'
} as const;

export type StreamId = string;

export enum StreamTypesEnum {
  screen = 'screen',
  media = 'media'
}

export enum AppPlatforms {
  mobile,
  web
}

export type UserData = {
  name: string;
  avatar?: string;
};

export type RemoteStream = {
  user?: UserData | null;
  videoEnabled?: boolean;
  audioEnabled?: boolean;
  stream: MediaStream | null;
  type: StreamTypesEnum;
  isLoading?: boolean;
  disconnected?: boolean;
};

type ActionDataType = {
  peerID: PeerID;
  value: boolean;
};

export type SocketActionDataType = ActionDataType & {
  type: string;
  from: AppPlatforms;
};

export type MessageType =
  | { type: typeof MESSAGE_TYPES.chat; data: ChatMessageData }
  | { type: typeof MESSAGE_TYPES.speaking; data: ActionDataType }
  | { type: typeof MESSAGE_TYPES.update_appointment }
  | { type: typeof MESSAGE_TYPES.speech_text; data: string };
