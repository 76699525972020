import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectCaptionsData } from 'store/captions/selectors';
import { shallowEqual } from 'react-redux';

import CaptionItem from './CaptionItem';
import { StyledCaptions } from './styled';

const Captions: FC = () => {
  const captions = useAppSelector(selectCaptionsData, shallowEqual);

  return (
    <StyledCaptions>
      <ul>
        {captions.map(item => (
          <CaptionItem
            key={`caption-${item.peerID}-${item.data}`}
            peerID={item.peerID}
            transcript={item.data}
          />
        ))}
      </ul>
    </StyledCaptions>
  );
};

export default Captions;
