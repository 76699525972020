// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Chrome, Edge, Firefox, Safari } from 'icons';
import { FC } from 'react';

import { PageWrapper, CheckBrowserContent } from './styled';
import { ICheckBrowserProps } from './types';

const checkIsMacOs = () => {
  const platform =
    window.navigator?.userAgentData?.platform || window.navigator.platform;

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (
    macosPlatforms.indexOf(platform) !== -1 ||
    iosPlatforms.indexOf(platform) !== -1
  ) {
    return true;
  }

  return false;
};

const checkBrowser = () => {
  const isEdge = /Edge/.test(navigator.userAgent);
  const isFirefox = /Firefox/.test(navigator.userAgent);
  const isOpera = /OPR/.test(navigator.userAgent);
  const isChromeIOS = /CriOS/.test(navigator.userAgent);
  const isChrome = !isOpera && /Google Inc/.test(navigator.vendor);

  const isSafari =
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);

  if (isFirefox || isSafari || isEdge || isChrome || isChromeIOS) {
    return true;
  }

  return false;
};

const CheckBrowser: FC<ICheckBrowserProps> = ({ children }) => {
  const allowToUse = checkBrowser();
  const isMacOs = checkIsMacOs();

  if (allowToUse) {
    return <>{children}</>;
  }

  return (
    <PageWrapper>
      <CheckBrowserContent>
        <h3>Unsupported browser</h3>
        <p>You’re using a web browser we don’t support yet.</p>
        <ul>
          <li>
            <a href="https://www.google.com/chrome/">
              <Chrome />
              <span>Google Chrome</span>
            </a>
          </li>
          {isMacOs && (
            <li>
              <a href="https://www.apple.com/safari/">
                <Safari />
                <span>Safari</span>
              </a>
            </li>
          )}
          <li>
            <a href="https://www.mozilla.org/en-US/firefox/new/">
              <Firefox />
              <span>Mozilla Firefox</span>
            </a>
          </li>
          <li>
            <a href="https://www.microsoft.com/en-us/edge?form=MA13FJ">
              <Edge />
              <span>Microsoft Edge</span>
            </a>
          </li>
        </ul>
      </CheckBrowserContent>
    </PageWrapper>
  );
};

export default CheckBrowser;
