import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectChat = (state: RootState) => state.chat;

export const selectChatIsOpened = createSelector(
  [selectChat],
  chat => chat.opened
);

export const selectChatMessages = createSelector(
  [selectChat],
  chat => chat.messages
);

export const selectChatHasUnreadMessages = createSelector(
  [selectChatIsOpened, selectChatMessages],
  (isOpened, messages) => {
    if (isOpened) {
      return false;
    }

    return messages.some(item => !item.read);
  }
);

export const selectChatUnreadMessages = createSelector(
  [selectChatIsOpened, selectChatMessages],
  (isOpened, messages) => {
    if (isOpened) {
      return [];
    }

    return messages.filter(item => !item.read);
  }
);

export const selectChatLastUnreadMessage = createSelector(
  [selectChatUnreadMessages],
  messages => messages[messages.length - 1]
);
