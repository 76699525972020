import { FC, useEffect, useState } from 'react';
import Routes from 'Routes';
import { BrowserRouter, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { store } from 'store';
import { APP_EVENTS } from 'logger/constants';
import { logger } from 'logger';
import { decodeFromBase64 } from '@medicusunion/front-utils';

import { AppContainer, Confirm } from 'components/shared';
import {
  isDoctorStorage,
  refreshTokenStorage,
  tokenStorage
} from 'utils/storage';
import 'react-toastify/dist/ReactToastify.css';

const App: FC = () => {
  // translations
  const { i18n } = useTranslation();
  // states
  const [render, setRender] = useState(false);
  // navigation
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    logger.event(APP_EVENTS.page_opened);
  }, []);

  useEffect(() => {
    const queryTokenCompressed = search.get('t');
    const token = decodeFromBase64(queryTokenCompressed);

    const refreshTokenCompressed = search.get('r');
    const refreshToken = decodeFromBase64(refreshTokenCompressed);

    const lang = search.get('lang') || localStorage.getItem('lang') || 'en';

    const isDoctor = search.get('creator') === 'doctor';

    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
    search.delete('lang');

    console.log('token', token);

    if (token) {
      search.delete('t');
      tokenStorage.set(token);
    }

    if (refreshToken) {
      search.delete('r');
      refreshTokenStorage.set(refreshToken);
    }

    if (isDoctor) {
      isDoctorStorage.set('1');
    } else {
      isDoctorStorage.remove();
    }

    setSearch(search);
    setRender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setSearch]);

  if (!render) return null;

  return (
    <AppContainer>
      <ThemeProvider theme={{}}>
        <Routes />
        <ToastContainer />
        <Confirm />
      </ThemeProvider>
    </AppContainer>
  );
};

const AppWrapper: FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

export default AppWrapper;
