import { FC, InputHTMLAttributes, useMemo, useState } from 'react';
import { DefaultTFuncReturn } from 'i18next';
import { Eye, EyeHide } from 'icons';

import { StyledInput } from './styled';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: DefaultTFuncReturn;
  error?: string;
}

const Input: FC<IProps> = ({ label, error, className, type, ...props }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const inputType = useMemo(() => {
    if (type === 'password') {
      if (isPasswordVisible) {
        return 'text';
      }

      return 'password';
    }

    return type;
  }, [isPasswordVisible, type]);

  const toggleVisible = () => {
    setPasswordVisible(prev => !prev);
  };

  return (
    <StyledInput isError={!!error} type={type} className={className}>
      {!!label && <label htmlFor={props.id}>{label}</label>}
      <input type={inputType} {...props} />
      {type === 'password' && (
        <span className="eye-icon" onClick={toggleVisible}>
          {isPasswordVisible ? <Eye /> : <EyeHide />}
        </span>
      )}
    </StyledInput>
  );
};

export default Input;
