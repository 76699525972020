import { FC, memo } from 'react';

import { StyledTalking } from './styled';

const Talking: FC = () => {
  return (
    <StyledTalking>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </StyledTalking>
  );
};

export default memo(Talking);
