import styled from 'styled-components';

export const PageWrapper = styled.div`
  background-color: var(--layout-bg-color);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckBrowserContent = styled.div`
  width: 100%;
  max-width: 668px;
  padding: 40px 32px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  text-align: center;
  h3 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    margin: 0;
  }
  p {
    font-size: 14px;
    line-height: 1.57;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 40px;
  }
  ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    a {
      color: var(--blue-2);
      text-decoration: underline;
      user-select: none;
      span {
        display: block;
        padding-top: 12px;
      }
    }
  }
`;
