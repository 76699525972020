import styled from 'styled-components';

export const StyledCopyLink = styled.div``;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 8px;
  border: 1px solid var(--neutral-20);
  border-radius: 6px;
  margin-bottom: 10px;
  .left {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: calc(100% - 108px);
    width: 100%;
    .icon {
      height: 24px;
      display: inline-block;
      padding-right: 8px;
      border-right: 1px solid var(--gray);
      svg {
        color: var(--gray);
      }
    }
    p {
      padding-left: 8px;
      width: 90%;
      color: var(--blue-6);
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  button {
    height: 100%;
    min-width: 100px;
    flex: 0 0 100px;
    background-color: var(--success);
    border: none;
    border-radius: 4px;
    color: var(--white);
    cursor: pointer;
    transition: opacity 0.3s;
    &:active {
      opacity: 0.3;
    }
  }
`;

export const StyledWarning = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  p {
    color: var(--accent);
    font-size: 14px;
    padding-left: 8px;
    a {
      color: var(--accent);
    }
  }
`;

export const StyledLoading = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
