import { FC, memo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectAppointmentData,
  selectAppointmentFiles
} from 'store/appointments/selectors';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, Pdf } from 'icons';
import instance from 'services/api';
import axios from 'axios';
import config from 'config';

import { StyledFiles } from './styled';
import { Title } from '../styled';
import { downloadFileFromUrl } from 'utils/file';
import Loading from 'components/shared/Loading';

const Files: FC = () => {
  // translations
  const { t } = useTranslation();
  // redux
  const appointment = useAppSelector(selectAppointmentData, shallowEqual);
  const data = useAppSelector(selectAppointmentFiles, shallowEqual);
  const [loading, setLoading] = useState('');

  const renderFileIcon = (name?: string) => {
    if (!name) return null;

    const fileName = name.split('.');

    switch (fileName[fileName.length - 1]) {
      case 'pdf':
        return <Pdf />;

      default:
        return <Link />;
    }
  };

  const downloadFile = async (value?: string) => {
    try {
      if (!value || loading) {
        return;
      }

      setLoading(value);
      const res = await instance.get(
        `/storage/file/url/${value}?user_id=${appointment?.patient_id}`,
        {
          baseURL: config.image_url
        }
      );

      const { url } = res.data.data;

      downloadFileFromUrl(url, value);
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        alert(error.response.data.message || 'Something went wrong');
      }
    } finally {
      setLoading('');
    }
  };

  if (!data?.length) return null;

  return (
    <>
      <Title>{t('files_documents')}</Title>
      <StyledFiles>
        <ul>
          {data.map(item => (
            <li
              key={`file-${item.id}`}
              style={
                loading === item.file
                  ? { opacity: 0.8, cursor: 'pointer' }
                  : { cursor: 'pointer' }
              }
              onClick={() => downloadFile(item.file)}
            >
              <a>
                {renderFileIcon(item.file)}
                <span>
                  {loading === item.file ? (
                    <Loading color="primary-new" size={20} />
                  ) : (
                    item.file
                  )}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </StyledFiles>
    </>
  );
};

export default memo(Files);
