import { ReactNode, FC, useState, useEffect, useRef } from 'react';
import { ArrowDown } from 'icons';

import { StyledSelect } from './styled';

interface IProps {
  placeholder?: string;
  value?: string;
  icon?: ReactNode;
  options: {
    label: ReactNode;
    value: string;
  }[];
  disabled?: boolean;
  footer?: ReactNode;
  onChange(value: string): void;
}

const Select: FC<IProps> = ({
  placeholder,
  value,
  options,
  icon = null,
  disabled,
  footer,
  onChange
}) => {
  const [isOpened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const onSelect = (e: string) => {
    setOpened(false);
    onChange(e);
  };

  const selectedOption = options.find(item => item.value === value);

  return (
    <StyledSelect ref={ref} isOpened={isOpened}>
      <button
        disabled={disabled}
        className="select-button"
        onClick={() => setOpened(!isOpened)}
      >
        {icon}
        <span>{selectedOption?.label || placeholder}</span>
        <ArrowDown />
      </button>
      {isOpened && (
        <ul className="select-list">
          {options.map(item => {
            const isActive = item.value === value;

            return (
              <li
                key={`select-item-${item.value}`}
                className={`select-list-item ${isActive ? 'active' : ''}`}
                onClick={() => onSelect(item.value)}
              >
                {item.label}
              </li>
            );
          })}
          {footer && (
            <li className="select-list-item select-footer">{footer}</li>
          )}
        </ul>
      )}
    </StyledSelect>
  );
};

export default Select;
