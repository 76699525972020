import styled, { css } from 'styled-components';

import { IStyledTooltip } from './types';

const getTooltipStyles = (placement: IStyledTooltip['placement']) => {
  switch (placement) {
    case 'right':
      return css`
        left: 0;
        &:after {
          left: 8px;
        }
      `;
    case 'center':
      return css`
        left: 50%;
        transform: translateX(-50%);
        &:after {
          left: 50%;
          transform: translateX(-50%);
        }
      `;
    case 'left':
      return css`
        right: 0;
        &:after {
          right: 8px;
        }
      `;
  }
};

export const StyledTooltip = styled.div<IStyledTooltip>`
  position: relative;
  .tooltip-wrapper {
    position: absolute;
    z-index: 999;
    bottom: 100%;
    margin-bottom: 8px;
    background: var(--light-bg);
    border-radius: 2px;
    padding: 8px;
    ${({ placement }) => getTooltipStyles(placement)}
    p {
      color: var(--primary-dark);
      font-size: 14px;
      white-space: nowrap;
    }
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      border: 5px solid;
      border-color: var(--light-bg) transparent transparent;
    }
  }
`;
