export const getDevices = async (audio = true, video = true) => {
  const devices = await navigator.mediaDevices.enumerateDevices();

  let microphones: MediaDeviceInfo[] = [];
  let cameras: MediaDeviceInfo[] = [];

  if (audio) {
    microphones = devices.filter(d => d.kind === 'audioinput');
  }

  if (video) {
    cameras = devices.filter(d => d.kind === 'videoinput');
  }

  const speakers = devices.filter(d => d.kind === 'audiooutput');

  return {
    microphones,
    cameras,
    speakers
  };
};
