import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IUIState, ModalKeys, SetModalParams, ToggleModal } from './types';

const initialState: IUIState = {
  modals: {
    [ModalKeys.confirm]: { visible: false },
    [ModalKeys.shareLink]: { visible: false },
    [ModalKeys.desktopShareScreen]: { visible: false },
    [ModalKeys.checkDevices]: { visible: false },
    [ModalKeys.permission]: { visible: false }
  }
};

const uisSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<ToggleModal>) => {
      const { name, value, params } = action.payload;

      if (typeof value === 'undefined') {
        state.modals[name].visible = !state.modals[name].visible;
      } else {
        state.modals[name].visible = value;
      }

      if (params) {
        state.modals[name].params = params;
      }
    },
    setModalParams: (state, action: PayloadAction<SetModalParams>) => {
      const { name, params } = action.payload;

      state.modals[name].params = params;
    }
  }
});

export const { toggleModal, setModalParams } = uisSlice.actions;
export default uisSlice.reducer;
