import { useContext } from 'react';

import { StreamStateContext } from '.';

const useStreamStateContext = () => {
  const context = useContext(StreamStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useStreamStateContext must be used within a StreamProvider'
    );
  }

  return context;
};

export default useStreamStateContext;
