import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import {
  selectDevicesCameras,
  selectDevicesMicrophones,
  selectSelectedCamera,
  selectSelectedMicrophone
} from 'store/devices/selectors';
import { chooseCamera, chooseMicrophone } from 'store/devices/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsSettingsOpen } from 'store/webrtc/selectors';
import { toggleSettings } from 'store/webrtc/slice';

import { Modal } from 'components/shared';
import {
  SettingItem,
  SettingsButton,
  SettingsMenu,
  SettingsMenuContent,
  SettingsMenuWrapper
} from './styled';

const MENUS = {
  video: 'video',
  audio: 'audio'
};

interface IProps {
  onSwitchCamera?(device: MediaDeviceInfo): void;
  onSwitchMicrophone?(device: MediaDeviceInfo): void;
}

const Settings: FC<IProps> = ({ onSwitchCamera, onSwitchMicrophone }) => {
  // translations
  const { t } = useTranslation();
  // redux
  const dispatch = useAppDispatch();
  const cameras = useAppSelector(selectDevicesCameras, shallowEqual);
  const selectedCamera = useAppSelector(selectSelectedCamera);
  const microphones = useAppSelector(selectDevicesMicrophones, shallowEqual);
  const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
  const isOpen = useAppSelector(selectIsSettingsOpen);

  // states
  const [active, setActive] = useState(MENUS.video);

  const onClose = () => {
    dispatch(toggleSettings(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('settings')}>
      <SettingsMenuWrapper>
        <SettingsMenu>
          <li>
            <SettingsButton
              onClick={() => setActive(MENUS.video)}
              active={active === MENUS.video}
            >
              {t('video')}
            </SettingsButton>
          </li>
          <li>
            <SettingsButton
              onClick={() => setActive(MENUS.audio)}
              active={active === MENUS.audio}
            >
              {t('audio')}
            </SettingsButton>
          </li>
        </SettingsMenu>
        <SettingsMenuContent>
          {active === MENUS.video ? (
            <>
              <SettingItem>
                <label htmlFor="camera-device">{t('choose_camera')}</label>
                <select
                  id="camera-device"
                  value={selectedCamera}
                  onChange={e => {
                    const camera = cameras.find(
                      item => item.deviceId === e.target.value
                    );

                    if (camera) {
                      dispatch(chooseCamera(camera));
                      onSwitchCamera?.(camera);
                    }
                  }}
                >
                  {cameras.map(device => (
                    <option
                      key={`camera-device-${device.deviceId}`}
                      value={device.deviceId}
                    >
                      {device.label}
                    </option>
                  ))}
                </select>
              </SettingItem>
            </>
          ) : (
            <>
              <SettingItem>
                <label htmlFor="microphone-device">
                  {t('choose_microphone')}
                </label>
                <select
                  id="microphone-device"
                  value={selectedMicrophone}
                  onChange={e => {
                    const microphone = microphones.find(
                      item => item.deviceId === e.target.value
                    );

                    if (microphone) {
                      dispatch(chooseMicrophone(microphone));
                      onSwitchMicrophone?.(microphone);
                    }
                  }}
                >
                  {microphones.map(device => (
                    <option
                      key={`microphone-device-${device.deviceId}`}
                      value={device.deviceId}
                    >
                      {device.label}
                    </option>
                  ))}
                </select>
              </SettingItem>
              {/* <SettingItem>
                  <label htmlFor="speaker-device">Choose speaker</label>
                  <select
                    id="speaker-device"
                    value={selectedSpeaker?.deviceId}
                    onChange={e => {
                      const speaker = speakers.find(
                        item => item.deviceId === e.target.value
                      );

                      speaker && chooseSpeaker(speaker);
                    }}
                  >
                    {speakers.map(device => (
                      <option
                        key={`speaker-device-${device.deviceId}`}
                        value={device.deviceId}
                      >
                        {device.label}
                      </option>
                    ))}
                  </select>
                </SettingItem> */}
            </>
          )}
        </SettingsMenuContent>
      </SettingsMenuWrapper>
    </Modal>
  );
};

export default memo(Settings);
