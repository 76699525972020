import styled from 'styled-components';

export const StyledCallEnded = styled.div`
  background-color: var(--white);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  background-color: var(--white);
  box-shadow: 0px 12px 40px rgba(192, 196, 234, 0.25);
  max-width: 800px;
  padding: 50px;
  border-radius: 5px;
  flex: 1 0 100%;
  width: 100%;
`;

export const Content = styled.div`
  max-width: 335px;
  margin: 0 auto;
  h2 {
    text-align: center;
    color: var(--blue-5);
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 32px;
  }
`;

export const SuccessButton = styled.button`
  padding: 10px 30px;
  font-size: 18px;
  font-weight: bold;
  color: var(--white);
  border: none;
  cursor: pointer;
  background-color: #3ab54a;
  border-radius: 10px;
  &:disabled {
    opacity: 0.6;
  }
`;

export const Button = styled.button`
  padding: 10px 30px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #c8d2e6;
  color: var(--blue-6);
  opacity: 0.6;
  border-radius: 10px;
`;

export const QuestionButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -8px;
  button {
    margin: 0 8px;
  }
`;

export const FormItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  label {
    padding-left: 12px;
    display: block;
    font-weight: bold;
    font-size: 18px;
    color: var(--blue-5);
    cursor: pointer;
  }
`;

export const Textarea = styled.textarea`
  resize: none;
  height: 80px;
  width: 100%;
  border: 1px solid #c7cde5;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
`;

export const SubmitButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const RatingModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 24px;
`;

export const RatingModalContent = styled.div`
  background-color: var(--white);
  box-shadow: 0px 12px 40px rgba(192, 196, 234, 0.25);
  max-width: 600px;
  width: 100%;
  flex: 1 0 100%;
  padding: 24px;
  border-radius: 8px;
  h3 {
    color: var(--blue-6);
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 24px;
  }
`;

export const RatingModalContainer = styled.div``;

export const StarsContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  button {
    margin: 0 2px;
  }
  svg {
    width: 24px;
    height: 24px;
    transition: all 0.2s;
  }
`;

export const StarButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

export const SuccessIconContainer = styled.div`
  padding-bottom: 32px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  svg {
    max-width: 100%;
  }
`;

export const SuccessButtonContainer = styled.div`
  button {
    width: 100%;
  }
`;

export const SuccessSection = styled.div`
  > h2 {
    margin-bottom: 8px;
  }
  > p {
    margin-bottom: 32px;
    text-align: center;
    font-size: 18px;
  }
`;

export const Reconnect = styled.div`
  text-align: center;
  padding-top: 16px;
  p {
    color: var(--blue-5);
    font-size: 14px;
    padding-bottom: 2px;
  }
  button {
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--blue-2);
  }
`;
