import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledError } from './styled';

const ErrorPage: FC = () => {
  // translations
  const { t } = useTranslation();

  return (
    <StyledError>
      <div>
        <p>{t('something_wrong')}</p>
        <a href="https://medicusunion.me/">{t('back_home')}</a>
      </div>
    </StyledError>
  );
};

export default ErrorPage;
