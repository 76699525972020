import styled from 'styled-components';

export const StyledFooterButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`;
