import { FC, memo, useCallback } from 'react';
import {
  selectDevicesCameras,
  selectSelectedCamera
} from 'store/devices/selectors';
import { chooseCamera } from 'store/devices/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Video } from 'icons';
import { useTranslation } from 'react-i18next';
import { toggleModal } from 'store/ui/slice';
import { ModalKeys } from 'store/ui/types';
import { shallowEqual } from 'react-redux';

import { Select } from 'components/ui';
import { StyledFooterButton } from './styled';

interface Props {
  disabled?: boolean;
  onSwitch(device: MediaDeviceInfo): void;
}

const VideoSelect: FC<Props> = ({ disabled, onSwitch }) => {
  const { t } = useTranslation();
  const data = useAppSelector(selectDevicesCameras, shallowEqual);
  const selected = useAppSelector(selectSelectedCamera);
  const dispatch = useAppDispatch();

  const onSelect = useCallback(
    (id: string) => {
      const device = data.find(item => item.deviceId === id);

      if (device) {
        dispatch(chooseCamera(device));
        onSwitch(device);
      }
    },
    [data, dispatch, onSwitch]
  );

  const onClickCheck = useCallback(() => {
    dispatch(toggleModal({ name: ModalKeys.checkDevices, value: true }));
  }, [dispatch]);

  const renderFooter = useCallback(
    () => (
      <StyledFooterButton onClick={onClickCheck}>
        <Video width={20} height={20} className="select-placeholder-icon" />
        {t('make_test_recording')}
      </StyledFooterButton>
    ),
    [onClickCheck, t]
  );

  if (!data.length) {
    return null;
  }

  return (
    <Select
      value={selected}
      options={data.map(item => ({
        value: item.deviceId,
        label: item.label
      }))}
      onChange={onSelect}
      icon={<Video className="select-placeholder-icon" />}
      disabled={disabled}
      footer={renderFooter()}
    />
  );
};

export default memo(VideoSelect);
