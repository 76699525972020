import { useContext } from 'react';

import { StreamDispatchContext } from '.';

const useStreamDispatchContext = () => {
  const context = useContext(StreamDispatchContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useStreamDispatchContext must be used within a StreamProvider'
    );
  }

  return context;
};

export default useStreamDispatchContext;
