import { FC } from 'react';
import { USER_ID } from 'constants/global';

import { RTCVideo } from 'components/shared';
import { ILocalRTCVideo } from './types';

const LocalRTCVideo: FC<ILocalRTCVideo> = ({ pinned, stream }) => {
  return (
    <RTCVideo
      self
      id={USER_ID}
      key="video-item-local-user"
      pinned={pinned}
      stream={stream}
      index={-1}
    />
  );
};

export default LocalRTCVideo;
