import { FC } from 'react';

import { StyledLoading } from './styled';

export interface ILoadingProps {
  color: string;
  size: number;
}

const Loading: FC<Partial<ILoadingProps>> = ({
  color = 'white',
  size = 80
}) => (
  <StyledLoading color={color} size={size}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </StyledLoading>
);

export default Loading;
