export const USER_TYPES = {
  translator: 'translator',
  patient: 'patient',
  doctor: 'doctor'
} as const;

const GANDER_MALE_VALUE = '1';
const GANDER_FEMALE_VALUE = '2';

export const GENDERS_VALUES = {
  [GANDER_MALE_VALUE]: 'male',
  [GANDER_FEMALE_VALUE]: 'female'
};
