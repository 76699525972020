import styled from 'styled-components';

const BUTTON_WIDTH = 200;
const LIST_WIDTH = 240;

export const StyledSelect = styled.div<{ isOpened?: boolean }>`
  position: relative;
  .select-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 20px;
    width: ${BUTTON_WIDTH}px;
    span {
      flex: 1;
      display: inline-block;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--black);
    }
    &:hover {
      // TODO: color name
      background-color: rgba(246, 247, 251, 1);
    }
    ${({ isOpened }) => isOpened && `background-color: rgba(246, 247, 251, 1);`}
  }
  .select-list {
    position: absolute;
    left: -${(LIST_WIDTH - BUTTON_WIDTH) / 2}px;
    top: 100%;
    z-index: 10;
    padding: 8px 0;
    margin-top: 8px;
    background-color: var(--white);
    box-shadow: 0px 4px 20px 0px rgba(231, 234, 246, 1);
    border-radius: 8px;
    list-style: none;
    width: ${LIST_WIDTH}px;
    .select-list-item {
      cursor: pointer;
      white-space: nowrap;
      padding: 8px;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      font-size: 14px;
      &:hover,
      &.active {
        // TODO: color name
        background-color: rgba(239, 243, 255, 0.6);
      }
    }
  }
  .select-placeholder-icon,
  .select-placeholder-icon * {
    fill: var(--gray-1);
  }
`;
