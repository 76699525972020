import { FC, memo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectAppointmentNotes } from 'store/appointments/selectors';
import { shallowEqual } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Add } from 'icons';

import { AddButton, StyledNotes } from './styled';
import { INotesProps } from './types';
import { Title } from '../styled';
import AddNote from '../AddNote';

const Notes: FC<INotesProps> = ({ appointmentId, sender, isMine }) => {
  // translations
  const { t } = useTranslation();
  // redux
  const data = useAppSelector(
    selectAppointmentNotes(isMine, sender),
    shallowEqual
  );

  // states
  const [isAddingNote, setAddingNote] = useState(false);
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {!!data?.length && (
        <>
          {isMine ? (
            <Title>{t('your_notes')}</Title>
          ) : (
            <Title>{t(`${sender}_notes`)}</Title>
          )}
          <StyledNotes>
            <ul>
              {(showMore ? data : [data[data.length - 1]]).map(item => (
                <li key={`note-${item.id}`}>
                  <p dangerouslySetInnerHTML={{ __html: item.note }} />
                  <span>
                    {dayjs(item.created_at).format('DD.MM.YYYY HH:mm')}
                  </span>
                </li>
              ))}
            </ul>
            {data.length > 1 && (
              <div className="more-button">
                <button onClick={() => setShowMore(prev => !prev)}>
                  {t(showMore ? 'less' : 'more')}
                </button>
              </div>
            )}
          </StyledNotes>
        </>
      )}
      {isMine && (
        <AddButton onClick={() => setAddingNote(true)}>
          <span>{t('add_note')}</span>
          <Add />
        </AddButton>
      )}
      <AddNote
        appointmentId={appointmentId}
        isOpen={isAddingNote}
        onClose={() => setAddingNote(false)}
      />
    </>
  );
};

export default memo(Notes);
