import { FC, memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectAppointmentIsOpened,
  selectAppointmentUsers,
  selectUserType
} from 'store/appointments/selectors';
import {
  refetchAppointmentData,
  toggleAppointment
} from 'store/appointments/slice';
import { shallowEqual } from 'react-redux';
import { USER_TYPES } from 'constants/users';
import { OpenLink } from 'icons';
import { getWebsiteUrl } from 'constants/url';
import { encodeToBase64 } from '@medicusunion/front-utils';

import { Sidebar } from 'components/shared';
import { SidebarRef } from 'components/shared/Sidebar/types';
import { Title } from './styled';
import Notes from './Notes';
import UserItem from './UserItem';
import Files from './Files';
import { getIdStart } from 'utils/string';

const AppointmentInfo: FC = () => {
  // translations
  const { t } = useTranslation();
  // refs
  const sidebarRef = useRef<SidebarRef>(null);
  // redux
  const dispatch = useAppDispatch();
  const isOpened = useAppSelector(selectAppointmentIsOpened);
  const userType = useAppSelector(selectUserType);
  const users = useAppSelector(selectAppointmentUsers, shallowEqual);

  const onClose = () => {
    dispatch(toggleAppointment(false));
  };

  useEffect(() => {
    if (isOpened) {
      dispatch(refetchAppointmentData());
    }
  }, [dispatch, isOpened]);

  const appointmentId = useMemo(() => {
    const paths = window.location.pathname.split('/');

    return paths[paths.length - 1];
  }, []);

  const href = useMemo(() => {
    const url = getWebsiteUrl(userType);
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    const compressedToken = encodeToBase64(token);

    const compressedRefresh = encodeToBase64(refreshToken);

    switch (userType) {
      case USER_TYPES.doctor:
      case USER_TYPES.translator:
        return `${url}/appointments/details/${appointmentId}?t=${compressedToken}&r=${compressedRefresh}`;
      case USER_TYPES.patient:
        return `${url}/appointments/${appointmentId}`;
    }
  }, [appointmentId, userType]);

  return (
    <Sidebar
      title={t('information')}
      isOpen={isOpened}
      onClose={onClose}
      ref={sidebarRef}
    >
      <Title style={{ display: 'flex', alignItems: 'center' }}>
        {t('appointment_details_id', { id: getIdStart(appointmentId) })}
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: 8 }}
        >
          <OpenLink
            width={18}
            height={18}
            style={{ verticalAlign: 'bottom' }}
          />
        </a>
      </Title>
      {users.map(user => {
        if (!user) return null;

        return (
          <UserItem
            key={`user-${user.id}`}
            avatar={user.avatar}
            firstName={user.first_name}
            lastName={user.last_name}
            extra={user.extra}
          />
        );
      })}
      <Notes
        sender={
          userType !== USER_TYPES.doctor
            ? USER_TYPES.doctor
            : USER_TYPES.patient
        }
        appointmentId={appointmentId}
        isMine={false}
      />
      <Notes
        sender={userType === USER_TYPES.translator ? USER_TYPES.patient : ''}
        appointmentId={appointmentId}
        isMine={userType !== USER_TYPES.translator}
      />
      <Files />
    </Sidebar>
  );
};

export default memo(AppointmentInfo);
