import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectShareApprovalStatus,
  selectUserName
} from 'store/webrtc/selectors';
import { ShareApprovalsEnum } from 'store/webrtc/types';
import { Check, Close } from 'icons';

import { StyledUserItem } from './styled';
import { Loading } from 'components/shared';

interface IProps {
  id: string;
}

const UserItem: FC<IProps> = ({ id }) => {
  const status = useAppSelector(selectShareApprovalStatus(id));
  const userName = useAppSelector(selectUserName(id));

  const renderStatus = () => {
    switch (status) {
      case ShareApprovalsEnum.approved:
        return (
          <span>
            <Check />
          </span>
        );
      case ShareApprovalsEnum.rejected:
        return (
          <span className="danger">
            <Close width={18} height={18} />
          </span>
        );
      default:
        return (
          <span>
            <Loading color="blue-6" size={20} />
          </span>
        );
    }
  };

  return (
    <StyledUserItem>
      <p>{userName}</p>
      <span>{renderStatus()}</span>
    </StyledUserItem>
  );
};

export default UserItem;
