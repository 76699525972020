import styled from 'styled-components';

export const StyledCaptions = styled.div`
  position: absolute;
  bottom: 110px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    width: 300px;
    li {
      padding: 8px;
      color: var(--white);
      margin-bottom: 4px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 8px;
      span {
        font-weight: bold;
      }
    }
  }
`;
