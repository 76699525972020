import { FC, memo, useEffect, useRef, useState } from 'react';
import useStreamStateContext from 'providers/Stream/useStreamStateContext';
import { useTranslation } from 'react-i18next';
import { Play } from 'icons';

import { RECORD_TIME, StyledStreamRecord } from './styled';
import { Button } from 'components/ui';
import { wait } from 'utils/wait';

const MIME_TYPES = ['video/webm', 'video/mp4', 'video/ogg'];

enum Status {
  not_started = 'not_started',
  started = 'started',
  ended = 'ended'
}

const StreamRecord: FC = () => {
  const { t } = useTranslation();
  const localMediaElement = useRef<HTMLVideoElement | null>(null);
  const resultMediaElement = useRef<HTMLVideoElement | null>(null);
  const localStream = useStreamStateContext();
  const [status, setStatus] = useState<Status>(Status.not_started);
  const [isPlaying, setPlaying] = useState(false);

  useEffect(() => {
    if (status === Status.started && localMediaElement.current && localStream) {
      localMediaElement.current.srcObject = localStream;
    }
  }, [localStream, status]);

  const onRecord = () => {
    if (localStream) {
      setStatus(Status.started);
      const recorder = new MediaRecorder(localStream);
      const data: Blob[] = [];

      recorder.ondataavailable = event => data.push(event.data);
      recorder.start();

      const stopped = new Promise((resolve, reject) => {
        recorder.onstop = resolve;
        recorder.onerror = event => reject(event);
      });

      const recorded = wait(RECORD_TIME).then(() => {
        if (recorder.state === 'recording') {
          recorder.stop();
        }
      });

      Promise.all([stopped, recorded]).then(() => {
        const type = MIME_TYPES.find(item =>
          MediaRecorder.isTypeSupported(item)
        );

        const recordedBlob = new Blob(data, { type });

        setStatus(Status.ended);

        setTimeout(() => {
          if (resultMediaElement.current) {
            resultMediaElement.current.src = URL.createObjectURL(recordedBlob);
          }
        }, 100);
      });
    }
  };

  const render = () => {
    switch (status) {
      case Status.started:
        return (
          <div className="recording-loading">
            <video
              ref={localMediaElement}
              controls={false}
              muted
              autoPlay
              playsInline
            />
            <div className="recording-loading-line" />
            <h6>{t('say_something')}</h6>
            <p>{t('say_something_example')}</p>
          </div>
        );
      case Status.ended:
        return (
          <div className="recorded-video">
            <div className="recorded-video-container">
              <video
                ref={resultMediaElement}
                controls={false}
                muted={false}
                autoPlay={false}
                onEnded={() => setPlaying(false)}
                onPlay={() => setPlaying(true)}
                playsInline
              />
              <p>{t('thank_you_for_testing')}</p>
              {!isPlaying && (
                <button
                  className="play-button"
                  onClick={() => resultMediaElement.current?.play()}
                >
                  <Play />
                </button>
              )}
            </div>
            <Button
              onClick={() => setStatus(Status.not_started)}
              title={t('check_again')}
            />
          </div>
        );
      default:
        return (
          <div className="record-content">
            <p>{t('record_short_video')}</p>
            <Button onClick={onRecord} title={t('start_recording')} />
          </div>
        );
    }
  };

  return <StyledStreamRecord>{render()}</StyledStreamRecord>;
};

export default memo(StreamRecord);
