import styled from 'styled-components';

export const StyledWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
`;

export const Header = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 24px 32px;
  svg {
    width: 165px;
    height: 30px;
  }
  @media only screen and (max-width: 720px) {
    padding: 16px;
    justify-content: center;
  }
`;

export const StyledMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media only screen and (max-width: 720px) {
    align-items: flex-start;
  }
`;

export const StyledContent = styled.div<{ isRotated?: boolean }>`
  max-width: 696px;
  flex: 1 0 100%;
  width: 100%;
  text-align: center;
  padding: 16px;
  video {
    min-width: 270px;
    max-width: 100%;
    transform: scaleX(-1);
    object-fit: contain;
    transition: all 0.4s;
    height: 330px;
    border-radius: 16px;
    background-color: var(--video-background-color);
    ${({ isRotated = true }) => isRotated && 'transform: scaleX(-1)'};
  }
  .button-container {
    display: flex;
    justify-content: center;
    padding-top: 8px;
  }
  form {
    max-width: 320px;
    margin: 0 auto;
    .input-container {
      margin-bottom: 16px;
    }
  }
  @media only screen and (max-width: 720px) {
    max-width: 100%;
    video {
      height: 250px;
    }
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

export const SelectsContainer = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  gap: 12px;
  @media only screen and (max-width: 720px) {
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: 16px;
  justify-content: center;
  left: 0;
  right: 0;
`;

export const UserAvatar = styled.img`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

export const ImagePlaceholder = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;

export const UserNames = styled.p`
  margin-bottom: 24px;
  color: var(--black);
  font-weight: 600;
  text-align: center;
  font-size: 14px;
`;
