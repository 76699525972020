import { Star } from 'icons';
import {
  FC,
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router';
import instance from 'services/api';
import { ReactComponent as SuccessIcon } from 'assets/img/success.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectWebrtcJoined } from 'store/webrtc/selectors';
import {
  selectAppointmentData,
  selectUserType
} from 'store/appointments/selectors';
import { selectUserData, selectIsLoggedIn } from 'store/user/selectors';
import { shallowEqual } from 'react-redux';
import { getWebsiteUrl } from 'constants/url';
import { IS_ELECTRON } from 'constants/app';

import { AnswersType, ReasonValuesEnum, StepsEnum } from './types';
import {
  Button,
  Card,
  Content,
  FormItem,
  QuestionButtons,
  RatingModal,
  RatingModalContainer,
  RatingModalContent,
  Reconnect,
  StarButton,
  StarsContainer,
  StyledCallEnded,
  SubmitButton,
  SuccessButton,
  SuccessButtonContainer,
  SuccessIconContainer,
  SuccessSection,
  Textarea
} from './styled';

const stars = [1, 2, 3, 4, 5];

const CallEnded: FC = () => {
  // translations
  const { t } = useTranslation();
  // navigation
  const { id } = useParams();
  const navigate = useNavigate();
  // redux
  const user = useAppSelector(selectUserData, shallowEqual);
  const appointment = useAppSelector(selectAppointmentData, shallowEqual);
  const isLoggedIn = useAppSelector(selectIsLoggedIn());
  const joined = useAppSelector(selectWebrtcJoined);
  const userType = useAppSelector(selectUserType);
  // states
  const [step, setStep] = useState(
    isLoggedIn ? StepsEnum.question : StepsEnum.success
  );

  const [hovered, setHovered] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [answers, setAnswers] = useState<AnswersType>({
    [StepsEnum.reason]: {
      values: []
    },
    [StepsEnum.rating]: {}
  });

  const isPatient = useMemo(
    () => isLoggedIn && appointment?.patient?.id === user?.id,
    [isLoggedIn, appointment, user?.id]
  );

  useEffect(() => {
    if (!joined) {
      navigate(`/home/${id}${window.location.search}`);
    }
  }, [id, joined, navigate]);

  const onClickNo = useCallback(() => {
    setLoading(false);
    setStep(StepsEnum.reason);
  }, []);

  const onClickYes = useCallback(async () => {
    setLoading(true);
    await instance.post('/appointments/call-reviews', {
      appointment_id: id,
      satisfied: true
    });
    setLoading(false);

    if (isPatient) {
      setStep(StepsEnum.rating);
    } else {
      setStep(StepsEnum.success);
    }
  }, [id, isPatient]);

  const onChangeReason = useCallback(
    (key: ReasonValuesEnum, value: boolean) => {
      setAnswers(prev => ({
        ...prev,
        [StepsEnum.reason]: {
          ...prev[StepsEnum.reason],
          values: value
            ? [...prev[StepsEnum.reason].values, key]
            : prev[StepsEnum.reason].values.filter(item => item !== key)
        }
      }));
    },
    []
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async e => {
      e.preventDefault();

      setLoading(true);
      await instance.post('/appointments/call-reviews', {
        appointment_id: id,
        review: answers.reason.text,
        issues: answers.reason.values,
        satisfied: false
      });
      setLoading(false);

      if (isPatient) {
        setStep(StepsEnum.rating);
      } else {
        setStep(StepsEnum.success);
      }
    },
    [answers, id, isPatient]
  );

  const onFinish = useCallback(async () => {
    setLoading(true);

    if (answers.rating.value) {
      await instance.post('/appointments/reviews', {
        receiver_id: appointment?.doctor?.id,
        review: answers.rating.text,
        rating: answers.rating.value
      });
    }

    setStep(StepsEnum.success);
  }, [answers.rating.text, answers.rating.value, appointment?.doctor?.id]);

  const onClickGo = () => {
    if (IS_ELECTRON) {
      return navigate('/');
    }

    const url = getWebsiteUrl(userType);

    if (url) {
      window.location.href = url;
    }
  };

  const reconnect = () => {
    window.location.href = `${window.location.origin}/home/${id}`;
  };

  const renderSteps = () => {
    switch (step) {
      case StepsEnum.question:
        return (
          <>
            <h2>{t('are_you_satisfied')}</h2>
            <QuestionButtons>
              <Button onClick={onClickNo}>{t('no')}</Button>
              <SuccessButton onClick={onClickYes}>{t('yes')}</SuccessButton>
            </QuestionButtons>
            <Reconnect>
              <p>{t('if_you_left')}</p>
              <button onClick={reconnect}>{t('go_back')}</button>
            </Reconnect>
          </>
        );
      case StepsEnum.reason:
      case StepsEnum.rating:
        return (
          <div>
            <h2>{t('are_you_satisfied')}</h2>
            <form onSubmit={onSubmit}>
              <FormItem>
                <input
                  type="checkbox"
                  name="technical"
                  id="technical"
                  value={ReasonValuesEnum.technical}
                  onChange={e =>
                    onChangeReason(ReasonValuesEnum.technical, e.target.checked)
                  }
                />
                <label htmlFor="technical">{t('technical_issues')}</label>
              </FormItem>
              <FormItem>
                <input
                  type="checkbox"
                  name="unsatisfactory"
                  id="unsatisfactory"
                  value={ReasonValuesEnum.unsatisfactory}
                  onChange={e =>
                    onChangeReason(
                      ReasonValuesEnum.unsatisfactory,
                      e.target.checked
                    )
                  }
                />
                <label htmlFor="unsatisfactory">
                  {t('unsatisfactory_consultation')}
                </label>
              </FormItem>
              <FormItem>
                <input
                  type="checkbox"
                  name="translation"
                  id="translation"
                  value={ReasonValuesEnum.translation}
                  onChange={e =>
                    onChangeReason(
                      ReasonValuesEnum.translation,
                      e.target.checked
                    )
                  }
                />
                <label htmlFor="translation">
                  {t('issues_with_translation')}
                </label>
              </FormItem>
              <div>
                <Textarea
                  name="text"
                  placeholder={t('your_option') || ''}
                  onChange={e => {
                    setAnswers(prev => ({
                      ...prev,
                      [StepsEnum.reason]: {
                        ...prev[StepsEnum.reason],
                        text: e.target.value
                      }
                    }));
                  }}
                />
              </div>
              <SubmitButton>
                <SuccessButton disabled={isLoading} type="submit">
                  {t('submit')}
                </SuccessButton>
              </SubmitButton>
            </form>
          </div>
        );
      case StepsEnum.success:
        return (
          <SuccessSection>
            <h2>{t('meeting_finished')}</h2>
            <p>{t('glad_to_help')}</p>
            <SuccessIconContainer>
              <SuccessIcon />
            </SuccessIconContainer>
            <SuccessButtonContainer>
              <SuccessButton onClick={onClickGo} type="button">
                {t('back_home')}
              </SuccessButton>
            </SuccessButtonContainer>
            {!isLoggedIn && (
              <Reconnect>
                <p>{t('if_you_left')}</p>
                <button onClick={reconnect}>{t('go_back')}</button>
              </Reconnect>
            )}
          </SuccessSection>
        );
    }
  };

  return (
    <StyledCallEnded>
      <Card>
        <Content>{renderSteps()}</Content>
      </Card>
      {step === StepsEnum.rating && (
        <RatingModal>
          <RatingModalContent>
            <h3>{t('doctor_rating')}</h3>
            <RatingModalContainer>
              <StarsContainer onMouseLeave={() => setHovered(0)}>
                {stars.map(item => {
                  const isActive =
                    hovered >= item ||
                    Number(answers[StepsEnum.rating]?.value) >= item;

                  return (
                    <StarButton
                      key={`star-item-${item}`}
                      onMouseOver={() => setHovered(item)}
                      onClick={() => {
                        setAnswers(prev => ({
                          ...prev,
                          [StepsEnum.rating]: {
                            ...prev[StepsEnum.rating],
                            value: item
                          }
                        }));
                      }}
                    >
                      <Star fill={isActive ? '#FAAD13' : '#A4B2CE'} />
                    </StarButton>
                  );
                })}
              </StarsContainer>
              <Textarea
                placeholder={t('review') || ''}
                onChange={e => {
                  setAnswers(prev => ({
                    ...prev,
                    [StepsEnum.rating]: {
                      ...prev[StepsEnum.rating],
                      text: e.target.value
                    }
                  }));
                }}
              />
            </RatingModalContainer>
            <SubmitButton>
              <SuccessButton
                disabled={isLoading}
                type="button"
                onClick={onFinish}
              >
                {t('submit')}
              </SuccessButton>
            </SubmitButton>
          </RatingModalContent>
        </RatingModal>
      )}
    </StyledCallEnded>
  );
};

export default CallEnded;
