import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectDevices = (state: RootState) => state.devices;

export const selectDevicesCameras = createSelector(
  [selectDevices],
  devices => devices.cameras
);

export const selectSelectedCamera = createSelector(
  [selectDevices],
  devices => devices.selectedCamera
);

export const selectDevicesMicrophones = createSelector(
  [selectDevices],
  devices => devices.microphones
);

export const selectSelectedMicrophone = createSelector(
  [selectDevices],
  devices => devices.selectedMicrophone
);

export const selectDevicesSpeakers = createSelector(
  [selectDevices],
  devices => devices.speakers
);

export const selectSelectedSpeaker = createSelector(
  [selectDevices],
  devices => devices.selectedSpeaker
);

export const selectVideoAllowed = createSelector(
  [selectDevices],
  devices => devices.videoAllowed
);

export const selectAudioAllowed = createSelector(
  [selectDevices],
  devices => devices.audioAllowed
);

export const selectIsLoadingDevices = createSelector(
  [selectDevices],
  devices => devices.isLoadingDevices
);
