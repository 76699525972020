import dayjs from 'dayjs';
import { IUserData } from 'store/user/types';

type ServerError = {
  [kay: string]: {
    [kay: string]: {
      msg: string;
    };
  };
};

type FieldsData = {
  name: string;
  errors: string[];
};

export const getFieldsDataFromServerError: (
  errors: ServerError
) => FieldsData[] = (errors: ServerError) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = Object.keys(errors).reduce((acc: any[], key: string) => {
    return [...acc, { name: key, errors: [errors[key].msg] }];
  }, []);

  return error as FieldsData[];
};

export type FileResponse = {
  fileList: { response?: { data: { image: string[] } }; name: string }[];
};

export const getFileNamesFromResponse = (
  data: FileResponse | string[] | string
): string[] | null => {
  if (!data) return null;

  if (typeof data === 'string') return [data];
  if (Array.isArray(data)) return data;

  const result = data.fileList
    .map(item => (item.response ? item.response.data?.image?.[0] : item.name))
    .filter(item => item);

  return result;
};

export const getUserName = (user?: IUserData | null) => {
  if (user?.first_name || user?.last_name) {
    return `${user?.first_name || ''} ${user?.last_name || ''}`;
  }

  return 'Guest';
};

export const getUserAge = (dob?: string | null) => {
  if (!dob) return null;

  return Math.max(0, dayjs().diff(dayjs(dob), 'years'));
};
