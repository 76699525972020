import { AxiosResponse } from 'axios';
import ACTIONS from 'socket/actions';
import { logger } from 'logger';
import { APP_ERRORS, APP_EVENTS } from 'logger/constants';
import instance from 'services/api';
import { USER_ID } from 'constants/global';

import console from 'utils/console';
import { MessageType } from 'hooks/useWebRTC/types';
import { CHAT_CHANNEL_ID, DEFAULT_ICE } from './cosntants';
import socket from './socket';
import {
  ConnectChannels,
  GetUserMedia,
  OnIceCandidate,
  OnNewPeer,
  OnNewPeerCB,
  OnReplacePeerIds,
  OnSessionDescription,
  OnSessionDescriptionCB,
  PeerID
} from './types';
import { getUserMedia } from './utils';

class WebRTC {
  iceServers: RTCIceServer[];
  dcs: Record<PeerID, RTCDataChannel>;
  pcs: Record<PeerID, RTCPeerConnection>;
  displayStream: MediaStream | null;
  constructor() {
    this.iceServers = [];
    this.dcs = {};
    this.pcs = {};
    this.displayStream = null;
  }

  // initialize all the important thing to make it work
  init = async () => {
    return Promise.allSettled([this.getIceServers()]);
  };

  // Get ice servers from the servers in case of any problems set default ones
  getIceServers = async () => {
    try {
      const url = '/room/turn-credentials';

      const res: AxiosResponse<{ data: RTCIceServer[] }> = await instance.get(
        url
      );

      this.iceServers = res?.data?.data || DEFAULT_ICE;
    } catch (err) {
      console.warn('ERROR | getIceServers | ', err);
      logger.error(APP_ERRORS.get_ice_servers, { err });
      this.iceServers = DEFAULT_ICE;
    }
  };

  // get user media stream
  getUserMedia = async (params: GetUserMedia) => {
    try {
      const stream = await getUserMedia(params);

      return stream;
    } catch (err) {
      console.warn('ERROR | getUserMedia | ', err);
      logger.error(APP_ERRORS.get_user_media, { err, params });
    }
  };

  // get display media stream
  getDisplayMedia = async (onStopSharing: () => void) => {
    try {
      this.displayStream = await navigator.mediaDevices.getDisplayMedia({
        audio: false
      });

      this.displayStream
        .getVideoTracks()[0]
        .addEventListener('ended', onStopSharing);

      logger.event(APP_EVENTS.share_screen);

      for (const peerID in this.pcs) {
        await this.shareDisplayMedia(peerID);
      }
    } catch (err) {
      console.warn('ERROR | getDisplayMedia | ', err);
      logger.error(APP_ERRORS.get_user_media, { err });
    }
  };

  // get display media stream
  getDisplayMediaElectron = async (
    sourceId: string,
    onStopSharing: () => void
  ) => {
    try {
      this.displayStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mandatory: {
            chromeMediaSource: 'desktop',
            chromeMediaSourceId: sourceId
          }
        }
      });

      this.displayStream
        .getVideoTracks()[0]
        .addEventListener('ended', onStopSharing);

      logger.event(APP_EVENTS.share_screen);

      for (const peerID in this.pcs) {
        await this.shareDisplayMedia(peerID);
      }
    } catch (err) {
      console.warn('ERROR | getDisplayMedia | ', err);
      logger.error(APP_ERRORS.get_user_media, { err });
    }
  };

  // connect RTC channels
  connectChannels = ({ peerID, onReceiveMessages }: ConnectChannels) => {
    try {
      const sendChannel = this.pcs[peerID].createDataChannel(CHAT_CHANNEL_ID);

      this.pcs[peerID].ondatachannel = (event: RTCDataChannelEvent) => {
        const receiveChannel = event.channel;

        receiveChannel.onmessage = onReceiveMessages(peerID);
      };

      this.dcs[peerID] = sendChannel;
    } catch (err) {
      console.warn('ERROR | connectChannels | ', err);
      logger.error(APP_ERRORS.connect_channels, { err, peerID });
    }
  };

  // send data to channels
  sendToChannels = (message: MessageType) => {
    try {
      Object.values(this.dcs)
        .filter(item => item.readyState === 'open')
        .forEach(item => item.send(JSON.stringify(message)));
    } catch (err) {
      console.warn('ERROR | sendToChannels | ', err);
      logger.error(APP_ERRORS.send_to_channels, { err });
    }
  };

  // on new peer added
  // create peer connection, add tracks connect data channels
  onNewPeer =
    ({
      localStream,
      ontrack,
      onReceiveMessages,
      onDisconnect,
      onConnect,
      onFail,
      onError
    }: OnNewPeer) =>
    async ({ peerID, createOffer, data }: OnNewPeerCB) => {
      console.log('ON_NEW_PEER', peerID, USER_ID);

      try {
        if (peerID === USER_ID || !localStream) {
          return;
        }

        const current = this.pcs[peerID];

        if (current) {
          this.onRemovePeer(peerID);
        }

        this.pcs[peerID] = new RTCPeerConnection({
          iceServers: this.iceServers
        });

        console.log('ON_NEW_PEER -> data', data);

        let tracksCount = 0;
        const maxTracksCount = data?.videoEnabled ? 2 : 1;

        this.pcs[peerID].ontrack = ({ streams: [remoteStream] }) => {
          tracksCount++;

          if (tracksCount === maxTracksCount) {
            ontrack({
              peerID,
              stream: remoteStream,
              data
            });
          }
        };

        this.pcs[peerID].onicecandidate = event => {
          if (event.candidate) {
            socket.sendToPeer(ACTIONS.RELAY_ICE, {
              peerID,
              iceCandidate: event.candidate
            });
          }
        };

        this.pcs[peerID].onconnectionstatechange = e => {
          console.log(
            'CONNECTION STATE CHANGED',
            this.pcs[peerID]?.connectionState,
            JSON.stringify(e, null, 4)
          );

          if (this.pcs[peerID]) {
            switch (this.pcs[peerID].connectionState) {
              case 'disconnected':
                console.log('DISCONNECTED', peerID);
                onDisconnect(peerID);
                break;
              case 'new':
              case 'closed':
              case 'connecting':
                // think about these 2
                break;
              case 'failed':
                onFail(peerID);
                break;
              case 'connected':
                onConnect(peerID);
                break;
              default:
                break;
            }
          } else {
            console.log(`peerID ${peerID} removed`);
          }
        };

        const localTracks = localStream
          .getTracks()
          .filter(item => item.readyState !== 'ended');

        localTracks.forEach(track => {
          console.log('addTrack', track);
          this.pcs[peerID].addTrack(track, localStream);
        });

        if (localTracks.length < 2) {
          this.pcs[peerID].addTransceiver('video', {
            direction: 'recvonly'
          });
        }

        if (createOffer) {
          this.connectChannels({
            peerID,
            onReceiveMessages
          });

          const offer = await this.pcs[peerID].createOffer();

          await this.pcs[peerID].setLocalDescription(offer);

          logger.event(APP_EVENTS.offer_sent, { peerID });

          socket.sendToPeer(ACTIONS.RELAY_SDP, {
            peerID,
            sessionDescription: offer
          });
        }
      } catch (err) {
        console.warn('ERROR | onNewPeer | ', err);
        logger.error(APP_ERRORS.on_new_peer, { peerID, err });
        onError();
      }
    };

  onRemovePeer = (peerID: PeerID) => {
    if (peerID in this.dcs) {
      this.dcs[peerID].close();
      delete this.dcs[peerID];
    }

    if (peerID in this.pcs) {
      this.pcs[peerID].close();
      delete this.pcs[peerID];
    }
  };

  // add/replace track to peer connections
  addOrReplaceTrackToPcs = async (
    stream: MediaStream,
    type: string,
    oldTrack?: MediaStreamTrack
  ) => {
    for (const peerID in this.pcs) {
      const track =
        type === 'video'
          ? stream.getVideoTracks()?.[0]
          : stream.getAudioTracks()?.[0];

      const sender = this.pcs[peerID].getSenders().find(sender => {
        return sender.track === oldTrack;
      });

      if (sender) {
        sender.replaceTrack(track);
      } else {
        this.pcs[peerID].addTrack(track, stream);
      }

      const offer = await this.pcs[peerID].createOffer();

      await this.pcs[peerID].setLocalDescription(offer);

      socket.sendToPeer(ACTIONS.RELAY_SDP, {
        peerID,
        sessionDescription: offer
      });
    }
  };

  // handle screen sharing and send offer
  shareDisplayMedia = async (peerID: string) => {
    if (this.displayStream) {
      const track = this.displayStream.getVideoTracks()?.[0];

      if (track) {
        const trackExists = this.pcs[peerID]
          .getSenders()
          .some(item => item.track === track);

        if (!trackExists) {
          this.pcs[peerID].addTrack(track, this.displayStream);
        }
      }

      const offer = await this.pcs[peerID].createOffer();

      await this.pcs[peerID].setLocalDescription(offer);

      socket.sendToPeer(ACTIONS.SHARE_SCREEN, {
        peerID,
        sessionDescription: offer
      });
    }
  };

  // stop screen sharing
  stopDisplayMedia = async (onStopSharing: () => void) => {
    this.displayStream
      ?.getVideoTracks()[0]
      .removeEventListener('ended', onStopSharing);

    this.displayStream?.getTracks().forEach(track => track.stop());
    this.displayStream = null;
    socket.sendToPeer(ACTIONS.STOP_SHARE_SCREEN);
    logger.event(APP_EVENTS.stop_share_screen);
  };

  // on session description
  onSessionDescription =
    ({ onReceiveMessages, cb }: OnSessionDescription) =>
    async ({ peerID, sessionDescription }: OnSessionDescriptionCB) => {
      try {
        await this.pcs[peerID].setRemoteDescription(
          new RTCSessionDescription(sessionDescription)
        );

        if (sessionDescription.type === 'offer') {
          this.connectChannels({
            peerID,
            onReceiveMessages
          });

          const answer = await this.pcs[peerID].createAnswer();

          await this.pcs[peerID].setLocalDescription(answer);

          logger.event(APP_EVENTS.answer_sent, { peerID });

          socket.sendToPeer(ACTIONS.RELAY_SDP, {
            peerID,
            sessionDescription: answer
          });

          this.shareDisplayMedia(peerID);

          cb?.(peerID);
        }
      } catch (err) {
        logger.error(APP_ERRORS.on_session_description, { peerID, err });
        console.warn('ERROR | onSessionDescription | ', err);
      }
    };

  // on screen sharing session description
  onScreenSharingSessionDescription =
    (cb: (stream: MediaStream, peerID: string) => void) =>
    async ({ peerID, sessionDescription }: OnSessionDescriptionCB) => {
      try {
        this.pcs[peerID].ontrack = ({ streams: [remoteStream] }) => {
          cb(remoteStream, peerID);
        };

        await this.pcs[peerID].setRemoteDescription(
          new RTCSessionDescription(sessionDescription)
        );

        if (sessionDescription.type === 'offer') {
          const answer = await this.pcs[peerID].createAnswer();

          await this.pcs[peerID].setLocalDescription(answer);

          socket.sendToPeer(ACTIONS.SHARE_SCREEN, {
            peerID,
            sessionDescription: answer
          });
        }
      } catch (err) {
        logger.error(APP_ERRORS.on_share_screen_session_description, {
          peerID,
          err
        });
        console.warn('ERROR | onScreenSharingSessionDescription | ', err);
      }
    };

  // on ice candidate
  onIceCandidate = async ({ peerID, iceCandidate }: OnIceCandidate) => {
    try {
      if (iceCandidate) {
        await this.pcs[peerID].addIceCandidate(
          new RTCIceCandidate(iceCandidate)
        );
      }
    } catch (err) {
      logger.error(APP_ERRORS.on_ice_candidate, { peerID, err });
      console.warn('ERROR | onIceCandidate | ', err);
    }
  };

  // replace peer ids on reconnect
  replacePeerIds = async ({ prev, peerID }: OnReplacePeerIds) => {
    if (this.pcs[prev]) {
      this.pcs[peerID] = this.pcs[prev];
    }

    if (this.dcs[prev]) {
      this.dcs[peerID] = this.dcs[prev];
    }

    delete this.pcs[prev];
    delete this.dcs[prev];
  };
}

export default new WebRTC();
