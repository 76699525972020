import { FC, ReactNode } from 'react';
import { DefaultTFuncReturn } from 'i18next';

import { StyledButton } from './styled';
import { Loading } from 'components/shared';
import { ButtonTypes } from './types';

interface IProps {
  title?: string | DefaultTFuncReturn;
  icon?: ReactNode;
  type?: ButtonTypes;
  disabled?: boolean;
  loading?: boolean;
  onClick?(): void;
}

const Button: FC<IProps> = ({
  title,
  icon,
  disabled,
  loading,
  type = ButtonTypes.primary,
  onClick
}) => (
  <StyledButton
    mode={type}
    disabled={disabled}
    onClick={() => !loading && onClick?.()}
    isIcon={!!icon}
  >
    {icon || title}
    {loading && <Loading size={20} />}
  </StyledButton>
);

export default Button;
