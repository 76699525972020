import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Eye, Link, Warning } from 'icons';
import copy from 'copy-to-clipboard';
import instance from 'services/api';
import { useParams } from 'react-router';
import { USER_ID } from 'constants/global';

import {
  StyledCopyLink,
  StyledItem,
  StyledLoading,
  StyledWarning
} from './styled';
import { Loading } from 'components/shared';

const CopyLink: FC = () => {
  // translations
  const { t } = useTranslation();
  // states
  const [isLoading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [inviteId, setInviteId] = useState('');
  // navigation
  const { id: roomID } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await instance.post('appointments/share-link', {
          appointmentId: roomID,
          peerID: USER_ID
        });

        if (!res?.data?.data?.password) {
          throw new Error();
        }

        setInviteId(res.data.data.inviteId);
        setPassword(res.data.data.password);
      } catch {
        //
      } finally {
        setLoading(false);
      }
    })();
  }, [roomID]);

  const href = window.location.href.replace('room', 'home');
  const url = `${href}?inviteId=${inviteId}`;

  if (isLoading || !password) {
    return (
      <StyledLoading>
        <Loading color="blue-7" />
      </StyledLoading>
    );
  }

  return (
    <StyledCopyLink>
      <StyledItem>
        <div className="left">
          <span className="icon">
            <Link />
          </span>
          <p>{url}</p>
        </div>
        <button onClick={() => copy(url)}>{t('copy')}</button>
      </StyledItem>
      <StyledItem>
        <div className="left">
          <span className="icon">
            <Eye />
          </span>
          <p>{password}</p>
        </div>
        <button onClick={() => copy(password)}>{t('copy')}</button>
      </StyledItem>
      <StyledWarning>
        <div>
          <Warning />
        </div>
        <p title="otp">
          {t('share_link_warning')}
          {/* currently we decided to not use OTP */}
          {/* <a
            title={t('otp') as string}
            target="_blank"
            href="https://en.wikipedia.org/wiki/One-time_password"
            rel="noreferrer"
          >
            (OTP)
          </a> */}
        </p>
      </StyledWarning>
    </StyledCopyLink>
  );
};

export default memo(CopyLink);
