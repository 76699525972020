import { RootState } from 'store';
import { selectUserData } from 'store/user/selectors';
import { USER_ID } from 'constants/global';
import { createSelector } from '@reduxjs/toolkit';

import { StreamId, StreamTypesEnum } from 'hooks/useWebRTC/types';
import { getUserName } from 'utils/helpers';
import { ShareApprovalsEnum } from './types';

export const selectWebrtc = (state: RootState) => state.webrtc;

export const selectRemoteStreams = createSelector(
  [selectWebrtc],
  webrtc => webrtc.remoteStreams
);

export const selectRemoteStreamsById = createSelector(
  [selectWebrtc],
  webrtc => webrtc.remoteStreamsById
);

export const selectRemoteStream = (id: StreamId) =>
  createSelector(
    [selectRemoteStreamsById],
    remoteStreamsById => remoteStreamsById[id] || {}
  );

export const selectRemoteStreamsCount = createSelector(
  [selectRemoteStreams],
  remoteStreams => remoteStreams.length
);

export const selectWebrtcJoined = createSelector(
  [selectWebrtc],
  webrtc => webrtc.joined
);

export const selectIsSettingsOpen = createSelector(
  [selectWebrtc],
  webrtc => webrtc.isSettingsOpen
);

export const selectIsSharingScreen = createSelector(
  [selectWebrtc],
  webrtc => webrtc.isSharingScreen
);

export const selectSharingScreenExists = createSelector(
  [selectWebrtc],
  webrtc => webrtc.sharingScreenExists
);

export const selectSpeaking = createSelector(
  [selectWebrtc],
  webrtc => webrtc.speaking
);

export const selectIsSpeaking = (peerID: string, muted: boolean) =>
  createSelector([selectSpeaking], speaking => {
    if (muted) {
      return false;
    }

    return speaking[peerID];
  });

export const selectRemoteStreamStream = (id: StreamId) =>
  createSelector(
    [selectRemoteStream(id)],
    remoteStream => remoteStream?.stream
  );

export const selectRemoteStreamUser = (id: StreamId) =>
  createSelector([selectRemoteStream(id)], remoteStream => remoteStream?.user);

export const selectRemoteStreamUserName = (id: StreamId) =>
  createSelector([selectRemoteStreamUser(id)], user => user?.name);

export const selectRemoteStreamUserAvatar = (id: StreamId) =>
  createSelector([selectRemoteStreamUser(id)], user => user?.avatar);

export const selectVideoStream = (id: StreamId) =>
  createSelector([selectRemoteStreamStream(id)], stream => {
    if (id === USER_ID) {
      return null;
    }

    return stream;
  });

export const selectRemoteStreamVideoEnabled = (id: StreamId) =>
  createSelector(
    [selectRemoteStream(id)],
    remoteStream => remoteStream?.videoEnabled
  );

export const selectRemoteStreamAudioEnabled = (id: StreamId) =>
  createSelector(
    [selectRemoteStream(id)],
    remoteStream => remoteStream?.audioEnabled
  );

export const selectRemoteStreamIsScreen = (id: StreamId) =>
  createSelector(
    [selectRemoteStream(id)],
    remoteStream => remoteStream?.type === StreamTypesEnum.screen
  );

export const selectRemoteStreamIsLoading = (id: StreamId) =>
  createSelector(
    [selectRemoteStream(id)],
    remoteStream => remoteStream?.isLoading
  );
export const selectRemoteStreamIsDisconnected = (id: StreamId) =>
  createSelector(
    [selectRemoteStream(id)],
    remoteStream => remoteStream?.disconnected
  );

export const selectUserName = (id: StreamId) =>
  createSelector(
    [selectUserData, selectRemoteStreamUserName(id)],
    (user, name) => {
      if (id === USER_ID) {
        return getUserName(user);
      }

      return name;
    }
  );

export const selectUserAvatar = (id: StreamId) =>
  createSelector(
    [selectUserData, selectRemoteStreamUserAvatar(id)],
    (user, avatar) => {
      if (id === USER_ID) {
        return user?.avatar;
      }

      return avatar;
    }
  );

export const selectSelfVideoEnabled = createSelector(
  [selectWebrtc],
  webrtc => webrtc.videoEnabled
);

export const selectSelfAudioEnabled = createSelector(
  [selectWebrtc],
  webrtc => webrtc.audioEnabled
);

export const selectVideoEnabled = (id: StreamId) =>
  createSelector(
    [selectSelfVideoEnabled, selectRemoteStreamVideoEnabled(id)],
    (selfVideoEnabled, remoteStreamVideoEnabled) => {
      if (id === USER_ID) {
        return selfVideoEnabled;
      }

      return remoteStreamVideoEnabled;
    }
  );

export const selectAudioEnabled = (id: StreamId) =>
  createSelector(
    [selectSelfAudioEnabled, selectRemoteStreamAudioEnabled(id)],
    (selfAudioEnabled, remoteStreamAudioEnabled) => {
      if (id === USER_ID) {
        return selfAudioEnabled;
      }

      return remoteStreamAudioEnabled;
    }
  );

export const selectIsStreamScreen = (id: StreamId) =>
  createSelector([selectRemoteStreamIsScreen(id)], isScreen => {
    if (id === USER_ID) {
      return false;
    }

    return isScreen;
  });

export const selectIsStreamLoading = (id: StreamId) =>
  createSelector([selectRemoteStreamIsLoading(id)], isLoading => {
    if (id === USER_ID) {
      return false;
    }

    return isLoading;
  });

export const selectIsStreamDisconnected = (id: StreamId) =>
  createSelector([selectRemoteStreamIsDisconnected(id)], IsDisconnected => {
    if (id === USER_ID) {
      return false;
    }

    return IsDisconnected;
  });

export const selectShareApprovalUsers = createSelector(
  [selectRemoteStreams],
  remoteStreams =>
    remoteStreams.filter(item => !item.startsWith(`${StreamTypesEnum.screen}-`))
);

export const selectShareApprovalUsersCount = createSelector(
  [selectShareApprovalUsers],
  users => users.length
);

export const selectShareApprovalStatuses = createSelector(
  [selectWebrtc],
  webrtc => webrtc.shareApprovals
);

export const selectShareApprovalStatus = (id: StreamId) =>
  createSelector(
    [selectShareApprovalStatuses],
    shareApprovals => shareApprovals[id]
  );

export const selectShareApprovedByEveryone = createSelector(
  [selectShareApprovalStatuses],
  shareApprovals =>
    Object.values(shareApprovals).every(
      item => item === ShareApprovalsEnum.approved
    )
);

export const selectIsApprovingInProgress = createSelector(
  [selectWebrtc],
  webrtc => webrtc.isApprovingInProgress
);

export const selectIsApprovingRequested = createSelector(
  [selectWebrtc],
  webrtc => webrtc.isApprovingRequested
);
