import { Close } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  StyledClose,
  StyledFooter,
  StyledModal
} from './styled';
import { IModalProps } from './types';

const Modal: FC<IModalProps> = ({
  title,
  isOpen,
  children,
  footer,
  width = 600,
  onClose
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <StyledModal>
      <ModalContainer width={width}>
        <ModalHeader>
          <StyledClose onClick={onClose}>
            <Close />
          </StyledClose>
        </ModalHeader>
        <ModalContent>
          {!!title && <h4>{title}</h4>}
          {children}
        </ModalContent>
        {!!footer && !!(footer.cancelButton || footer.okButton) && (
          <StyledFooter>
            {/* TODO: create button component */}
            {footer.cancelButton && (
              <button className="cancel" onClick={footer.cancelButton.onClick}>
                {footer.cancelButton.text || t('cancel')}
              </button>
            )}
            {/* TODO: create button component */}
            {footer.okButton && (
              <button
                className="ok"
                disabled={footer.okButton.disabled}
                onClick={footer.okButton.onClick}
              >
                {footer.okButton.text || 'OK'}
              </button>
            )}
          </StyledFooter>
        )}
      </ModalContainer>
    </StyledModal>
  );
};

export default Modal;
