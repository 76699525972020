import styled from 'styled-components';

export const StyledDraggable = styled.div`
  position: fixed;
  z-index: 4;
  user-select: none;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;
