import styled, { css } from 'styled-components';

import { IVideoItem } from './types';
import { MARGIN_SIZE, ROOM_BUTTONS_HEIGHT } from 'pages/Room/styled';
import { NameContainer } from '../RTCVideoUserInfo/styled';

const getVideoSizes = (count: number, pinned: boolean) => {
  if (pinned) {
    return css`
      flex: 1 0 200px;
      width: 200px;
      height: 200px;
    `;
  }

  switch (count) {
    case 0:
    case 1:
      return css`
        flex: 1 0 100%;
        max-width: 100%;
        height: 100%;
      `;
    case 2:
    case 3:
      return css`
        --video-item-height: calc(50% - var(--item-distance) / 2);
        --video-item-width: calc(50% - var(--item-distance));
        flex: 1 0 var(--video-item-width);
        max-width: var(--video-item-width);
        height: var(--video-item-height);
      `;

    case 5:
    case 4:
      return css`
        --video-item-height: calc(50% - var(--item-distance) / 2);
        --video-item-width: calc(33% - var(--item-distance));
        flex: 1 0 var(--video-item-width);
        max-width: var(--video-item-width);
        height: var(--video-item-height);
      `;

    default: {
      const height = `${100 / Math.ceil(count / 4)}%`;

      if (!(count % 3)) {
        return css`
          flex: 1 0 ${100 / 3}%;
          max-width: ${100 / 3}%;
          height: ${height};
        `;
      }

      return css`
        flex: 1 0 25%;
        max-width: 25%;
        height: ${height};
      `;
    }
  }
};

const getMobileVideoSizes = (count: number) => {
  switch (count) {
    case 0:
    case 1:
      return css`
        max-width: 100%;
        width: 100%;
        height: 100%;
      `;
    case 2:
      return css`
        height: calc(33% - var(--item-distance));
        flex: 1 0 100%;
        width: 100%;
        min-width: 100%;
      `;
    default:
      return css`
        max-width: 50%;
        width: 50%;
        height: calc(50% - var(--item-distance));
        display: inline-block;
      `;
  }
};

export const PinButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: 0.4s all;
  svg {
    width: 15px;
    height: 15px;
  }
  ${({ active }) =>
    css`
      background-color: ${active ? 'var(--white)' : 'var(--gray-3)'};
      svg {
        color: ${active ? 'var(--gray-3)' : 'var(--white)'};
      }
    `}
`;

export const LoadingContainer = styled.div<{ transparent?: boolean }>`
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ transparent }) =>
    transparent ? '' : 'background-color: var(--video-background-color)'};
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 16px;
    p {
      padding-top: 16px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
  }
`;

export const StyledVideo = styled.div<IVideoItem>`
  max-height: 100%;
  min-height: 300px;
  position: relative;
  transition: all 0.4s;
  border-radius: 16px;
  overflow: hidden;
  background-color: var(--black);
  &:hover ${PinButton} {
    opacity: 1;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.4s;
    background-color: var(--video-background-color);
  }
  ${({
    self,
    length,
    pinned,
    hasPinned,
    index,
    isSpeaking,
    isRotated = true
  }) => css`
    border: 6px solid ${isSpeaking ? 'var(--blue-4)' : 'transparent'};
    ${getVideoSizes(length, hasPinned)}
    @media only screen and (max-width: 768px) {
      border: 0;
      min-height: auto;
      ${getMobileVideoSizes(length)}
    }
    ${self &&
    isRotated &&
    css`
      video {
        transform: scaleX(-1);
      }
    `}
    ${self &&
    length === 1 &&
    css`
      position: fixed;
      right: 32px;
      bottom: 104px;
      z-index: 6;
      min-height: 100px;
      flex: 1 0 200px;
      width: 200px;
      height: 200px;
      border: 0;
      ${NameContainer} {
        width: 100%;
        max-width: 100%;
      }
      ${ImagePlaceholder},
      video {
        background-color: var(--video-background-color-light);
      }
      @media only screen and (max-width: 768px) {
        bottom: ${ROOM_BUTTONS_HEIGHT + MARGIN_SIZE}px;
        width: 130px;
        height: 130px;
        overflow: hidden;
      }
    `}
    ${!self &&
    length === 1 &&
    css`
      border: 0;
    `}
  ${pinned &&
    css`
      position: fixed;
      top: 32px;
      left: 32px;
      height: 100%;
      width: calc(100vw - 286px);
      max-width: calc(100vw - 286px);
      height: calc(100vh - 136px);
    `}
  ${!pinned &&
    hasPinned &&
    css`
      position: absolute;
      right: 0;
      top: ${(index + 1) * 216}px;
      min-height: 200px;
      width: 200px;
    `}
  `}
`;

export const ImagePlaceholder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--video-background-color);
  border-radius: 16px;
`;

export const UserAvatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

export const AvatarPlaceholder = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  object-position: center;
`;
