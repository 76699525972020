import styled from 'styled-components';

export const StyledButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
  border: none;
  background-color: rgba(11, 26, 48, 0.8);
  margin: 0 8px;
  color: var(--action-button-color);
  svg {
    margin-bottom: 4px;
  }
`;
