export enum LogEvents {
  event = 'event',
  error = 'error'
}

export const APP_EVENTS = {
  socket_connected: 'socket_connected',
  page_opened: 'page_opened',
  get_devices: 'get_devices',
  join_send: 'join_send',
  ontrack: 'ontrack',
  offer_sent: 'offer_sent',
  remove_peer: 'remove_peer',
  answer_sent: 'answer_sent',
  end_call: 'end_call',
  leave: 'leave',
  stop_tracks: 'stop_tracks',
  join: 'join',
  share_screen: 'share_screen',
  stop_share_screen: 'stop_share_screen',
  microphone_toggle: 'microphone_toggle',
  camera_toggle: 'camera_toggle',
  microphone_change: 'microphone_change',
  camera_change: 'camera_change',
  wrong_password: 'wrong_password'
};

export const APP_ERRORS = {
  no_devices_found: 'no_devices_found',
  connection_error: 'connection_error',
  on_new_peer: 'on_new_peer',
  on_session_description: 'on_session_description',
  on_share_screen_session_description: 'on_share_screen_session_description',
  on_ice_candidate: 'on_ice_candidate',
  get_ice_servers: 'get_ice_servers',
  get_user_media: 'get_user_media',
  connect_channels: 'connect_channels',
  send_to_channels: 'send_to_channels',
  w_connection_failed: 'w_connection_failed'
};
