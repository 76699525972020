import { NetworkStatuses } from 'store/network/types';
import styled, { css, keyframes } from 'styled-components';

import { IStyledNetworkStatus } from './types';

const upToDown = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`;

export const StyledNetworkStatus = styled.div<IStyledNetworkStatus>`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  animation: ${upToDown} 0.5s;
  display: flex;
  align-items: center;
  p {
    font-weight: 500;
    text-align: center;
    margin: 0 8px;
  }
  ${({ status }) => {
    switch (status) {
      case NetworkStatuses.online:
        return css`
          background-color: var(--success);
          justify-content: center;
          p {
            color: var(--white);
          }
        `;
      case NetworkStatuses.offline:
        return css`
          background-color: var(--danger);
          justify-content: center;
          p {
            color: var(--white);
          }
        `;
      case NetworkStatuses.slowConnection:
      case NetworkStatuses.verySlowConnection:
        return css`
          background-color: var(--warning);
          justify-content: space-between;
          p {
            color: var(--white);
          }
        `;
    }
  }}
`;

export const StyledClose = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: 0;
  svg {
    width: 18px;
    height: 18px;
    fill: #ffffff;
  }
`;
