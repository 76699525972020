import { AxiosResponse } from 'axios';
import config from 'config';
import instance from 'services/api';
import { io, Socket as SocketIo } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { USER_ID } from 'constants/global';

import { SOCKET_OPTIONS } from './cosntants';
import { RoomInfo } from './types';

interface Socket {
  socket?: SocketIo<DefaultEventsMap, DefaultEventsMap>;
  roomID?: string;
}

class Socket {
  constructor() {
    this.socket;
    this.roomID;
  }

  get io() {
    if (!this.socket) {
      throw new Error('Socket is not connected');
    }

    return this.socket;
  }

  get id() {
    if (!this.socket) {
      throw new Error('Socket is not connected');
    }

    return this.socket.id;
  }

  connect = (roomID: string, token?: string | null) => {
    if (!config.socket_url) {
      throw new Error('Please pass socket url');
    }

    console.log('CONNECT USER', USER_ID);

    this.roomID = roomID;
    this.socket = io(config.socket_url, {
      ...SOCKET_OPTIONS,
      auth: token
        ? {
            token: `Bearer ${token}`
          }
        : undefined,
      query: {
        roomID,
        userID: USER_ID
      }
    });
  };

  sendToPeer = (
    action: string,
    payload?: unknown,
    cb?: (data: unknown) => void
  ) => {
    if (!this.socket) {
      throw new Error('Socket is not connected');
    }

    this.socket.emit(
      action,
      {
        peerID: USER_ID,
        payload
      },
      cb
    );
  };

  getRoomInfo = async (): Promise<RoomInfo[]> => {
    try {
      const res: AxiosResponse = await instance.get(
        `/room/info/${this.roomID}`
      );

      return res.data.data;
    } catch (err) {
      console.warn('ERROR | getRoomInfo | ', err);

      return [];
    }
  };
}

export default new Socket();
