import config from 'config';

import placeholder from '../assets/img/user.svg';

export const getFileUrl = (fileKey?: string | null) => {
  if (!fileKey) {
    return placeholder;
  }

  return `${config.image_url}/aws-s3/file/${fileKey}`;
};

export const downloadFileFromUrl = (url: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.rel = 'noreferrer';
  a.download = fileName || 'download';

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
};
