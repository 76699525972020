import {
  APPOINTMENT_CREATOR,
  AppointmentCreator
} from 'constants/appointments';
import { GENDERS_VALUES, USER_TYPES } from 'constants/users';
import { RootState } from 'store';
import i18next from 'i18next';
import { createSelector } from '@reduxjs/toolkit';
import { selectUserData } from 'store/user/selectors';

import { getUserAge } from 'utils/helpers';
import { UsersItemType } from './types';

export const selectAppointments = (state: RootState) => state.appointments;

export const selectAppointmentsLoading = createSelector(
  [selectAppointments],
  appointments => appointments.loading
);

export const selectAppointmentData = createSelector(
  [selectAppointments],
  appointments => appointments.appointment
);

export const selectUserType = createSelector(
  [selectUserData, selectAppointmentData],
  (user, appointment) => {
    switch (user?.id) {
      case appointment?.patient?.id:
        return 'patient';
      case appointment?.doctor?.id:
        return 'doctor';
      case Number(appointment?.appointment_translators?.[0].translator.id):
        return 'translator';
      default:
        return '';
    }
  }
);

export const selectAppointmentExists = createSelector(
  [selectAppointments],
  appointments => !!appointments.appointment?.isAppointment
);

export const selectAppointmentsError = createSelector(
  [selectAppointments],
  appointments => appointments.error
);

export const selectAppointmentIsOpened = createSelector(
  [selectAppointments],
  appointments => appointments.opened
);

export const selectAppointmentNotes = (isMine: boolean, sender: string) =>
  createSelector(
    [selectUserType, selectAppointmentData],
    (user, appointment) => {
      if (!appointment || !user) return [];

      if (user === USER_TYPES.translator) {
        return appointment.notes.filter(
          item =>
            item.sender === APPOINTMENT_CREATOR[sender as AppointmentCreator]
        );
      }

      return appointment.notes.filter(item =>
        isMine
          ? item.sender === APPOINTMENT_CREATOR[user]
          : item.sender !== APPOINTMENT_CREATOR[user]
      );
    }
  );

export const selectAppointmentFiles = createSelector(
  [selectAppointmentData],
  appointment => appointment?.files || []
);

export const selectAppointmentDoctor = createSelector(
  [selectUserType, selectAppointmentData],
  (userType, appointment) => {
    if (userType === USER_TYPES.doctor) {
      return null;
    }

    return appointment?.doctor;
  }
);

export const selectAppointmentPatient = createSelector(
  [selectUserType, selectAppointmentData],
  (userType, appointment) => {
    if (userType === USER_TYPES.patient) {
      return null;
    }

    return appointment?.patient;
  }
);

export const selectAppointmentTranslator = createSelector(
  [selectUserType, selectAppointmentData],
  (userType, appointment) => {
    if (userType === USER_TYPES.translator) {
      return null;
    }

    return appointment?.appointment_translators?.[0]?.translator;
  }
);

export const selectAppointmentUsers = createSelector(
  [
    selectAppointmentDoctor,
    selectAppointmentPatient,
    selectAppointmentTranslator,
    selectUserType
  ],
  (doctor, patient, translator, userType) => {
    const age = getUserAge(patient?.date_of_birth);
    const ageStr = age ? `${i18next.t('years', { age })}, ` : '';
    const patientStr = patient?.gender
      ? GENDERS_VALUES[patient.gender as keyof typeof GENDERS_VALUES]
      : '';

    const patientData: UsersItemType = patient
      ? {
          ...patient,
          extra: [
            `${ageStr}${i18next.t(patientStr)}`,
            patient?.languages?.map(item => item.label).join(' | ') || '',
            patient?.address || ''
          ]
        }
      : null;

    const translatorData: UsersItemType = translator
      ? {
          ...translator,
          extra: [
            'translator',
            translator?.languages?.map(item => item.label).join(' | ') || ''
          ]
        }
      : null;

    const doctorData: UsersItemType = doctor
      ? {
          ...doctor,
          extra: [
            doctor?.languages?.map(item => item.label).join(' | ') || '',
            doctor?.address || ''
          ]
        }
      : null;

    switch (userType) {
      case USER_TYPES.doctor:
        return [patientData, translatorData];
      case USER_TYPES.patient:
        return [doctorData, translatorData];
      case USER_TYPES.translator:
        return [doctorData, patientData];
      default:
        return [];
    }
  }
);
