import { createRoot } from 'react-dom/client';

import { CheckBrowser } from 'components/shared';
import App from './App';

import './index.css';
import 'locales';

const container = document.getElementById('root');

const root = createRoot(container as HTMLElement);

root.render(
  <CheckBrowser>
    <App />
  </CheckBrowser>
);
