import styled from 'styled-components';

export const AddNoteContent = styled.div`
  label {
    font-size: 16px;
    color: var(--gray-4);
    display: block;
    margin-bottom: 8px;
  }
  textarea {
    border: 1px solid #e4eeff;
    border-radius: 8px;
    padding: 8px;
    height: 200px;
    width: 100%;
    font-size: 16px;
    resize: none;
  }
`;

export const AddNoteFooter = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: flex-end;
  button {
    background-color: var(--blue-4);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 24px;
    height: 33px;
    font-size: 14px;
    text-align: center;
    color: var(--white);
    border: none;
    min-width: 60px;
    padding: 0 16px;
    cursor: pointer;
  }
`;
