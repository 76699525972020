import styled from 'styled-components';

export const ROOM_BUTTONS_HEIGHT = 56;
export const MARGIN_SIZE = 32;

const { clientHeight } = document.documentElement;

export const StyledVideoContainer = styled.div`
  --margin-size: 32px;
  --item-distance: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: var(--margin-size) var(--margin-size) 0;
  height: calc(100vh - 104px - var(--margin-size));
  overflow-y: auto;
  position: relative;
  gap: var(--item-distance);
  @media only screen and (max-width: 768px) {
    min-height: auto;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: ${clientHeight - (ROOM_BUTTONS_HEIGHT + MARGIN_SIZE * 2)}px;
    overflow-y: auto;
  }
`;
