import styled, { keyframes } from 'styled-components';

export const RECORD_TIME = 10000;

const lineAnimation = keyframes`
 0% { width: 0% }
 100% { width: 100% }
`;

export const StyledStreamRecord = styled.div`
  color: var(--dark);
  text-align: center;
  video {
    min-width: 270px;
    max-width: 100%;
    transform: scaleX(-1);
    object-fit: contain;
    transition: all 0.4s;
    height: 330px;
    border-radius: 16px;
    background-color: var(--gray-4);
    transform: scaleX(-1);
  }
  .recorded-video {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    position: relative;
    .recorded-video-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      .play-button {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: var(--primary-new);
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
          fill: var(--white);
        }
      }
    }
  }
  .record-content {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .recording-loading {
    padding: 16px 0 32px;
    .recording-loading-line {
      width: 150px;
      height: 6px;
      border-radius: 8px;
      background-color: var(--gray-20);
      margin: 16px auto;
      position: relative;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        height: 100%;
        background-color: var(--primary-new);
        width: 100%;
        animation: ${lineAnimation} ${RECORD_TIME / 1000}s linear;
      }
    }
    h6 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
    }
  }
`;
