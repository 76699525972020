import {
  FC,
  Dispatch,
  ReactNode,
  createContext,
  SetStateAction,
  useState
} from 'react';

interface IProps {
  children: ReactNode;
}

export const StreamStateContext = createContext<MediaStream | null>(null);
export const StreamDispatchContext = createContext<Dispatch<
  SetStateAction<MediaStream | null>
> | null>(null);

const StreamProvider: FC<IProps> = ({ children }) => {
  const [stream, setStream] = useState<MediaStream | null>(null);

  return (
    <StreamStateContext.Provider value={stream}>
      <StreamDispatchContext.Provider value={setStream}>
        {children}
      </StreamDispatchContext.Provider>
    </StreamStateContext.Provider>
  );
};

export default StreamProvider;
