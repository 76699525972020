import styled, { css } from 'styled-components';

import { ROOM_BUTTONS_HEIGHT } from 'pages/Room/styled';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

export const ButtonsContainer = styled.div`
  background-color: var(--blue-6);
  border-radius: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${ROOM_BUTTONS_HEIGHT}px;
  padding: 0 24px;
  .room-button {
    margin: 0 12px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  button {
    position: relative;
    background-color: transparent;
    padding: 8px 0;
    color: var(--white);
    border: 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 11px;
    svg {
      height: 24px;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    border-radius: 0;
    padding: 8px;
    transform: translateX(0);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
`;

export const StyledRightSide = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 200px;
  background-color: var(--gray-3);
`;

export const Badge = styled.div<{ show: boolean }>`
  display: inline-block;
  position: relative;
  ${({ show }) =>
    show &&
    css`
      &:after {
        content: '';
        background-color: var(--danger);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: 0;
      }
    `}
`;
