import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { PeerID } from 'webrtc/types';

import {
  ChangeDisconnectionStatus,
  IWebRTCState,
  SetRemoteStream,
  SetShareApprovalStatus,
  ShareApprovalsEnum,
  ToggleRemoteStreamMedia,
  ToggleSpeaking
} from './types';
import {
  selectRemoteStreams,
  selectRemoteStreamsById,
  selectShareApprovalStatuses
} from './selectors';
import { AppPlatforms, StreamTypesEnum } from 'hooks/useWebRTC/types';

const initialState: IWebRTCState = {
  joined: false,
  videoEnabled: true,
  audioEnabled: true,
  isSettingsOpen: false,
  speaking: {},
  isSharingScreen: false,
  sharingScreenExists: false,
  isApprovingInProgress: false,
  isApprovingRequested: false,
  shareApprovals: {},
  remoteStreamsById: {},
  remoteStreams: []
};

export const resetShareApprovalStatuses = createAsyncThunk(
  'webrtc/resetShareApprovalStatuses',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const statuses = selectShareApprovalStatuses(state);

    return Object.keys(statuses);
  }
);

export const resetShareApprovalRejectedStatuses = createAsyncThunk(
  'webrtc/resetShareApprovalRejectedStatuses',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const statuses = selectShareApprovalStatuses(state);

    const rejectedIds = Object.keys(statuses).filter(
      id => statuses[id] === ShareApprovalsEnum.rejected
    );

    return rejectedIds;
  }
);

export const deleteRemoteStreams = createAsyncThunk(
  'webrtc/deleteRemoteStreams',
  async (peerID: string, { getState }) => {
    const state = getState() as RootState;
    const shareApprovals = selectShareApprovalStatuses(state);
    const remoteStreamsById = selectRemoteStreamsById(state);
    const remoteStreams = selectRemoteStreams(state);

    const newShareApprovals = { ...shareApprovals };
    const newRemoteStreamsById = { ...remoteStreamsById };

    const shareScreenId = `${StreamTypesEnum.screen}-${peerID}`;

    const shareScreenRemoved = remoteStreams.includes(shareScreenId);

    const newRemoteStreams = remoteStreams.filter(
      id => id !== peerID && id !== shareScreenId
    );

    delete newShareApprovals[peerID];
    delete newRemoteStreamsById[peerID];

    return {
      shareApprovals: newShareApprovals,
      remoteStreamsById: newRemoteStreamsById,
      remoteStreams: newRemoteStreams,
      shareScreenRemoved
    };
  }
);

// Then, handle actions in your reducers:
const webrtcSlice = createSlice({
  name: 'webrtc',
  initialState,
  reducers: {
    join: state => {
      state.joined = true;
    },
    setVideoEnabled: (state, action: PayloadAction<boolean>) => {
      state.videoEnabled = action.payload;
    },
    setAudioEnabled: (state, action: PayloadAction<boolean>) => {
      state.audioEnabled = action.payload;
    },
    toggleSettings: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action.payload === 'undefined') {
        state.isSettingsOpen = !state.isSettingsOpen;
      } else {
        state.isSettingsOpen = action.payload;
      }
    },
    toggleSpeaking: (state, action: PayloadAction<ToggleSpeaking>) => {
      const { peerID, value } = action.payload;

      state.speaking[peerID] = value;
    },
    toggleShareScreen: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action.payload === 'undefined') {
        state.isSharingScreen = !state.isSharingScreen;
      } else {
        state.isSharingScreen = action.payload;
      }
    },
    toggleShareScreenExists: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      if (typeof action.payload === 'undefined') {
        state.sharingScreenExists = !state.sharingScreenExists;
      } else {
        state.sharingScreenExists = action.payload;
      }
    },
    setRemoteStreams: (state, action: PayloadAction<SetRemoteStream>) => {
      const { id, data } = action.payload;

      state.shareApprovals[id] = ShareApprovalsEnum.pending;
      state.remoteStreamsById[id] = {
        ...(state.remoteStreamsById[id] || {}),
        disconnected: false,
        ...data
      };

      state.remoteStreams = Array.from(new Set([...state.remoteStreams, id]));
    },
    switchRemoteStreamVideo: (
      state,
      action: PayloadAction<ToggleRemoteStreamMedia>
    ) => {
      const { id, value, from } = action.payload;

      state.remoteStreamsById[id].videoEnabled = value;

      if (from !== AppPlatforms.mobile) {
        state.remoteStreamsById[id].isLoading = value;
      }
    },
    switchRemoteStreamAudio: (
      state,
      action: PayloadAction<ToggleRemoteStreamMedia>
    ) => {
      const { id, value } = action.payload;

      state.remoteStreamsById[id].audioEnabled = value;
    },
    setStreamLoading: (
      state,
      action: PayloadAction<ToggleRemoteStreamMedia>
    ) => {
      const { id, value } = action.payload;

      state.remoteStreamsById[id].isLoading = value;
    },
    setShareApprovalStatus: (
      state,
      action: PayloadAction<SetShareApprovalStatus>
    ) => {
      const { id, value } = action.payload;

      state.shareApprovals[id] = value
        ? ShareApprovalsEnum.approved
        : ShareApprovalsEnum.rejected;
    },
    setIsApprovingRequested: (state, action: PayloadAction<boolean>) => {
      state.isApprovingRequested = action.payload;
    },
    setIsApprovingInProgress: (state, action: PayloadAction<boolean>) => {
      state.isApprovingInProgress = action.payload;
    },
    changeDisconnectionStatus: (
      state,
      action: PayloadAction<ChangeDisconnectionStatus>
    ) => {
      const { peerID, value = true } = action.payload;

      if (state.remoteStreamsById[peerID]) {
        state.remoteStreamsById[peerID].disconnected = value;
      }
    },
    deleteRemoteStreamMedia: (state, action: PayloadAction<PeerID>) => {
      if (state.remoteStreamsById[action.payload]) {
        state.remoteStreamsById[action.payload].stream = null;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(
      resetShareApprovalRejectedStatuses.fulfilled,
      (state, action) => {
        action.payload.forEach(id => {
          state.shareApprovals[id] = ShareApprovalsEnum.pending;
        });
      }
    );
    builder.addCase(resetShareApprovalStatuses.fulfilled, (state, action) => {
      action.payload.forEach(id => {
        state.shareApprovals[id] = ShareApprovalsEnum.pending;
      });
    });
    builder.addCase(deleteRemoteStreams.fulfilled, (state, action) => {
      state.remoteStreams = action.payload.remoteStreams;
      state.shareApprovals = action.payload.shareApprovals;
      state.remoteStreamsById = action.payload.remoteStreamsById;

      if (action.payload.shareScreenRemoved) {
        state.sharingScreenExists = false;
      }
    });
  }
});

export const {
  join,
  setVideoEnabled,
  setAudioEnabled,
  toggleSettings,
  toggleSpeaking,
  toggleShareScreen,
  toggleShareScreenExists,
  setRemoteStreams,
  switchRemoteStreamVideo,
  switchRemoteStreamAudio,
  setStreamLoading,
  setShareApprovalStatus,
  setIsApprovingRequested,
  setIsApprovingInProgress,
  changeDisconnectionStatus,
  deleteRemoteStreamMedia
} = webrtcSlice.actions;

export default webrtcSlice.reducer;
