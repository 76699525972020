import { store } from 'store';
import instance from 'services/api';
import socket from 'webrtc/socket';
import { USER_ID } from 'constants/global';

import { LogEvents } from './constants';

class Logger {
  get url() {
    return '/logs';
  }

  get roomID() {
    const pathname = window.location.pathname;
    const splittedPath = pathname.split('/');

    return splittedPath[splittedPath.length - 1];
  }

  get user() {
    const state = store.getState();

    return state.user.user;
  }

  event(name: string, data?: object) {
    const json = JSON.stringify({
      ...(data || {}),
      userId: USER_ID,
      connection: navigator.connection?.effectiveType || 'unknown'
    });

    instance.post(this.url + '/collect', {
      data: {
        type: LogEvents.event,
        roomID: this.roomID,
        peerID: socket.socket?.id,
        name,
        options: json
      }
    });
  }

  error(name: string, data?: object) {
    const json = JSON.stringify({
      ...(data || {}),
      userId: USER_ID,
      connection: navigator.connection?.effectiveType || 'unknown'
    });

    instance.post(this.url + '/error', {
      data: {
        type: LogEvents.error,
        roomID: this.roomID,
        peerID: socket.id,
        name,
        options: json
      }
    });
  }
}

export const logger = new Logger();
