import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from 'services/api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SOCKET_PAGES } from 'constants/global';

import { IUserState } from './types';

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async () => {
    const response = await instance.get('/auth/me');

    return response.data.data;
  }
);

const paths = window.location.pathname.split('/');
const path = paths[1];
const appointmentId = paths[paths.length - 1];

const initialState: IUserState = {
  user: null,
  loading: true,
  error: false,
  isLoggedIn: false,
  roomID: SOCKET_PAGES.includes(path) ? appointmentId || '' : '',
  password: ''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      if (action.payload.length < 30) {
        state.user = { ...(state.user || {}), first_name: action.payload };
      }
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setRoomId: (state, action: PayloadAction<string>) => {
      state.roomID = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserData.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.isLoggedIn = true;
    });
    builder.addCase(fetchUserData.rejected, state => {
      localStorage.removeItem('token');
      state.error = true;
      state.loading = false;
      state.isLoggedIn = false;
    });
  }
});

export const { setUserName, setPassword, setLoading, setRoomId } =
  userSlice.actions;
export default userSlice.reducer;
