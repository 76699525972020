import { GetUserMedia } from './types';

export const getVideoConstraints = (isEnabled = true, id?: string) => {
  if (isEnabled) {
    if (id) {
      return {
        deviceId: {
          exact: id
        }
      };
    }

    return true;
  }

  return false;
};

export const getAudioConstraints = (id?: string) => {
  if (id) {
    return {
      deviceId: {
        exact: id
      }
    };
  }

  return true;
};

export const getUserMedia = async ({
  isVideoEnabled = true,
  isAudioEnabled = true,
  videoDeviceId,
  audioDeviceId
}: GetUserMedia) => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: getAudioConstraints(audioDeviceId),
    video: getVideoConstraints(isVideoEnabled, videoDeviceId)
  });

  if (!isAudioEnabled) {
    const tracks = stream.getAudioTracks();

    tracks.forEach(track => (track.enabled = false));
  }

  return stream;
};
