import styled, { keyframes } from 'styled-components';

const modalKeyframes = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`;

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9997;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  opacity: 1;
  animation: ${modalKeyframes} 0.3s ease-out;
`;

export const ModalContainer = styled.div<{ width: number }>`
  background-color: var(--white);
  border-radius: 16px;
  max-width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ width }) => `width: ${width}px`}
`;

export const ModalContent = styled.div`
  padding: 16px 24px;
  > h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--blue-6);
    padding-bottom: 16px;
    line-height: 1;
  }
`;

export const ModalHeader = styled.div`
  height: 38px;
  display: flex;
  padding-top: 10px;
  justify-content: flex-end;
  padding: 0 12px;
`;

export const StyledClose = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: 0;
`;

export const StyledFooter = styled.div`
  padding: 24px;
  border-top: 1px solid var(--gray-20);
  display: flex;
  justify-content: flex-end;
  margin: 0 -6px;
  button {
    margin: 0 6px;
    font-size: 16px;
    cursor: pointer;
    height: 34px;
    min-width: 115px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    outline: 0;
    transition: opacity 0.2s;
    &:active {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &.cancel {
      border: 1px solid var(--gray-10);
      background-color: var(--neutral-20);
      color: var(--text-color);
    }
    &.ok {
      border: none;
      background-color: var(--blue-4);
      color: var(--white);
    }
  }
`;
