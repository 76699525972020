import {
  FC,
  FormEventHandler,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectChatIsOpened,
  selectChatLastUnreadMessage,
  selectChatMessages
} from 'store/chat/selectors';
import { selectUserName } from 'store/user/selectors';
import { readAllMessages, sendMessage, toggleChat } from 'store/chat/slice';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { shallowEqual } from 'react-redux';
import { Send } from 'icons';
import { USER_ID } from 'constants/global';

import { MessageItem, StyledInputContainer } from './styled';
import { Sidebar } from 'components/shared';
import { SidebarRef } from 'components/shared/Sidebar/types';
import { Button } from 'components/ui';

const Chat: FC = () => {
  // translations
  const { t } = useTranslation();
  // refs
  const sidebarRef = useRef<SidebarRef>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  // redux
  const dispatch = useAppDispatch();
  const isOpened = useAppSelector(selectChatIsOpened);
  const messages = useAppSelector(selectChatMessages, shallowEqual);
  const lastUnreadMessages = useAppSelector(
    selectChatLastUnreadMessage,
    shallowEqual
  );

  const userName = useAppSelector(selectUserName());
  // states
  const [text, setText] = useState('');

  useEffect(() => {
    if (lastUnreadMessages) {
      toast(
        <div>
          <h6>{lastUnreadMessages.sender}</h6>
          <p>{lastUnreadMessages.text}</p>
        </div>,
        {
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          onClick: () => dispatch(toggleChat(true))
        }
      );
    }
  }, [dispatch, lastUnreadMessages]);

  const scrollToBottom = useCallback(() => {
    sidebarRef.current?.scrollToBottom();
  }, []);

  const onSendMessage: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      if (!text) return;

      const messageData = {
        text,
        date: dayjs().format(),
        sender: userName,
        senderId: USER_ID
      };

      dispatch(sendMessage(messageData));
      setText('');
      inputRef.current?.focus();
    },
    [dispatch, text, userName]
  );

  useEffect(() => {
    if (isOpened) {
      scrollToBottom();
      dispatch(readAllMessages());
    }
  }, [dispatch, scrollToBottom, isOpened, messages.length]);

  const onClose = () => {
    setText('');
    dispatch(toggleChat(false));
  };

  return (
    <Sidebar
      title={t('chat')}
      isOpen={isOpened}
      footer={
        <form onSubmit={onSendMessage}>
          <StyledInputContainer>
            <div className="input-wrapper">
              <input
                value={text}
                onChange={e => setText(e.target.value)}
                placeholder={t('type_message') || ''}
                ref={inputRef}
              />
              <Button icon={<Send />} />
            </div>
          </StyledInputContainer>
        </form>
      }
      onClose={onClose}
      ref={sidebarRef}
    >
      {messages.map((item, index) => (
        <MessageItem key={`message-item-${index}`}>
          <p className="name">
            {item.senderId === USER_ID ? t('you') : item.sender}{' '}
            <span className="date">{dayjs(item.date).format('HH:mm')}</span>
          </p>
          <p className="text">{item.text}</p>
        </MessageItem>
      ))}
    </Sidebar>
  );
};

export default memo(Chat);
