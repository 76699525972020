import styled from 'styled-components';

export const StyledPermission = styled.div`
  display: flex;
  gap: 51px;
  padding: 16px 32px;
  color: var(--dark);
  svg {
    width: 228px;
    height: 220px;
  }
  h6 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 24px;
  }
  ol li {
    font-size: 16px;
  }
  p {
    padding-top: 16px;
    font-size: 12px;
  }
  @media only screen and (max-width: 768px) {
    .permission-image {
      display: none;
    }
  }
`;
