const config = {
  production: {
    socket_url: process.env.REACT_APP_SOCKET_URL,
    base_url: process.env.REACT_APP_MEETINGS_URL,
    website_url: process.env.REACT_APP_PATIENT_URL,
    image_url: process.env.REACT_APP_GATEWAY_URL,
    doctor_url: process.env.REACT_APP_DOCTOR_URL,
    translator_url: process.env.REACT_APP_TRANSLATOR_URL,
    env: process.env.NODE_ENV
  },
  development: {
    // LOCAL
    socket_url: 'http://localhost:6002',
    base_url: 'http://localhost:6001',
    website_url: 'http://localhost:3004',
    // STAGING
    // socket_url: 'wss://stg-wss.medicusunion.me',
    // base_url: 'https://stg-api-meeting.medicusunion.me',
    // website_url: 'https://stg.medicusunion.me',
    image_url: 'https://stg-gateway.medicusunion.me',
    doctor_url: 'https://stg-doctor.medicusunion.me',
    translator_url: 'https://stg-translator.medicusunion.me',
    env: process.env.NODE_ENV
  },
  test: {
    socket_url: process.env.REACT_APP_SOCKET_URL,
    base_url: process.env.REACT_APP_MEETINGS_URL,
    website_url: process.env.REACT_APP_PATIENT_URL,
    image_url: process.env.REACT_APP_GATEWAY_URL,
    doctor_url: process.env.REACT_APP_DOCTOR_URL,
    translator_url: process.env.REACT_APP_TRANSLATOR_URL,
    env: process.env.NODE_ENV
  }
};

export default config[process.env.NODE_ENV];
