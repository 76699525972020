import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Warning } from 'icons';
import { useSearchParams } from 'react-router-dom';

import { StyledError } from './styled';
import { Button } from 'components/ui';

const Duplicate: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const roomID = searchParams.get('roomID');

  const onClickStay = () => {
    window.location.href = `${window.location.origin}/home/${roomID}`;
  };

  return (
    <StyledError>
      <div className="container">
        <Warning width={120} height={120} />
        <p>{t('duplicate_tab')}</p>
        {!!roomID && (
          <Button title={t('stay_in_this_tab')} onClick={onClickStay} />
        )}
      </div>
    </StyledError>
  );
};

export default Duplicate;
