import { configureStore } from '@reduxjs/toolkit';

// reducers
import userReducer from './user/slice';
import appointmentsReducer from './appointments/slice';
import webrtcReducer from './webrtc/slice';
import devicesReducer from './devices/slice';
import chatReducer from './chat/slice';
import networkReducer from './network/slice';
import uiReducer from './ui/slice';
import captionsReducer from './captions/slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    appointments: appointmentsReducer,
    webrtc: webrtcReducer,
    devices: devicesReducer,
    chat: chatReducer,
    network: networkReducer,
    ui: uiReducer,
    captions: captionsReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
