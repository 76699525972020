import { RootState } from 'store';

import { ModalKeys, IUIState } from './types';

export const selectUIModal =
  <T = IUIState['modals'][ModalKeys]>(name: ModalKeys) =>
  (state: RootState) =>
    state.ui.modals[name] as unknown as T;

export const selectUIModalVisible = (name: ModalKeys) => (state: RootState) => {
  const modal = selectUIModal(name)(state);

  return modal.visible;
};

export const selectUIModalParams = (name: ModalKeys) => (state: RootState) => {
  const modal = selectUIModal(name)(state);

  return modal.params;
};
