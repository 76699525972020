import { FC, memo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectShareApprovalUsers } from 'store/webrtc/selectors';
import { shallowEqual } from 'react-redux';

import { StyledUsers } from './styled';
import UserItem from '../UserItem';

const Users: FC = () => {
  const users = useAppSelector(selectShareApprovalUsers, shallowEqual);

  return (
    <StyledUsers>
      {users.map(id => (
        <UserItem key={id} id={id} />
      ))}
    </StyledUsers>
  );
};

export default memo(Users);
