/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectSelfAudioEnabled,
  selectWebrtcJoined
} from 'store/webrtc/selectors';
import webrtc from 'webrtc';
import { store } from 'store';
import { addCaption } from 'store/captions/slice';
import { USER_ID } from 'constants/global';

import { MESSAGE_TYPES } from './useWebRTC/types';

const useCaptions = () => {
  const joined = useAppSelector(selectWebrtcJoined);
  const audioEnabled = useAppSelector(selectSelfAudioEnabled);

  useEffect(() => {
    let recognition: any = null;

    if (joined && audioEnabled) {
      const SpeechRecognition =
        (window as any).SpeechRecognition ||
        (window as any).webkitSpeechRecognition;

      recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = 'ru'; // Set your desired language

      recognition.onresult = (event: any) => {
        console.log('event', event.results);
        const transcript = Array.from(event.results)
          .map((result: any) => result[0])
          .map(result => result.transcript)
          .join('');

        console.log('transcript', transcript);

        if (transcript.trim()) {
          webrtc.sendToChannels({
            type: MESSAGE_TYPES.speech_text,
            data: transcript
          });

          store.dispatch(addCaption({ data: transcript, peerID: USER_ID }));
        }
      };

      recognition.start();

      recognition.onend = () => {
        console.log('recognition.onend');
        // recognition.start();
      };
    }

    return () => {
      recognition?.stop();
    };
  }, [joined, audioEnabled]);

  return;
};

export default useCaptions;
