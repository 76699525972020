import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectCaptions = (state: RootState) => state.captions;

export const selectCaptionsIsVisible = createSelector(
  [selectCaptions],
  captions => captions.visible
);

export const selectCaptionsData = createSelector(
  [selectCaptions],
  captions => captions.shownCaptions
);
