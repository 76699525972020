import { DefaultTFuncReturn } from 'i18next';

interface ModalState<T = unknown> {
  visible: boolean;
  params?: T;
}

export enum ModalKeys {
  confirm = 'confirm',
  shareLink = 'shareLink',
  desktopShareScreen = 'desktopShareScreen',
  checkDevices = 'checkDevices',
  permission = 'permission'
}

type ConfirmParams = {
  title: string;
  description: string;
  okText?: string | DefaultTFuncReturn;
  cancelText?: string | DefaultTFuncReturn;
  showCancel?: boolean;
  showOk?: boolean;
  onOk?(): void;
  onCancel?(): void;
};

type PermissionParams = {
  type: 'video' | 'mic';
};

type ToggleConfirm = {
  name: ModalKeys.confirm;
  value?: boolean;
  params?: ConfirmParams;
};

type ToggleShareLink = {
  name: ModalKeys.shareLink;
  params?: unknown;
  value?: boolean;
};

type ToggleDesktopShareScreen = {
  name: ModalKeys.desktopShareScreen;
  params?: unknown;
  value?: boolean;
};

type ToggleCheckDevices = {
  name: ModalKeys.checkDevices;
  params?: unknown;
  value?: boolean;
};

type TogglePermission = {
  name: ModalKeys.permission;
  params?: PermissionParams;
  value?: boolean;
};

export type ToggleModal =
  | ToggleConfirm
  | ToggleShareLink
  | ToggleDesktopShareScreen
  | ToggleCheckDevices
  | TogglePermission;
export type SetModalParams = Omit<ToggleModal, 'value'>;

export interface IUIState {
  modals: {
    [ModalKeys.confirm]: ModalState<ConfirmParams>;
    [ModalKeys.shareLink]: ModalState;
    [ModalKeys.desktopShareScreen]: ModalState;
    [ModalKeys.checkDevices]: ModalState;
    [ModalKeys.permission]: ModalState<PermissionParams>;
  };
}
