import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import webrtc from 'webrtc';

import { MESSAGE_TYPES } from 'hooks/useWebRTC/types';
import { ChatMessageData, IChatState } from './types';

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async (data: ChatMessageData, { dispatch }) => {
    webrtc.sendToChannels({
      type: MESSAGE_TYPES.chat,
      data
    });

    dispatch(chatSlice.actions.addMessage(data));
  }
);

const initialState: IChatState = {
  opened: false,
  messages: []
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    toggleChat: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action.payload === 'undefined') {
        state.opened = !state.opened;
      } else {
        state.opened = action.payload;
      }
    },
    addMessage: (state, action: PayloadAction<ChatMessageData>) => {
      state.messages.push(action.payload);
    },
    readAllMessages: state => {
      state.messages = state.messages.map(item => ({ ...item, read: true }));
    }
  }
});

export const { toggleChat, addMessage, readAllMessages } = chatSlice.actions;
export default chatSlice.reducer;
