import styled, { css } from 'styled-components';

import { ButtonTypes } from './types';

const buttonStyles = {
  [ButtonTypes.primary]: css`
    color: var(--white);
    background-color: var(--primary-new);
  `
} as const;

export const StyledButton = styled.button<{
  mode: ButtonTypes;
  isIcon?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 46px;
  font-size: 16px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  outline: none;
  ${({ isIcon }) => (isIcon ? 'height: 46px;' : 'padding: 0 28px;')}
  ${({ mode }) => buttonStyles[mode]}
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
