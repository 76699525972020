import { Close } from 'icons';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectNetworkStatus } from 'store/network/selectors';
import { NetworkStatuses } from 'store/network/types';

import { StyledClose, StyledNetworkStatus } from './styled';

const TEXTS = {
  online: 'online',
  offline: 'offline',
  slowConnection: 'slow_connection',
  verySlowConnection: 'very_slow_connection'
} as const;

const ONLINE_DELAY = 2000;

const NetworkStatus = () => {
  // translations
  const { t } = useTranslation();
  // states
  const status = useAppSelector(selectNetworkStatus());
  // states
  const [showOnline, setShowOnline] = useState(false);
  const [closed, setClosed] = useState(false);
  // refs
  const wasOffline = useRef(false);

  useEffect(() => {
    if (status === NetworkStatuses.offline) {
      wasOffline.current = true;
    }
  }, [status]);

  useEffect(() => {
    setClosed(false);
  }, [status]);

  useEffect(() => {
    if (wasOffline.current && status === NetworkStatuses.online) {
      setShowOnline(true);
      wasOffline.current = false;
      setTimeout(() => {
        setShowOnline(false);
      }, ONLINE_DELAY);
    }
  }, [status]);

  const showClose =
    status === NetworkStatuses.slowConnection ||
    status === NetworkStatuses.verySlowConnection;

  if (closed || (status === NetworkStatuses.online && !showOnline)) return null;

  return (
    <StyledNetworkStatus status={status}>
      {showClose && <div />}
      <p>{t(TEXTS[status])}</p>
      {showClose && (
        <StyledClose onClick={() => setClosed(true)}>
          <Close />
        </StyledClose>
      )}
    </StyledNetworkStatus>
  );
};

export default memo(NetworkStatus);
