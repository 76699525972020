export default () => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (!connection) return null;

    let type = connection.effectiveType;

    function updateConnectionStatus() {
        postMessage(JSON.stringify({ from: type, to: connection.effectiveType, online: navigator.onLine }));
        type = connection.effectiveType;
    }

    connection.addEventListener('change', updateConnectionStatus);
}
