import { useCallback, useEffect, useRef, useState } from 'react';

type SetStateCb<T = unknown> = (
  value: T | ((prev: T) => T),
  cb?: () => void
) => void;

const useStateWithCallback = <T = unknown>(
  initialValue: T
): [T, SetStateCb<T>] => {
  // states
  const [state, setState] = useState(initialValue);
  // refs
  const cbRef = useRef<(() => void) | null>(null);

  const setStateCb: SetStateCb<T> = useCallback((value, cb): void => {
    if (cb) {
      cbRef.current = cb;
    }

    setState(value);
  }, []);

  useEffect(() => {
    if (state && cbRef.current) {
      cbRef.current();
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCb];
};

export default useStateWithCallback;
