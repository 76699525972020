import { FC, useState } from 'react';

import { useIsMobile } from 'hooks/useIsMobile';
import { StyledTooltip } from './styled';
import { ITooltipProps } from './types';

const Tooltip: FC<ITooltipProps> = ({
  placement = 'center',
  text,
  children
}) => {
  // states
  const [isVisible, setVisible] = useState(false);
  // hooks
  const isMobile = useIsMobile();

  return (
    <StyledTooltip
      placement={placement}
      onMouseEnter={() => setVisible(!isMobile)}
      onMouseLeave={() => setVisible(false)}
    >
      {isVisible && (
        <div className="tooltip-wrapper">
          <p>{text}</p>
        </div>
      )}
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
