import { IS_DEV } from '../constants';

class Console {
  log = (...args: unknown[]) => {
    if (IS_DEV) {
      console.log(...args);
    }
  };

  warn = (...args: unknown[]) => {
    if (IS_DEV) {
      console.warn(...args);
    }
  };

  error = (...args: unknown[]) => {
    if (IS_DEV) {
      console.error(...args);
    }
  };

  time = (label: string) => {
    if (IS_DEV) {
      console.time(label);
    }
  };

  timeEnd = (label: string) => {
    if (IS_DEV) {
      console.timeEnd(label);
    }
  };
}

export default new Console();
