import styled from 'styled-components';

export const StyledErrorModal = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 16px;
`;

export const ErrorContent = styled.div`
  width: 500px;
  max-width: 100%;
  background-color: var(--white);
  border-radius: 16px;
  text-align: center;
  padding: 16px;
  h4 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
    padding-bottom: 12px;
  }
  button {
    display: block;
    margin: 0 auto;
    width: 180px;
    padding: 12px;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 76px;
    border: 2px solid var(--danger);
    color: var(--danger);
  }
`;
