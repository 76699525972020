import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Chat,
  Hang,
  Mic,
  MicDisabled,
  Settings,
  Video,
  VideoDisabled,
  Info,
  ScreenShare,
  StopScreenShare,
  Link2
} from 'icons';
import ACTIONS from 'socket/actions';
import { useTranslation } from 'react-i18next';
import socket from 'webrtc/socket';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsApprovingRequested, toggleSettings } from 'store/webrtc/slice';
import {
  selectIsApprovingInProgress,
  selectIsApprovingRequested,
  selectIsSharingScreen,
  selectSelfAudioEnabled,
  selectSelfVideoEnabled,
  selectSharingScreenExists
} from 'store/webrtc/selectors';
import {
  selectAudioAllowed,
  selectVideoAllowed
} from 'store/devices/selectors';
import { selectIsLoggedIn } from 'store/user/selectors';
import { selectAppointmentExists } from 'store/appointments/selectors';
import { selectChatHasUnreadMessages } from 'store/chat/selectors';
import { toggleChat } from 'store/chat/slice';
import { toggleAppointment } from 'store/appointments/slice';
import { toggleModal } from 'store/ui/slice';
import { ModalKeys } from 'store/ui/types';
import { IS_ELECTRON } from 'constants/app';

import { Tooltip } from 'components/shared';
import { ButtonsWrapper, ButtonsContainer, Badge } from './styled';
import { IRoomButtons } from './types';
import { useIsMobile } from 'hooks/useIsMobile';
import ShareScreenModal from 'components/desktop/ShareScreenModal';

const RoomButtons: FC<IRoomButtons> = ({
  endCall,
  toggleShareScreen,
  onSwitchAudio,
  onSwitchVideo
}) => {
  // translations
  const { t } = useTranslation();
  // navigation
  const navigate = useNavigate();
  const { id: roomID } = useParams();
  // ui
  const isMobile = useIsMobile();
  // redux
  const dispatch = useAppDispatch();
  const videoEnabled = useAppSelector(selectSelfVideoEnabled);
  const audioEnabled = useAppSelector(selectSelfAudioEnabled);
  const videoAllowed = useAppSelector(selectVideoAllowed);
  const audioAllowed = useAppSelector(selectAudioAllowed);
  const hasUnreadMessage = useAppSelector(selectChatHasUnreadMessages);
  const isAppointment = useAppSelector(selectAppointmentExists);
  const isSharingScreen = useAppSelector(selectIsSharingScreen);
  const sharingScreenExists = useAppSelector(selectSharingScreenExists);
  const isApprovingRequested = useAppSelector(selectIsApprovingRequested);
  const isApprovingInProgress = useAppSelector(selectIsApprovingInProgress);
  const isLoggedIn = useAppSelector(selectIsLoggedIn());

  const onEndCall = useCallback(() => {
    endCall(() => {
      navigate(`/end/${roomID}${window.location.search}`);
    });
  }, [endCall, navigate, roomID]);

  const onAppointmentClick = () => {
    dispatch(toggleChat(false));
    dispatch(toggleAppointment());
  };

  const onChatClick = () => {
    dispatch(toggleAppointment(false));
    dispatch(toggleChat());
  };

  const onShareScreenClick = () => {
    if (sharingScreenExists) {
      return alert(t('only_one_screen_can_be_shared'));
    }

    if (IS_ELECTRON) {
      if (isSharingScreen) {
        return toggleShareScreen(false);
      }

      return dispatch(
        toggleModal({
          name: ModalKeys.desktopShareScreen,
          value: true
        })
      );
    }

    toggleShareScreen(!isSharingScreen);
  };

  const onShareLinkClick = () => {
    if (isApprovingInProgress) {
      return alert(t('another_share_link_in_progress'));
    }

    dispatch(
      toggleModal({
        name: ModalKeys.shareLink,
        value: true
      })
    );

    if (!isApprovingRequested) {
      dispatch(setIsApprovingRequested(true));
      socket.sendToPeer(ACTIONS.SHARE_LINK);
    }
  };

  const rightActions = () => (
    <>
      <div className="room-button">
        <Tooltip text={t('settings')}>
          <button onClick={() => dispatch(toggleSettings())}>
            <Settings width={24} height={24} />
          </button>
        </Tooltip>
      </div>
      {isLoggedIn && (
        <div className="room-button">
          <Tooltip text={t('share_link')}>
            <button onClick={onShareLinkClick}>
              <Link2 width={24} height={24} />
            </button>
          </Tooltip>
        </div>
      )}
      {isAppointment && (
        <div className="room-button">
          <Tooltip text={t('information')} placement="left">
            <button onClick={onAppointmentClick}>
              <Info width={24} height={24} />
            </button>
          </Tooltip>
        </div>
      )}
    </>
  );

  return (
    <>
      <ButtonsWrapper>
        {!isMobile && <div style={{ width: 120 }} />}
        <ButtonsContainer onMouseDown={e => e.stopPropagation()}>
          {audioAllowed && (
            <div className="room-button">
              <Tooltip text={audioEnabled ? t('mute') : t('unmute')}>
                <button onClick={() => onSwitchAudio(!audioEnabled)}>
                  {audioEnabled ? (
                    <Mic width={24} height={24} />
                  ) : (
                    <MicDisabled width={24} height={24} />
                  )}
                </button>
              </Tooltip>
            </div>
          )}
          {videoAllowed && (
            <div className="room-button">
              <Tooltip text={videoEnabled ? t('stop_video') : t('start_video')}>
                <button onClick={() => onSwitchVideo(!videoEnabled)}>
                  {videoEnabled ? (
                    <Video width={24} height={24} />
                  ) : (
                    <VideoDisabled width={24} height={24} />
                  )}
                </button>
              </Tooltip>
            </div>
          )}
          <div className="room-button">
            <Tooltip text={t('chat')}>
              <button onClick={onChatClick}>
                <Badge show={hasUnreadMessage}>
                  <Chat width={24} height={24} />
                </Badge>
              </button>
            </Tooltip>
          </div>
          {!isMobile && (
            <div className="room-button">
              <Tooltip
                text={t(isSharingScreen ? 'stop_share_screen' : 'share_screen')}
              >
                <button id="share-screen-button" onClick={onShareScreenClick}>
                  {isSharingScreen ? (
                    <StopScreenShare width={24} height={24} />
                  ) : (
                    <ScreenShare width={24} height={24} />
                  )}
                </button>
              </Tooltip>
            </div>
          )}
          {isMobile && rightActions()}
          <div className="room-button">
            <Tooltip text={t('end_call')}>
              <button onClick={onEndCall}>
                <Hang width={24} height={24} />
              </button>
            </Tooltip>
          </div>
        </ButtonsContainer>
        {!isMobile && (
          <ButtonsContainer onMouseDown={e => e.stopPropagation()}>
            {rightActions()}
          </ButtonsContainer>
        )}
      </ButtonsWrapper>
      {IS_ELECTRON && (
        <ShareScreenModal toggleShareScreen={toggleShareScreen} />
      )}
    </>
  );
};

export default RoomButtons;
