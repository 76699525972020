import { IS_ELECTRON } from 'constants/app';
import { FC, ReactNode } from 'react';

interface IProps {
  web: ReactNode;
  desktop: ReactNode;
}

const DesktopWrapper: FC<IProps> = ({ web, desktop }) => {
  if (IS_ELECTRON) {
    return <>{desktop}</>;
  }

  return <>{web}</>;
};

export default DesktopWrapper;
