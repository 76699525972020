import { RootState } from 'store';

import { getUserName } from 'utils/helpers';

export const selectUserLoading = () => (state: RootState) => state.user.loading;

export const selectUserData = (state: RootState) => state.user.user;

export const selectUserName = () => (state: RootState) => {
  const user = selectUserData(state);

  return getUserName(user);
};

export const selectUserPassword = () => (state: RootState) =>
  state.user.password;

export const selectIsLoggedIn = () => (state: RootState) =>
  state.user.isLoggedIn;

export const selectUserError = () => (state: RootState) => state.user.error;

export const selectRoomId = () => (state: RootState) => state.user.roomID;
