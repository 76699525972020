import { useEffect, useState } from 'react';

const MOBILE_MAX_WIDTH = 768;

export const useIsMobile = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const calculate = () => {
      setMobile(window.innerWidth <= MOBILE_MAX_WIDTH);
    };

    calculate();

    window.addEventListener('resize', calculate);

    return () => {
      window.addEventListener('resize', calculate);
    };
  }, []);

  return isMobile;
};
