import {
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router';
import ACTIONS from 'socket/actions';
import { useTranslation } from 'react-i18next';
import webrtc from 'webrtc';
import socket from 'webrtc/socket';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  checkDevices,
  setAudioAllowed,
  setDevicesLoading,
  setVideoAllowed
} from 'store/devices/slice';
import { join, setAudioEnabled, setVideoEnabled } from 'store/webrtc/slice';
import { setPassword, setUserName } from 'store/user/slice';
import {
  selectAudioAllowed,
  selectIsLoadingDevices,
  selectSelectedCamera,
  selectSelectedMicrophone,
  selectVideoAllowed
} from 'store/devices/selectors';
import {
  selectSelfAudioEnabled,
  selectSelfVideoEnabled,
  selectWebrtcJoined
} from 'store/webrtc/selectors';
import {
  selectIsLoggedIn,
  selectUserData,
  selectUserPassword
} from 'store/user/selectors';
import { getUserMedia } from 'webrtc/utils';
import { selectNetworkStatus } from 'store/network/selectors';
import { NetworkStatuses } from 'store/network/types';
import { shallowEqual } from 'react-redux';
import { logger } from 'logger';
import { APP_EVENTS } from 'logger/constants';
import instance from 'services/api';
import { IS_ELECTRON } from 'constants/app';
import StreamProvider from 'providers/Stream';
import useStreamStateContext from 'providers/Stream/useStreamStateContext';
import useStreamDispatchContext from 'providers/Stream/useStreamDispatchContext';
import { toggleModal } from 'store/ui/slice';
import { ModalKeys } from 'store/ui/types';
import config from 'config';

import {
  Splash,
  SettingsModal,
  CheckDevices,
  PermissionModal
} from 'components/shared';
import { getFileUrl } from 'utils/file';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import userPlaceholder from '../../assets/img/user.svg';
import {
  StyledContent,
  StyledMain,
  VideoContainer,
  ActionButtons,
  ImagePlaceholder,
  Header,
  StyledWrapper,
  UserAvatar,
  UserNames,
  SelectsContainer
} from './styled';
import { getInviteId } from 'utils/navigation';
import { ActionButton, AudioSelect, VideoSelect } from './components';
import { Button, Input } from 'components/ui';
import { isDoctorStorage } from 'utils/storage';

interface IProps {
  check?: boolean;
}

const Main: FC<IProps> = ({ check }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: roomID } = useParams();
  const dispatch = useAppDispatch();
  const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
  const selectedCamera = useAppSelector(selectSelectedCamera);
  const audioEnabled = useAppSelector(selectSelfAudioEnabled);
  const audioAllowed = useAppSelector(selectAudioAllowed);
  const videoEnabled = useAppSelector(selectSelfVideoEnabled);
  const videoAllowed = useAppSelector(selectVideoAllowed);
  const isLoggedIn = useAppSelector(selectIsLoggedIn());
  const joined = useAppSelector(selectWebrtcJoined);
  const user = useAppSelector(selectUserData, shallowEqual);
  const networkStatus = useAppSelector(selectNetworkStatus());
  const password = useAppSelector(selectUserPassword());
  const isLoadingDevices = useAppSelector(selectIsLoadingDevices);
  const [users, setUsers] = useState<string[]>([]);
  const [passwordError, setPasswordError] = useState('');
  const [isMediaSwitching, setIsMediaSwitching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCheckingPass, setCheckingPass] = useState(false);
  const localMediaElement = useRef<HTMLVideoElement | null>(null);
  const initJoined = useRef(joined);
  const localStream = useStreamStateContext();
  const setLocalStream = useStreamDispatchContext();

  useEffect(() => {
    const setRoomInfo = (data: { name: string }[]) => {
      setUsers(data.map(user => user?.name?.trim() || 'Guest'));
    };

    if (isLoggedIn) {
      socket.io.on(ACTIONS.ROOM_INFO, setRoomInfo);
    }

    return () => {
      socket.io.off(ACTIONS.ROOM_INFO, setRoomInfo);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && roomID) {
      socket.getRoomInfo().then(data => {
        setUsers(data.map(user => user?.name?.trim() || 'Guest'));
      });
    }
  }, [isLoggedIn, roomID]);

  useEffect(() => {
    if (initJoined.current) {
      window.location.reload();
    }
  }, []);

  const onSwitchCamera = useCallback(
    async (device: MediaDeviceInfo) => {
      setIsMediaSwitching(true);
      getUserMedia({
        isVideoEnabled: videoEnabled,
        isAudioEnabled: true,
        videoDeviceId: device.deviceId,
        audioDeviceId: selectedMicrophone
      })
        .then(stream => {
          logger.event(APP_EVENTS.camera_change, {
            videoDeviceId: device.deviceId
          });

          if (stream) {
            setLocalStream(prev => {
              prev?.getTracks().forEach(track => track.stop());

              return stream;
            });
          }
        })
        .finally(() => {
          setIsMediaSwitching(false);
        });
    },
    [selectedMicrophone, setLocalStream, videoEnabled]
  );

  const onSwitchMicrophone = useCallback(
    async (device: MediaDeviceInfo) => {
      setIsMediaSwitching(true);
      getUserMedia({
        isVideoEnabled: videoEnabled,
        isAudioEnabled: true,
        videoDeviceId: selectedCamera,
        audioDeviceId: device.deviceId
      })
        .then(stream => {
          logger.event(APP_EVENTS.microphone_change, {
            audioDeviceId: device.deviceId
          });

          if (stream) {
            setLocalStream(prev => {
              prev?.getTracks().forEach(track => track.stop());

              return stream;
            });
          }
        })
        .finally(() => {
          setIsMediaSwitching(false);
        });
    },
    [selectedCamera, setLocalStream, videoEnabled]
  );

  useEffect(() => {
    if (!isLoadingDevices && localMediaElement.current && localStream) {
      localMediaElement.current.srcObject = localStream;
    }
  }, [isLoadingDevices, localStream]);

  const requestMediaStream = useCallback(() => {
    const streamReceived = async (stream: MediaStream) => {
      dispatch(toggleModal({ name: ModalKeys.permission, value: false }));

      const videoTrucks = stream.getVideoTracks();
      const audioTrucks = stream.getAudioTracks();

      const devices = await dispatch(
        checkDevices({
          videoLabel: videoTrucks?.[0]?.label,
          audioLabel: audioTrucks?.[0]?.label
        })
      );

      logger.event(APP_EVENTS.get_devices, {
        data: devices.payload
      });

      setLocalStream(prev => {
        prev?.getTracks().forEach(track => track.stop());

        return stream;
      });
    };

    const getStreamWeb = async (isVideoEnabled = true) => {
      try {
        const stream = await getUserMedia({
          isVideoEnabled
        });

        if (stream) {
          if (isVideoEnabled) {
            dispatch(setVideoEnabled(true));
            dispatch(setVideoAllowed(true));
          }

          dispatch(setAudioEnabled(true));
          dispatch(setAudioAllowed(true));

          await streamReceived(stream);
        }
      } catch (err) {
        const { name } = (err || {}) as { name: string };
        console.log(['name'], name);

        switch (name) {
          case 'NotAllowedError':
          case 'OverconstrainedError':
            dispatch(setDevicesLoading(false));
            dispatch(setAudioAllowed(false));
            dispatch(setVideoAllowed(false));

            break;
          case 'NotFoundError':
            dispatch(setVideoEnabled(false));
            dispatch(setVideoAllowed(false));

            if (isVideoEnabled) {
              getStreamWeb(false);
            }

            break;

          default: {
            const devices = await dispatch(checkDevices({}));

            logger.event(APP_EVENTS.get_devices, {
              data: devices.payload
            });
            break;
          }
        }
      }
    };

    const getStreamDesktop = async () => {
      const [videoAllowed, audioAllowed] =
        await window.electron.getPermissions();

      dispatch(setVideoAllowed(videoAllowed));
      dispatch(setVideoAllowed(audioAllowed));

      if (!videoAllowed && !audioAllowed) {
        return;
      }

      const stream = await getUserMedia({
        isVideoEnabled: videoAllowed
      });

      if (stream) {
        await streamReceived(stream);
      }
    };

    if (IS_ELECTRON) {
      getStreamDesktop();
    } else {
      getStreamWeb();
    }
  }, [dispatch, setLocalStream]);

  useEffect(() => {
    requestMediaStream();
  }, [requestMediaStream]);

  useEffect(
    () => () => {
      localStream?.getTracks().forEach(item => item.stop());
    },
    [localStream]
  );

  const onJoin = useCallback(async () => {
    try {
      if (!isLoggedIn && !password) {
        return;
      }

      if (!videoAllowed && !audioAllowed) {
        return alert(t('grant_permission'));
      }

      if (
        (typeof selectedCamera === 'undefined' || selectedCamera === null) &&
        (typeof selectedMicrophone === 'undefined' ||
          selectedMicrophone === null)
      ) {
        return alert(t('check_devices'));
      }

      setPasswordError('');
      setCheckingPass(true);

      if (!isLoggedIn && config.env !== 'development') {
        const inviteId = getInviteId();

        await instance.post(
          `appointments/join-room/${
            isDoctorStorage.get() === '1' ? 'doctor' : ''
          }`,
          {
            appointmentId: roomID,
            inviteId,
            password
          }
        );
      }

      setCheckingPass(false);
      setLoading(true);
      localStream?.getTracks().forEach(item => item.stop());
      await webrtc.init();
      dispatch(join());
      setTimeout(() => {
        logger.event(APP_EVENTS.join, {
          selectedCamera,
          selectedMicrophone
        });
        navigate(`/room/${roomID}${window.location.search}`);
      });
    } catch {
      logger.event(APP_EVENTS.wrong_password);
      setCheckingPass(false);
      setPasswordError('Incorrect password');
      setLoading(false);
    }
  }, [
    isLoggedIn,
    password,
    videoAllowed,
    audioAllowed,
    selectedCamera,
    selectedMicrophone,
    localStream,
    roomID,
    dispatch,
    t,
    navigate
  ]);

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onJoin();
    }
  };

  const onMicrophoneChange = () => {
    const value = !audioEnabled;

    dispatch(setAudioEnabled(value));
    localStream?.getAudioTracks().forEach(item => (item.enabled = value));
  };

  const onVideoChange = async () => {
    try {
      const value = !videoEnabled;

      dispatch(setVideoEnabled(value));

      const stream = await getUserMedia({
        isVideoEnabled: value,
        videoDeviceId: selectedCamera,
        audioDeviceId: selectedMicrophone
      });

      if (stream) {
        if (localMediaElement.current) {
          localMediaElement.current.srcObject = stream;
        }

        setLocalStream(prev => {
          prev?.getTracks().forEach(track => track.stop());

          return stream;
        });
      }
    } catch {
      alert(t('device_error'));
    }
  };

  const renderBottom = () => {
    if (!(audioAllowed || videoAllowed)) {
      return null;
    }

    if (check) {
      return (
        <div className="button-container">
          <Button
            title={t('make_test_recording')}
            onClick={() =>
              dispatch(toggleModal({ name: ModalKeys.checkDevices }))
            }
          />
        </div>
      );
    }

    return (
      <>
        {isLoggedIn && (
          <>
            {users.length ? (
              <UserNames>
                {users.join(', ').replace(/,([^,]*)$/, ` ${t('and')} $1`)}{' '}
                {t('on_call', { count: users.length })}
              </UserNames>
            ) : (
              <UserNames>{t('you_are_first')}</UserNames>
            )}
          </>
        )}
        {!isLoggedIn && (
          <>
            <form onSubmit={e => e.preventDefault()}>
              <Input
                className="input-container"
                placeholder={t('please_enter_name') || ''}
                value={user?.first_name || ''}
                maxLength={30}
                onChange={e => dispatch(setUserName(e.target.value))}
                onKeyDown={onKeyDown}
                name="name"
                autoComplete="given-name"
              />
              <Input
                className="input-container"
                placeholder={t('please_enter_password') || ''}
                value={password}
                onChange={e => dispatch(setPassword(e.target.value))}
                onKeyDown={onKeyDown}
                type="password"
                error={passwordError}
                name="password"
                autoComplete="off"
              />
            </form>
          </>
        )}
        <div className="button-container">
          <Button
            title={t('join_now')}
            onClick={onJoin}
            disabled={
              networkStatus === NetworkStatuses.offline ||
              (!audioAllowed && !videoAllowed) ||
              (!isLoggedIn && !password)
            }
            loading={isCheckingPass}
          />
        </div>
      </>
    );
  };

  if (isLoading || isLoadingDevices) {
    return <Splash />;
  }

  return (
    <StyledWrapper>
      <Header>
        <Logo className="logo" />
      </Header>
      <StyledMain>
        <StyledContent>
          <VideoContainer>
            {!videoEnabled && (
              <>
                {user?.avatar ? (
                  <UserAvatar src={getFileUrl(user.avatar)} alt="user" />
                ) : (
                  <ImagePlaceholder>
                    <img src={userPlaceholder} alt="user" />
                  </ImagePlaceholder>
                )}
              </>
            )}
            <video
              ref={localMediaElement}
              controls={false}
              muted
              autoPlay
              playsInline
            />
            <ActionButtons>
              <ActionButton
                onClick={onMicrophoneChange}
                active={audioEnabled}
                icon="mic"
                text={t('unmute')}
                activeText={t('mute') as string}
                allowed={audioAllowed && !!selectedMicrophone}
                requestMediaStream={requestMediaStream}
              />
              <ActionButton
                onClick={onVideoChange}
                active={videoEnabled}
                icon="video"
                text={t('start')}
                activeText={t('stop') as string}
                allowed={videoAllowed && !!selectedCamera}
                requestMediaStream={requestMediaStream}
              />
            </ActionButtons>
          </VideoContainer>
          <SelectsContainer>
            {audioAllowed && (
              <AudioSelect
                disabled={isMediaSwitching}
                onSwitch={onSwitchMicrophone}
              />
            )}
            {videoAllowed && (
              <VideoSelect
                disabled={isMediaSwitching}
                onSwitch={onSwitchCamera}
              />
            )}
          </SelectsContainer>
          {renderBottom()}
        </StyledContent>
      </StyledMain>
      <SettingsModal
        onSwitchCamera={onSwitchCamera}
        onSwitchMicrophone={onSwitchMicrophone}
      />
      <CheckDevices />
      <PermissionModal />
    </StyledWrapper>
  );
};

export const MainProvider: FC<IProps> = ({ check }) => (
  <StreamProvider>
    <Main check={check} />
  </StreamProvider>
);

export default MainProvider;
