export const parseJson = (data: unknown) => {
  try {
    if (typeof data !== 'string') {
      throw new Error();
    }

    const res = JSON.parse(data);

    return res;
  } catch (_) {
    return data;
  }
};
