export enum NetworkStatuses {
  online = 'online',
  offline = 'offline',
  slowConnection = 'slowConnection',
  verySlowConnection = 'verySlowConnection'
}

export interface INetworkState {
  status: NetworkStatuses;
}
