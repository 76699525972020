const ACTIONS = {
  JOIN: 'join',
  LEAVE: 'leave',
  SHARE_ROOMS: 'share-rooms',
  ADD_PEER: 'add-peer',
  REMOVE_PEER: 'remove-peer',
  RELAY_SDP: 'relay-sdp',
  RELAY_ICE: 'relay-ice',
  ICE_CANDIDATE: 'ice-candidate',
  SESSION_DESCRIPTION: 'session-description',
  CONNECT: 'connect',
  ERROR: 'error',
  CONNECT_ERROR: 'connect_error',
  APPOINTMENT_ERROR: 'appointment_error',
  JOIN_ERROR: 'join_error',
  SEND_SPEAKING: 'send_speaking',
  GET_SPEAKING: 'get_speaking',
  SWITCH_ACTION: 'switch_action',
  ROOM_INFO: 'ROOM_INFO',
  SHARE_SCREEN: 'share_screen',
  STOP_SHARE_SCREEN: 'stop_share_screen',
  SHARE_LINK: 'SHARE_LINK',
  STOP_SHARE_LINK: 'STOP_SHARE_LINK',
  SHARE_LINK_ANSWER: 'SHARE_LINK_ANSWER',
  RECONNECT: 'reconnect',
  REPLACE_SOCKET_ID: 'replace_socket_id',
  THROW: 'throw',
};

module.exports = ACTIONS;
