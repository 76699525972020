import { FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import instance from 'services/api';
import { addAppointmentNote } from 'store/appointments/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import dayjs from 'dayjs';
import webrtc from 'webrtc';
import { APPOINTMENT_CREATOR } from 'constants/appointments';
import { selectUserType } from 'store/appointments/selectors';

import { Modal } from 'components/shared';
import { IAddNoteProps } from './types';
import { AddNoteContent, AddNoteFooter } from './styled';
import { MESSAGE_TYPES } from 'hooks/useWebRTC/types';

const AddNote: FC<IAddNoteProps> = ({ appointmentId, isOpen, onClose }) => {
  // translations
  const { t } = useTranslation();
  // redux
  const userType = useAppSelector(selectUserType);
  const dispatch = useAppDispatch();
  // states
  const [text, setText] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setText('');
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    if (!text.trim()) {
      return;
    }

    setLoading(true);
    instance
      .post(`/appointments/note/${appointmentId}`, {
        note: text
      })
      .then(() => {
        if (userType) {
          webrtc.sendToChannels({
            type: MESSAGE_TYPES.update_appointment
          });
          dispatch(
            addAppointmentNote({
              id: Date.now().toString(),
              note: text,
              sender: APPOINTMENT_CREATOR[userType],
              created_at: dayjs().format()
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  return (
    <Modal title={t('add_note')} isOpen={isOpen} onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <AddNoteContent>
          <div>
            <label htmlFor="note-input">{t('your_notes')}</label>
            <textarea
              id="note-input"
              value={text}
              onChange={e => setText(e.target.value)}
            />
          </div>
        </AddNoteContent>
        <AddNoteFooter>
          <button disabled={isLoading}>{t('submit')}</button>
        </AddNoteFooter>
      </form>
    </Modal>
  );
};

export default AddNote;
