import styled, { keyframes } from 'styled-components';

const spinner = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;
`;

export const StyledInit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media only screen and (max-width: 720px) {
    align-items: flex-start;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

export const ContinueButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledContent = styled.div<{ isRotated?: boolean }>`
  max-width: 560px;
  margin: 16px;
  flex: 1 0 100%;
  width: 100%;
  height: 535px;
  text-align: center;
  padding: 16px;
  background-color: var(--white);
  border: 1px solid #f5f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  .content-header {
    img {
      width: 64px;
      height: 64px;
      animation: ${spinner} 5s infinite linear;
    }
    h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 1.2;
      color: var(--dark);
      padding-top: 4px;
    }
  }
  .room-id-input {
    margin: 50px 0;
    text-align: left;
    input {
      width: 420px;
      height: 50px;
    }
  }
  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
  video {
    max-width: 100%;
    transform: scaleX(-1);
    object-fit: contain;
    transition: all 0.4s;
    height: 330px;
    border-radius: 16px;
    background-color: var(--gray-4);
    ${({ isRotated = true }) => isRotated && 'transform: scaleX(-1)'};
    @media only screen and (max-width: 720px) {
      height: 250px;
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: 16px;
  justify-content: center;
  left: 0;
  right: 0;
`;

export const ActionButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
  border: none;
  background-color: rgba(11, 26, 48, 0.8);
  color: var(--secondary);
  margin: 0 8px;
  svg {
    margin-bottom: 4px;
    height: 24px;
  }
`;

export const Header = styled.div`
  padding: 16px 40px;
  svg {
    width: 165px;
    height: 30px;
  }
  @media only screen and (max-width: 720px) {
    display: flex;
    justify-content: center;
    padding: 16px;
  }
`;

export const UserAvatar = styled.img`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  width: 100px;
  height: 100px;
  border-radius: 16px;
  object-fit: cover;
  object-position: center;
`;

export const ImagePlaceholder = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 16px;
  background-color: var(--blue-3);
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    object-position: center;
  }
`;

export const UserNames = styled.p`
  margin-bottom: 24px;
  color: var(--gray-4);
  font-weight: 500;
  text-align: center;
`;
