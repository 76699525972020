import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { INetworkState, NetworkStatuses } from './types';

const initialState: INetworkState = {
  status: NetworkStatuses.online
};

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setNetworkStatus: (state, action: PayloadAction<NetworkStatuses>) => {
      state.status = action.payload;
    },
    checkConnection: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case '3g':
          state.status = NetworkStatuses.slowConnection;
          break;
        case '2g':
          state.status = NetworkStatuses.verySlowConnection;
          break;
        default:
          state.status = NetworkStatuses.online;
          break;
      }
    }
  }
});

export const { setNetworkStatus, checkConnection } = networkSlice.actions;
export default networkSlice.reducer;
