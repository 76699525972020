import styled from 'styled-components';

export const StyledCard = styled.div`
  background-color: var(--white);
  box-shadow: 0px 4px 20px #e7eaf6;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 12px;
  display: flex;
  border-radius: 8px;
  .user-image {
    margin-right: 24px;
    background-color: var(--light-bg);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &.no-avatar {
        object-position: center;
        object-fit: contain;
        width: 60%;
        height: 60%;
      }
    }
  }
  .user-content {
    ul {
      list-style: none;
      li {
        margin-bottom: 4px;
        color: rgba(45, 44, 76, 0.45);
        font-size: 14px;
        h6 {
          color: var(--primary-dark);
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
`;
