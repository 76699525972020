import { ManagerOptions, SocketOptions } from 'socket.io-client';

// ICE SERVERS
export const DEFAULT_ICE = [{ urls: 'stun:stun.l.google.com:19302' }];

// CHANNELS
export const CHAT_CHANNEL_ID = 'CHAT_CHANNEL_ID';

// SOCKET OPTIONS
export const SOCKET_OPTIONS: Partial<ManagerOptions & SocketOptions> = {
  forceNew: false,
  reconnectionAttempts: Infinity,
  transports: ['websocket']
};
