import { RouteObject, useRoutes } from 'react-router-dom';

import { DesktopWrapper, NotFound } from 'components/shared';
import Main from 'pages/Main';
import Room from 'pages/Room';
import ErrorPage from 'pages/Error';
import CallEnded from 'pages/CallEnded';
import Init from 'pages/Init';
import Duplicate from 'pages/Duplicate';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <DesktopWrapper web={<NotFound />} desktop={<Init />} />
  },
  {
    path: '/check-devices',
    element: <Main check />
  },
  {
    path: '/home/:id',
    element: <Main />
  },
  {
    path: '/room/:id',
    element: <Room />
  },
  {
    path: '/end/:id',
    element: <CallEnded />
  },
  {
    path: '/error',
    element: <ErrorPage />
  },
  {
    path: '/duplicate',
    element: <Duplicate />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

const Routes = () => {
  const routers = useRoutes(routes);

  return routers;
};

export default Routes;
