import { FC } from 'react';

import { StyledContainer } from './styled';
import { INotFoundProps } from './types';

const NotFound: FC<INotFoundProps> = ({
  text = 'Requested page is not found'
}) => {
  return (
    <StyledContainer>
      <p>{text}</p>
    </StyledContainer>
  );
};

export default NotFound;
