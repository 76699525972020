import axios from 'axios';
import config from 'config';

import { getFieldsDataFromServerError } from 'utils/helpers';
import { jwt } from 'utils/jwt';
import { tokenStorage } from 'utils/storage';

const instance = axios.create({
  baseURL: config.base_url,
  withCredentials: true
});

instance.interceptors.request.use(
  request => {
    const token = tokenStorage.get();

    if (token && request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const { response } = error;

    const originalRequest = error.config;

    if (response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await jwt.refreshToken();

        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

          return instance(originalRequest);
        }

        return Promise.reject();
      } catch (refreshError) {
        // Handle failure (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorData: any = response?.data;

    if (response?.status === 422) {
      return new Promise((_, reject) => {
        if (errorData) {
          if (errorData.errors) {
            const errors = getFieldsDataFromServerError(errorData.errors);

            return reject({ ...errorData, errors });
          }

          return reject({ ...errorData, status: response.status });
        }

        return reject(error);
      });
    }

    if (error.config.method !== 'get' && errorData?.message) {
      let messages = errorData.message;

      if (errorData.errors) {
        messages += `\n ${Object.values(errorData.errors)
          // ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((massage: any) => massage.msg)
          .join(`\n`)}`;
      }

      console.log('messages', messages);
    }

    if (response?.status === 401) {
      console.log('log out');
    }

    return Promise.reject(error);
  }
);

export default instance;
