import { FC, memo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUIModal } from 'store/ui/selectors';
import { IUIState, ModalKeys } from 'store/ui/types';
import { toggleModal } from 'store/ui/slice';
import { useTranslation } from 'react-i18next';
import {
  resetShareApprovalRejectedStatuses,
  resetShareApprovalStatuses,
  setIsApprovingRequested
} from 'store/webrtc/slice';
import socket from 'webrtc/socket';
import ACTIONS from 'socket/actions';
import { selectShareApprovedByEveryone } from 'store/webrtc/selectors';

import { Modal } from 'components/shared';
import { StyledContent } from './styled';
import { CopyLink, Users } from './components';

const ShareLink: FC = () => {
  // translations
  const { t } = useTranslation();
  // redux
  const isApproved = useAppSelector(selectShareApprovedByEveryone);
  const modal = useAppSelector(
    selectUIModal<IUIState['modals'][ModalKeys.shareLink]>(ModalKeys.shareLink)
  );

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(
      toggleModal({
        name: ModalKeys.shareLink,
        value: false
      })
    );
  };

  const onCancel = () => {
    onClose();
    dispatch(setIsApprovingRequested(false));
    dispatch(resetShareApprovalStatuses());
    socket.sendToPeer(ACTIONS.STOP_SHARE_LINK);
  };

  const onAsk = () => {
    dispatch(resetShareApprovalRejectedStatuses());
    socket.sendToPeer(ACTIONS.SHARE_LINK);
  };

  return (
    <Modal
      isOpen={modal.visible}
      title={t(isApproved ? 'sent_invitation' : 'share_link_title')}
      onClose={isApproved ? onCancel : onClose}
      footer={
        isApproved
          ? {
              cancelButton: {
                text: t('close'),
                onClick: onCancel
              }
            }
          : {
              cancelButton: {
                text: t('cancel_request'),
                onClick: onCancel
              },
              okButton: {
                text: t('ask_to_share'),
                onClick: onAsk
              }
            }
      }
      width={isApproved ? 900 : 600}
    >
      <StyledContent>
        <p>
          {t(isApproved ? 'sent_invitation_text' : 'share_link_description')}
        </p>
        {isApproved ? <CopyLink /> : <Users />}
      </StyledContent>
    </Modal>
  );
};

export default memo(ShareLink);
