import styled from 'styled-components';

export const MessageItem = styled.div`
  margin-bottom: 16px;
  p {
    word-break: break-word;
    &.name {
      padding-bottom: 8px;
      color: var(--gray-4);
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      word-wrap: break-word;
      span.date {
        color: var(--gray-2);
        display: inline-block;
        font-size: 12px;
        padding-left: 8px;
        font-weight: 400;
      }
    }
    &.text {
      display: block;
      font-size: 16px;
      color: var(--black);
    }
  }
`;

export const StyledInputContainer = styled.div`
  height: 104px;
  padding: 16px;
  border-top: 1px solid #e4eeff;
  .input-wrapper {
    background-color: var(--chat-input-background-color);
    border: 1px solid #e4eeff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-right: 8px;
    input {
      width: 100%;
      height: 100%;
      outline: 0;
      font-size: 16px;
      padding: 14px;
      border: none;
      background-color: transparent;
      height: 56px;
    }
    button {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
    }
  }
`;
