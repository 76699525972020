import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUIModal } from 'store/ui/selectors';
import { IUIState, ModalKeys } from 'store/ui/types';
import { toggleModal } from 'store/ui/slice';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared';
import { StyledContent } from './styled';

const Confirm: FC = () => {
  // translations
  const { t } = useTranslation();
  // redux
  const modal = useAppSelector(
    selectUIModal<IUIState['modals'][ModalKeys.confirm]>(ModalKeys.confirm)
  );

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(
      toggleModal({
        name: ModalKeys.confirm,
        value: false
      })
    );
  };

  const onDismiss = () => {
    modal.params?.onCancel?.();
    onClose();
  };

  const onOk = () => {
    modal.params?.onOk?.();
    onClose();
  };

  return (
    <Modal
      isOpen={modal.visible}
      title={modal.params?.title || ''}
      onClose={onDismiss}
      footer={{
        cancelButton: modal.params?.showCancel
          ? {
              text: modal.params?.cancelText,
              onClick: onDismiss
            }
          : null,
        okButton: {
          text: modal.params?.okText,
          onClick: onOk
        }
      }}
    >
      <StyledContent>
        {!!modal.params?.description && <p>{t(modal.params?.description)}</p>}
      </StyledContent>
    </Modal>
  );
};

export default Confirm;
