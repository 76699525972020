/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { shallowEqual } from 'react-redux';
import { selectUserName } from 'store/webrtc/selectors';
import { PeerID } from 'webrtc/types';

interface IProps {
  peerID: PeerID;
  transcript: string;
}

const CaptionItem: FC<IProps> = ({ peerID, transcript }) => {
  const name = useAppSelector(selectUserName(peerID), shallowEqual);

  return (
    <li>
      <span>{name}: </span>
      {transcript}
    </li>
  );
};

export default CaptionItem;
