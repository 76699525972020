import styled from 'styled-components';

export const StyledError = styled.div`
  background-color: var(--white);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .container {
    text-align: center;
    max-width: 450px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      padding-top: 16px;
      padding-bottom: 32px;
    }
    svg {
      path {
        stroke: var(--primary-new);
      }
    }
  }
`;
