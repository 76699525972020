export const APPOINTMENT_CREATOR_PATIENT = '2';
export const APPOINTMENT_CREATOR_DOCTOR = '3';

export const APPOINTMENT_CREATOR = {
  patient: APPOINTMENT_CREATOR_PATIENT,
  doctor: APPOINTMENT_CREATOR_DOCTOR,
  translator: ''
} as const;

export type AppointmentCreator = keyof typeof APPOINTMENT_CREATOR;
