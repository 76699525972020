import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CaptionItem, ICaptionsState } from './types';

const initialState: ICaptionsState = {
  visible: false,
  captions: [],
  shownCaptions: []
};

const captionsSlice = createSlice({
  name: 'captions',
  initialState,
  reducers: {
    addCaption: (state, action: PayloadAction<CaptionItem>) => {
      state.captions.push(action.payload);

      const shownCaptions = state.shownCaptions.filter(
        caption => caption.peerID !== action.payload.peerID
      );

      shownCaptions.push(action.payload);

      state.shownCaptions = shownCaptions.slice(-3);
    },
    clearCaptions: state => {
      state.shownCaptions = [];
    }
  }
});

export const { addCaption, clearCaptions } = captionsSlice.actions;
export default captionsSlice.reducer;
