import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { setAudioEnabled, setVideoEnabled } from 'store/webrtc/slice';
import { APP_ERRORS } from 'logger/constants';

import { CheckDevicesParams, IDevicesState } from './types';
import { getDevices } from 'utils/devices';

export const setVideoAllowed = createAsyncThunk(
  'devices/setVideoAllowed',
  async (value: boolean, { dispatch }) => {
    if (!value) {
      dispatch(setVideoEnabled(false));
    }

    return value;
  }
);

export const setAudioAllowed = createAsyncThunk(
  'devices/setAudioAllowed',
  async (value: boolean, { dispatch }) => {
    if (!value) {
      dispatch(setAudioEnabled(false));
    }

    return value;
  }
);

export const checkDevices = createAsyncThunk(
  'devices/checkDevices',
  async (params?: CheckDevicesParams) => {
    if (!navigator.mediaDevices) {
      throw new Error();
    }

    const res = await getDevices(true, true);

    if (!res.cameras?.length && !res.microphones?.length) {
      const { logger } = await import('logger');

      logger.error(APP_ERRORS.no_devices_found);

      throw new Error();
    }

    return {
      ...res,
      videoLabel: params?.videoLabel,
      audioLabel: params?.audioLabel
    };
  }
);

const initialState: IDevicesState = {
  microphones: [],
  cameras: [],
  speakers: [],
  error: false,
  videoAllowed: true,
  audioAllowed: true,
  isLoadingDevices: true
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    chooseCamera: (state, action: PayloadAction<MediaDeviceInfo>) => {
      state.selectedCamera = action.payload.deviceId;
    },
    chooseMicrophone: (state, action: PayloadAction<MediaDeviceInfo>) => {
      state.selectedMicrophone = action.payload.deviceId;
    },
    setDevicesLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDevices = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(checkDevices.fulfilled, (state, action) => {
      const {
        microphones = [],
        cameras = [],
        speakers = [],
        videoLabel,
        audioLabel
      } = action.payload;

      let selectedCamera = state.selectedCamera;

      if (videoLabel) {
        selectedCamera =
          cameras.find(item => item.label === videoLabel)?.deviceId ||
          selectedCamera;
      }

      const isExistingCamera = cameras.find(
        item => item.deviceId === selectedCamera
      );

      if (!isExistingCamera || !selectedCamera) {
        selectedCamera = cameras[0]?.deviceId;
      }

      let selectedMicrophone = state.selectedMicrophone;

      if (audioLabel) {
        selectedMicrophone =
          microphones.find(item => item.label === audioLabel)?.deviceId ||
          selectedMicrophone;
      }

      const isExistingMicrophone = microphones.find(
        item => item.deviceId === selectedMicrophone
      );

      if (!isExistingMicrophone || !selectedMicrophone) {
        selectedMicrophone = microphones[0]?.deviceId;
      }

      if (!microphones?.length) {
        state.audioAllowed = false;
      }

      state.microphones = microphones;
      state.selectedMicrophone = selectedMicrophone;

      if (!cameras?.length) {
        state.videoAllowed = false;
      }

      state.cameras = cameras;
      state.selectedCamera = selectedCamera;
      state.speakers = speakers;
      state.selectedSpeaker = speakers?.[0]?.deviceId;
      state.isLoadingDevices = false;
    });
    builder.addCase(checkDevices.rejected, state => {
      state.isLoadingDevices = false;
    });
    builder.addCase(setVideoAllowed.fulfilled, (state, action) => {
      state.videoAllowed = action.payload;
    });
    builder.addCase(setAudioAllowed.fulfilled, (state, action) => {
      state.audioAllowed = action.payload;
    });
  }
});

export const { chooseCamera, chooseMicrophone, setDevicesLoading } =
  devicesSlice.actions;
export default devicesSlice.reducer;
