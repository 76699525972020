import { FC } from 'react';
import { ReactComponent as ErrorIcon } from 'assets/img/error.svg';

import { ErrorContent, StyledErrorModal } from './styled';
import { IErrorModalProps } from './types';

const ErrorModal: FC<IErrorModalProps> = ({ visible, title, text, onOk }) => {
  if (!visible) {
    return null;
  }

  return (
    <StyledErrorModal>
      <ErrorContent>
        <h4>{title}</h4>
        <ErrorIcon />
        <p>{text}</p>
        <button onClick={onOk}>OK</button>
      </ErrorContent>
    </StyledErrorModal>
  );
};

export default ErrorModal;
