import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { getFileUrl } from 'utils/file';
import { StyledCard } from './styled';
import { IUserItemProps } from './types';

const UserItem: FC<IUserItemProps> = ({
  avatar,
  firstName,
  lastName,
  extra
}) => {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <div className="user-image">
        <img src={getFileUrl(avatar)} className={!avatar ? 'no-avatar' : ''} />
      </div>
      <div className="user-content">
        <ul>
          <li>
            <h6>
              {firstName} {lastName}
            </h6>
          </li>
          {extra?.map(item => (
            <li key={`extra-${firstName}-${lastName}-${item}}`}>{t(item)}</li>
          ))}
        </ul>
      </div>
    </StyledCard>
  );
};

export default memo(UserItem);
