import styled from 'styled-components';

export const StyledInput = styled.div<{ isError: boolean; type?: string }>`
  position: relative;
  label {
    display: block;
    color: var(--dark);
    font-size: 16px;
    line-height: 1.43;
    margin-bottom: 8px;
    font-weight: 500;
  }
  input {
    height: 39px;
    border-radius: 8px;
    font-size: 14px;
    padding-left: 16px;
    border-radius: 8px;
    outline: 0;
    font-size: 16px;
    width: 100%;
    ${({ isError }) =>
      `border: 1px solid var(--${isError ? 'danger' : 'gray-40'})`};
    padding-right: ${({ type }) => (type === 'password' ? '40px' : '16px')};
  }
  .eye-icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    display: flex;
    align-items: center;
    user-select: none;
  }
`;
