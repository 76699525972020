import styled, { css } from 'styled-components';

export const SettingsMenu = styled.ul`
  list-style: none;
  min-width: 20%;
  flex: 1 0 20%;
`;

export const SettingsButton = styled.button<{ active: boolean }>`
  cursor: pointer;
  background-color: transparent;
  padding: 12px 0;
  display: block;
  width: 100%;
  border: none;
  text-align: left;
  font-size: 18px;
  position: relative;
  border-right: 2px solid transparent;
  ${({ active }) =>
    active &&
    css`
      border-right: 2px solid var(--primary);
    `}
`;

export const SettingsMenuWrapper = styled.div`
  display: flex;
  min-height: 200px;
`;

export const SettingsMenuContent = styled.div`
  min-width: 80%;
  flex: 1 0 80%;
  padding: 0 12px;
`;

export const SettingItem = styled.div`
  padding-bottom: 16px;
  label {
    display: block;
    font-weight: 500;
    padding-bottom: 8px;
  }
  select {
    display: block;
    width: 100%;
    padding: 12px 0;
    border-color: var(--secondary);
    font-size: 16px;
    background-color: transparent;
  }
`;
