/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Fullscreen } from 'icons';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectSelectedSpeaker } from 'store/devices/selectors';
import {
  selectIsSpeaking,
  selectIsStreamDisconnected,
  selectIsStreamLoading,
  selectRemoteStreamsCount,
  selectUserAvatar,
  selectVideoEnabled,
  selectVideoStream
} from 'store/webrtc/selectors';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getFileUrl } from 'utils/file';
import {
  AvatarPlaceholder,
  ImagePlaceholder,
  PinButton,
  StyledVideo,
  LoadingContainer,
  UserAvatar
} from './styled';
import { IVideoProps } from './types';
import userPlaceholder from '../../../assets/img/user.svg';
import { useIsMobile } from 'hooks/useIsMobile';
import RTCVideoUserInfo, {
  RTCVideoUserInfoRef
} from 'components/shared/RTCVideoUserInfo';
import { Loading } from 'components/shared';

const RTCVideo: FC<IVideoProps> = ({
  id,
  self,
  pinned,
  index,
  stream: localStream,
  onClickPin
}) => {
  const isMobile = useIsMobile();
  const [muted, setMuted] = useState(false);
  const ref = useRef<HTMLVideoElement | null>(null);
  const rtcVideoUserInfoRef = useRef<RTCVideoUserInfoRef | null>(null);
  const { t } = useTranslation();
  const count = useAppSelector(selectRemoteStreamsCount);
  const selectedSpeaker = useAppSelector(selectSelectedSpeaker);
  const isSpeaking = useAppSelector(selectIsSpeaking(id, muted));
  const remoteStream = useAppSelector(selectVideoStream(id), shallowEqual);
  const avatar = useAppSelector(selectUserAvatar(id));
  const isVideoEnabled = useAppSelector(selectVideoEnabled(id));
  const isLoading = useAppSelector(selectIsStreamLoading(id));
  const isDisconnected = useAppSelector(selectIsStreamDisconnected(id));

  const stream = useMemo(
    () => localStream || remoteStream,
    [localStream, remoteStream]
  );

  useEffect(() => {
    // @ts-ignore
    if (!self && ref.current && ref.current?.setSinkId) {
      // @ts-ignore
      // ref.current?.setSinkId(selectedSpeaker?.deviceId);
    }
  }, [self, selectedSpeaker]);

  useEffect(() => {
    if (stream && ref.current) {
      ref.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <StyledVideo
      length={count}
      self={!!self}
      pinned={!self && id === pinned}
      index={index}
      hasPinned={!!pinned}
      isSpeaking={isSpeaking}
      onMouseEnter={() => rtcVideoUserInfoRef.current?.setHovered(true)}
      onMouseLeave={() => rtcVideoUserInfoRef.current?.setHovered(false)}
    >
      {!stream ||
        (!isVideoEnabled && (
          <ImagePlaceholder>
            {avatar ? (
              <UserAvatar src={getFileUrl(avatar || '')} alt="user" />
            ) : (
              <AvatarPlaceholder src={userPlaceholder} alt="user" />
            )}
          </ImagePlaceholder>
        ))}
      {(!stream || isLoading || isDisconnected) && (
        <LoadingContainer transparent={isDisconnected && !!stream}>
          <div className="spinner-container">
            <Loading />
            {(!stream || isDisconnected) && <p>{t('no_connection')}</p>}
          </div>
        </LoadingContainer>
      )}
      <video
        ref={ref}
        autoPlay
        playsInline
        muted={self || muted}
        controls={false}
      />
      {count > 1 && (
        <>
          {!isMobile && !self && id && onClickPin && (
            <PinButton active={id === pinned} onClick={() => onClickPin(id)}>
              <Fullscreen />
            </PinButton>
          )}
        </>
      )}
      <RTCVideoUserInfo
        ref={rtcVideoUserInfoRef}
        id={id}
        self={self}
        muted={muted}
        setMuted={setMuted}
      />
    </StyledVideo>
  );
};

export default memo(RTCVideo);
