import styled from 'styled-components';

export const StyledFiles = styled.div`
  margin-bottom: 16px;
  ul {
    list-style: none;
    li {
      padding-bottom: 8px;
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: var(--primary-dark);
        svg {
          width: 24px;
          height: 24px;
          flex: 0 0 24px;
        }
        span {
          flex: 1;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 4px;
        }
      }
    }
  }
`;
