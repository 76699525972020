import { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { selectUIModal } from 'store/ui/selectors';
import { IUIState, ModalKeys } from 'store/ui/types';
import { toggleModal } from 'store/ui/slice';
import { ReactComponent as Image } from 'assets/img/allow_permission.svg';

import { Modal } from 'components/shared';
import { StyledPermission } from './styled';

const PermissionModal: FC = () => {
  const { t } = useTranslation();
  const modal = useAppSelector<IUIState['modals'][ModalKeys.permission]>(
    selectUIModal(ModalKeys.permission)
  );

  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(toggleModal({ name: ModalKeys.permission }));
  }, [dispatch]);

  return (
    <Modal width={700} isOpen={modal.visible} onClose={onClose}>
      <StyledPermission>
        <div className="permission-image">
          <Image />
        </div>
        <div className="permission-content">
          <h6>{t(`permissions.${modal.params?.type}`)}</h6>
          <ol>
            <li>{t('click_allow')}</li>
            <li>{t('if_you_not_see')}</li>
            <li>{t(`on_your_device.${modal.params?.type}`)}</li>
          </ol>
          <p
            dangerouslySetInnerHTML={{ __html: t('contact_support') as string }}
          />
        </div>
      </StyledPermission>
    </Modal>
  );
};

export default memo(PermissionModal);
