import styled, { keyframes } from 'styled-components';

const talking = keyframes`
 0% { transform: scaleY(0.5) }
 50% { transform: scaleY(1) }
 100% { transform: scaleY(0.5) }
`;

export const StyledTalking = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  width: 24px;
  li {
    width: 2.5px;
    background-color: var(--primary);
    border-radius: 2px;
    &:nth-child(2),
    &:nth-child(4) {
      height: 16px;
      animation: ${talking} 1.2s infinite;
    }
    &:nth-child(1),
    &:nth-child(5) {
      height: 8px;
      animation: ${talking} 0.8s infinite;
    }
    &:nth-child(3) {
      height: 24px;
      animation: ${talking} 0.4s infinite;
    }
  }
`;
