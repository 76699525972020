import { FC, FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setRoomId as setRoomIdAction } from 'store/user/slice';

import logo from '../../assets/img/logo.png';
import { Splash } from 'components/shared';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import {
  StyledContent,
  StyledInit,
  Header,
  StyledWrapper,
  ContinueButton
} from './styled';
import { Button, Input } from 'components/ui';
import { tokenStorage } from 'utils/storage';

const Init: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roomId, setRoomId] = useState('');
  const [isLoading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const appointmentData = await window.electron.getAppointmentData();

        if (!appointmentData) {
          throw new Error();
        }

        const { id, lang = 'en', token } = appointmentData;

        dispatch(setRoomIdAction(id));

        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);

        if (token) {
          tokenStorage.set(token);
          window.location.href = `${window.location.origin}/home/${id}`;
        } else {
          navigate(`home/${id}`);
        }
      } catch {
        setLoading(false);
      }
    })();
  }, [dispatch, i18n, navigate]);

  const onContinue: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    if (roomId) {
      dispatch(setRoomIdAction(roomId));
      navigate(`home/${roomId}`);
    }
  };

  if (isLoading) {
    return <Splash />;
  }

  return (
    <StyledWrapper>
      <Header>
        <Logo className="logo" />
      </Header>
      <StyledInit>
        <StyledContent>
          <div className="content-header">
            <img src={logo} alt="logo" />
            <h2>MedicusUnion</h2>
          </div>
          <form onSubmit={onContinue}>
            <Input
              label={t('meeting_id')}
              placeholder={t('please_enter_id') || ''}
              value={roomId}
              onChange={e => setRoomId(e.target.value)}
              name="roomID"
              className="room-id-input"
            />
            <ContinueButton>
              <Button title={t('continue')} />
            </ContinueButton>
          </form>
        </StyledContent>
      </StyledInit>
    </StyledWrapper>
  );
};

export default Init;
