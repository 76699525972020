import { useCallback, useEffect, useRef, useState } from 'react';
import ACTIONS from 'socket/actions';
import { useNavigate } from 'react-router';
import hark, { Harker } from 'hark';
import socket from 'webrtc/socket';
import webrtc from 'webrtc';
import { PeerID } from 'webrtc/types';
import {
  selectRemoteStreamsCount,
  selectSelfAudioEnabled,
  selectSelfVideoEnabled,
  selectWebrtcJoined
} from 'store/webrtc/selectors';
import {
  selectSelectedCamera,
  selectSelectedMicrophone
} from 'store/devices/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/selectors';
import { addMessage } from 'store/chat/slice';
import {
  deleteRemoteStreams,
  setAudioEnabled,
  setRemoteStreams,
  setStreamLoading,
  setVideoEnabled,
  switchRemoteStreamAudio,
  switchRemoteStreamVideo,
  changeDisconnectionStatus,
  toggleShareScreen,
  toggleShareScreenExists,
  toggleSpeaking,
  deleteRemoteStreamMedia
} from 'store/webrtc/slice';
import { shallowEqual } from 'react-redux';
import { logger } from 'logger';
import { APP_EVENTS } from 'logger/constants';
import { refetchAppointmentData } from 'store/appointments/slice';
import { getVideoConstraints } from 'webrtc/utils';
import { USER_ID } from 'constants/global';
import { addCaption } from 'store/captions/slice';

import {
  MessageType,
  MESSAGE_TYPES,
  StreamTypesEnum,
  SocketActionDataType
} from './types';
import { parseJson } from 'utils/json';
import { getUserName } from 'utils/helpers';
import useStateWithCallback from 'hooks/useStateWithCallback';
import { useIsMobile } from 'hooks/useIsMobile';
import { getInviteId } from 'utils/navigation';

const DEBOUNCE_TIME = 300;
const VIDEO_LOADING_DELAY = 1000;
const RECONNECTION_INTERVAL = 7000;

const useWebRTC = (roomID: string) => {
  // states
  const [pinned, setPinned] = useState('');

  // states
  const [localStream, setLocalStream] =
    useStateWithCallback<MediaStream | null>(null);

  const [isError, setError] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const videoEnabled = useAppSelector(selectSelfVideoEnabled);
  const audioEnabled = useAppSelector(selectSelfAudioEnabled);
  const mainUser = useAppSelector(selectUserData, shallowEqual);
  const joined = useAppSelector(selectWebrtcJoined);
  const selectedCamera = useAppSelector(selectSelectedCamera);
  const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
  const remoteStreamsCount = useAppSelector(selectRemoteStreamsCount);
  // refs
  const isActionLoading = useRef(false);
  const initUser = useRef(mainUser);
  const speech = useRef<Harker | null>(null);
  const initialCameraRef = useRef(selectedCamera);
  const initialMicrophoneRef = useRef(selectedMicrophone);
  const audioEnabledRef = useRef(audioEnabled);
  const videoEnabledRef = useRef(videoEnabled);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const reconnectionTimer = useRef<Record<PeerID, NodeJS.Timeout | null>>({});
  // navigation
  const navigate = useNavigate();
  // mobile
  const isMobile = useIsMobile();

  const checkAndRemoveInterval = useCallback((peerID: PeerID) => {
    const interval = reconnectionTimer.current?.[peerID];

    if (interval) {
      clearInterval(interval);
    }
  }, []);

  const join = useCallback(
    async (peerID?: string, existingStream?: MediaStream | null) => {
      const inviteId = getInviteId();

      const data = {
        user: {
          avatar: initUser.current?.avatar,
          name: getUserName(initUser.current)
        },
        videoEnabled: videoEnabledRef.current,
        audioEnabled: audioEnabledRef.current,
        inviteId
      };

      if (existingStream) {
        socket.sendToPeer(ACTIONS.JOIN, {
          ...data,
          peerID
        });

        return;
      }

      const stream = await webrtc.getUserMedia({
        isVideoEnabled: videoEnabledRef.current,
        isAudioEnabled: audioEnabledRef.current,
        videoDeviceId: initialCameraRef.current,
        audioDeviceId: initialMicrophoneRef.current
      });

      if (stream) {
        speech.current = hark(stream);

        setLocalStream(stream, () => {
          logger.event(APP_EVENTS.join_send, {
            isVideoEnabled: videoEnabledRef.current,
            isAudioEnabled: audioEnabledRef.current,
            videoDeviceId: initialCameraRef.current,
            audioDeviceId: initialMicrophoneRef.current
          });
          socket.sendToPeer(ACTIONS.JOIN, data);
        });
      }
    },
    [setLocalStream]
  );

  const handleRemoveStreamMedia = useCallback(
    ({ peerID }: { peerID: PeerID }) => {
      if (pinned === peerID) {
        setPinned('');
      }

      webrtc.onRemovePeer(peerID);
      dispatch(deleteRemoteStreamMedia(peerID));
    },
    [dispatch, pinned]
  );

  const handleRemovePeer = useCallback(
    ({ peerID }: { peerID: PeerID }) => {
      if (pinned === peerID) {
        setPinned('');
      }

      webrtc.onRemovePeer(peerID);
      dispatch(deleteRemoteStreams(peerID));
    },
    [dispatch, pinned]
  );

  useEffect(() => {
    socket.io.on(ACTIONS.REMOVE_PEER, handleRemovePeer);

    return () => {
      socket.io.off(ACTIONS.REMOVE_PEER, handleRemovePeer);
    };
  }, [handleRemovePeer]);

  const onReceiveMessages = useCallback(
    peerID => {
      return (event: MessageEvent) => {
        console.log('event', event);
        const messageData: MessageType = parseJson(event.data);

        console.log('messageData', messageData);

        switch (messageData.type) {
          case MESSAGE_TYPES.chat:
            dispatch(addMessage({ ...messageData.data, read: false }));
            break;

          case MESSAGE_TYPES.speaking: {
            const { data } = messageData;

            dispatch(toggleSpeaking(data));

            break;
          }

          case MESSAGE_TYPES.update_appointment:
            dispatch(refetchAppointmentData());
            break;

          case MESSAGE_TYPES.speech_text:
            dispatch(addCaption({ peerID, data: messageData.data }));
            break;
        }
      };
    },
    [dispatch]
  );

  useEffect(() => {
    if (!joined) {
      navigate(`/home/${roomID}${window.location.search}`);
    } else {
      join();
    }
  }, [navigate, roomID, joined, join]);

  useEffect(() => {
    const handleNewPeer = webrtc.onNewPeer({
      localStream,
      ontrack: ({ peerID, stream, data }) => {
        checkAndRemoveInterval(peerID);

        logger.event(APP_EVENTS.ontrack, data);

        dispatch(
          setRemoteStreams({
            id: peerID,
            data: {
              ...data,
              type: StreamTypesEnum.media,
              stream
            }
          })
        );
      },
      onReceiveMessages,
      onDisconnect(peerID) {
        dispatch(
          changeDisconnectionStatus({
            peerID,
            value: true
          })
        );
      },
      onFail(peerID) {
        console.log('navigator.onLine', navigator.onLine);

        handleRemoveStreamMedia({ peerID });

        const reconnect = () => {
          console.log('TRY RECONNECT');

          socket.sendToPeer(
            ACTIONS.RECONNECT,
            {
              peerID
            },
            status => {
              console.log('SUCCESS', status);

              checkAndRemoveInterval(peerID);
            }
          );
        };

        if (navigator.onLine) {
          return reconnect();
        }

        reconnectionTimer.current[peerID] = setInterval(
          reconnect,
          RECONNECTION_INTERVAL
        );
      },
      onConnect(peerID) {
        console.log('CONNECTED', peerID);
        dispatch(
          changeDisconnectionStatus({
            peerID,
            value: false
          })
        );
      },
      onError: () => {
        setError(true);
      }
    });

    socket.io.on(ACTIONS.ADD_PEER, handleNewPeer);

    return () => {
      socket.io.off(ACTIONS.ADD_PEER, handleNewPeer);
    };
  }, [
    onReceiveMessages,
    localStream,
    dispatch,
    join,
    handleRemoveStreamMedia,
    checkAndRemoveInterval
  ]);

  useEffect(() => {
    const handleSessionDescription = webrtc.onSessionDescription({
      onReceiveMessages,
      cb(peerID) {
        setTimeout(() => {
          dispatch(
            setStreamLoading({
              id: peerID,
              value: false
            })
          );
        }, VIDEO_LOADING_DELAY);
      }
    });

    socket.io.on(ACTIONS.SESSION_DESCRIPTION, handleSessionDescription);

    return () => {
      socket.io.off(ACTIONS.SESSION_DESCRIPTION, handleSessionDescription);
    };
  }, [dispatch, onReceiveMessages]);

  useEffect(() => {
    const handleSessionDescription = webrtc.onScreenSharingSessionDescription(
      (stream, peerID) => {
        const id = `${StreamTypesEnum.screen}-${peerID}`;

        dispatch(toggleShareScreenExists(true));

        dispatch(
          setRemoteStreams({
            id,
            data: {
              stream,
              videoEnabled: true,
              type: StreamTypesEnum.screen
            }
          })
        );
      }
    );

    socket.io.on(ACTIONS.SHARE_SCREEN, handleSessionDescription);

    return () => {
      socket.io.off(ACTIONS.SHARE_SCREEN, handleSessionDescription);
    };
  }, [dispatch, onReceiveMessages]);

  useEffect(() => {
    socket.io.on(ACTIONS.ICE_CANDIDATE, webrtc.onIceCandidate);

    return () => {
      socket.io.off(ACTIONS.ICE_CANDIDATE, webrtc.onIceCandidate);
    };
  }, []);

  useEffect(() => {
    const handleStopSharing = ({ peerID }: { peerID: PeerID }) => {
      dispatch(toggleShareScreenExists(false));
      handleRemovePeer({
        peerID: `${StreamTypesEnum.screen}-${peerID}`
      });
    };

    socket.io.on(ACTIONS.STOP_SHARE_SCREEN, handleStopSharing);

    return () => {
      socket.io.off(ACTIONS.STOP_SHARE_SCREEN, handleStopSharing);
    };
  }, [dispatch, handleRemovePeer]);

  useEffect(() => {
    const handleSwitchAction = ({
      peerID,
      type,
      value,
      from
    }: SocketActionDataType) => {
      switch (type) {
        case MESSAGE_TYPES.video: {
          dispatch(
            switchRemoteStreamVideo({
              id: peerID,
              value,
              from
            })
          );
          break;
        }

        case MESSAGE_TYPES.audio: {
          dispatch(
            switchRemoteStreamAudio({
              id: peerID,
              value,
              from
            })
          );
          break;
        }
      }
    };

    socket.io.on(ACTIONS.SWITCH_ACTION, handleSwitchAction);

    return () => {
      socket.io.off(ACTIONS.SWITCH_ACTION, handleSwitchAction);
    };
  }, [dispatch, handleRemovePeer]);

  useEffect(() => {
    if (remoteStreamsCount > 1) {
      let timer: NodeJS.Timeout | null = null;

      if (audioEnabled) {
        speech.current?.on('speaking', () => {
          webrtc.sendToChannels({
            type: MESSAGE_TYPES.speaking,
            data: {
              peerID: USER_ID,
              value: true
            }
          });
        });

        speech.current?.on('stopped_speaking', () => {
          webrtc.sendToChannels({
            type: MESSAGE_TYPES.speaking,
            data: {
              peerID: USER_ID,
              value: false
            }
          });
        });
      } else {
        timer = setTimeout(() => {
          webrtc.sendToChannels({
            type: MESSAGE_TYPES.speaking,
            data: {
              peerID: USER_ID,
              value: false
            }
          });
        });
      }

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [audioEnabled, localStream, remoteStreamsCount]);

  const endCall = useCallback(
    (callback?: () => void) => {
      localStream?.getTracks().forEach(track => track.stop());

      socket.sendToPeer(ACTIONS.LEAVE);

      logger.event(APP_EVENTS.end_call);

      callback && setTimeout(callback, 200);
    },
    [localStream]
  );

  useEffect(() => {
    if (isMobile || remoteStreamsCount < 2) {
      setPinned('');
    }
  }, [isMobile, pinned, remoteStreamsCount]);

  const onShareScreen = useCallback(
    async (share: boolean, sourceId?: string) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(async () => {
        const onStopSharing = () => {
          webrtc.stopDisplayMedia(onStopSharing);
          dispatch(toggleShareScreen(false));
        };

        if (share) {
          const callback = () => {
            logger.event(APP_EVENTS.stop_share_screen);
            webrtc.stopDisplayMedia(onStopSharing);
            dispatch(toggleShareScreen(false));
          };

          if (sourceId) {
            await webrtc.getDisplayMediaElectron(sourceId, callback);
          } else {
            await webrtc.getDisplayMedia(callback);
          }
        } else {
          webrtc.stopDisplayMedia(onStopSharing);
        }

        dispatch(toggleShareScreen());
      }, DEBOUNCE_TIME);
    },
    [dispatch]
  );

  const onSwitchAudio = useCallback(
    (value: boolean) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(async () => {
        audioEnabledRef.current = value;
        localStream?.getAudioTracks().forEach(track => {
          track.enabled = value;
        });

        dispatch(setAudioEnabled(value));
        socket.sendToPeer(ACTIONS.SWITCH_ACTION, {
          type: MESSAGE_TYPES.audio,
          value
        });
        logger.event(APP_EVENTS.microphone_toggle, { value });
      }, DEBOUNCE_TIME);
    },
    [dispatch, localStream]
  );

  const onSwitchVideo = useCallback(
    async (value: boolean) => {
      if (isActionLoading.current) {
        return;
      }

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(async () => {
        videoEnabledRef.current = value;
        dispatch(setVideoEnabled(value));
        socket.sendToPeer(ACTIONS.SWITCH_ACTION, {
          type: MESSAGE_TYPES.video,
          value
        });
        logger.event(APP_EVENTS.camera_toggle, { value });

        if (value) {
          isActionLoading.current = true;
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: getVideoConstraints(true, selectedCamera)
          });

          if (stream && localStream) {
            const oldTrack = localStream?.getVideoTracks()?.[0];
            const newTrack = stream?.getVideoTracks()?.[0];

            if (oldTrack) {
              localStream.removeTrack(oldTrack);
            }

            localStream.addTrack(newTrack);

            setLocalStream(localStream);
            webrtc.addOrReplaceTrackToPcs(localStream, 'video', oldTrack);
            isActionLoading.current = false;
          }
        } else {
          const track = localStream?.getVideoTracks()?.[0];

          if (track) {
            track.stop();
          }

          setLocalStream(localStream);
        }
      }, DEBOUNCE_TIME);
    },
    [dispatch, setLocalStream, localStream, selectedCamera]
  );

  const onSwitchCamera = useCallback(
    async (device: MediaDeviceInfo) => {
      initialCameraRef.current = device.deviceId;

      if (!videoEnabledRef.current) {
        return;
      }

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(async () => {
        const stream = await webrtc.getUserMedia({
          isVideoEnabled: videoEnabledRef.current,
          isAudioEnabled: audioEnabledRef.current,
          videoDeviceId: device.deviceId,
          audioDeviceId: selectedMicrophone
        });

        logger.event(APP_EVENTS.camera_change, {
          videoDeviceId: device.deviceId
        });

        if (stream && localStream) {
          const oldTrack = localStream?.getVideoTracks()?.[0];
          const newTrack = stream?.getVideoTracks()?.[0];

          if (oldTrack) {
            localStream.removeTrack(oldTrack);
          }

          localStream.addTrack(newTrack);

          setLocalStream(localStream);
          webrtc.addOrReplaceTrackToPcs(localStream, 'video', oldTrack);
        }
      }, DEBOUNCE_TIME);
    },
    [localStream, selectedMicrophone, setLocalStream]
  );

  const onSwitchMicrophone = useCallback(
    async (device: MediaDeviceInfo) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(async () => {
        const stream = await webrtc.getUserMedia({
          isVideoEnabled: videoEnabledRef.current,
          isAudioEnabled: audioEnabledRef.current,
          videoDeviceId: selectedCamera,
          audioDeviceId: device.deviceId
        });

        logger.event(APP_EVENTS.microphone_change, {
          audioDeviceId: device.deviceId
        });

        if (stream && localStream) {
          const oldTrack = localStream?.getAudioTracks()?.[0];
          const newTrack = stream?.getAudioTracks()?.[0];

          if (oldTrack) {
            localStream.removeTrack(oldTrack);
          }

          localStream.addTrack(newTrack);

          setLocalStream(localStream);
          webrtc.addOrReplaceTrackToPcs(localStream, 'audio', oldTrack);
        }
      }, DEBOUNCE_TIME);
    },
    [localStream, selectedCamera, setLocalStream]
  );

  useEffect(() => {
    const handleReconnect = ({ peerID }: { peerID: PeerID }) => {
      handleRemoveStreamMedia({ peerID });

      checkAndRemoveInterval(peerID);

      join(peerID, localStream);
    };

    socket.io.on(ACTIONS.RECONNECT, handleReconnect);

    return () => {
      socket.io.off(ACTIONS.RECONNECT, handleReconnect);
    };
  }, [
    checkAndRemoveInterval,
    handleRemoveStreamMedia,
    join,
    localStream,
    onReceiveMessages
  ]);

  return {
    pinned,
    isError,
    localStream,
    endCall,
    setPinned,
    onShareScreen,
    onSwitchAudio,
    onSwitchVideo,
    onSwitchCamera,
    onSwitchMicrophone
  };
};

export default useWebRTC;
